import React from 'react'
import { connect } from 'react-redux'
import { CareUnitView } from './CareUnitView'
import { getCareCentersSelector } from '../../store/selectors'

type Props = {
  centers: any[];
  savedCenterId: string;
}

const Page = (props: Props) => {
  const { centers, savedCenterId } = props

  const getSavedCareCenterData = () => {
    if (!savedCenterId) return null

    const careUnit = centers.find((i) => i.idExt === savedCenterId)
    return {
      careUnit: careUnit.healthcareunit,
      phoneNumber: careUnit.healthcareunitPhoneNumber,
      address: careUnit.healthcareunitAddress,
      postCode: careUnit.healthcareunitZipCode,
      city: careUnit.cityname,
      to: careUnit.healthcareunitWebAddress
    }
  }

  return <CareUnitView savedCareCenterData={getSavedCareCenterData()} />
}

export const CareUnitPage = connect((state: any) => {
  return {
    centers: getCareCentersSelector(state),
    savedCenterId: state.consent.myInformation.primaryHealthCareEntityId
  }
})(Page)
