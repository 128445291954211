import React from 'react'
import PropTypes from 'prop-types'
import { HeroHeader } from 'src/new-lib/Hero'
import { ActionMenuRow } from 'src/new-lib/ActionMenuRow'
import { Button } from 'src/new-lib/Button'
import { LinkCard } from 'src/new-lib/LinkCard'
import styles from './PreSelectedCareUnit.module.scss'
import photo from '../../assets/images/child-leaning-on-shoulder.jpg'
import { Typography } from 'src/new-lib/Typography'
import { useDeviceSize } from 'src/new-lib/_utils'

type CareUnitViewProps = {
  savedCareCenterData: {
    careUnit: string,
    address: string,
    postCode: string,
    city: string,
    to: string
  } | null;
}

/**
 * @description Care-unit-view: Displays when user has a preselected unit saved
 * @description No-care-unit-view: should only display when user has no unit saved
 */

export const CareUnitView = ({ savedCareCenterData }: CareUnitViewProps) => {
  const { isMobile } = useDeviceSize()
  const bodyTitle = 'Din förvalda vårdcentral'
  const bodyText =
    'Här hittar du information angående din förvalda vårdcentral. Kom ihåg att det inte samma sak som att lista dig på en vårdcentral.'

  const title = 'Välj förvald vårdcentral'
  const text =
    'På Capios digitala tjänster kan du välja en förvald vårdcentral. Den blir din standardmottagning när du sedan söker digital vård hos oss. Observera att det inte är samma sak som att lista dig, men det kan du göra här också.'

  const CareUnitCard = () => {
    return (
      <div className={styles['content-container']}>
        <div className={styles['link-container']}>
          <LinkCard to="login" leftIconName="stethoscope" wide dumb>
            <Typography margin tag={isMobile ? 'h4' : 'h3'}>
              {savedCareCenterData.careUnit}
            </Typography>
            <Typography margin={{ t: '16px' }} tag="p">
              {savedCareCenterData.address}
            </Typography>
            <Typography margin={{ t: '0px' }} tag="p">
              {savedCareCenterData.postCode}, {savedCareCenterData.city}
            </Typography>
          </LinkCard>
        </div>
        <Button
          margin={{ t: '16px', b: '80px' }}
          to="chat > onboarding > step 1"
          variant="secondary-light"
        >
          ändra förvald vårdcentral
        </Button>
        <div className={styles['action-menu-content']}>
          <ActionMenuRow
            radiusTop
            iconName="medical-phone-app"
            iconSize="md"
            label="Starta ärende här"
            wide
            to="switchboard > specified unit"
            track={{
              name: 'seek_care_cta3',
              category: 'digital_care',
              action: 'to_digital_care',
            }}
          />
          <ActionMenuRow
            wide
            iconName="completed-notepad"
            iconSize="md"
            label="Lista dig här"
            to="listing > select"
            track={{
              name: 'listing_cta',
              category: 'listing',
              action: 'to_listing'
            }}
          />
          <ActionMenuRow
            wide
            iconName="share-link"
            iconSize="md"
            label="Mottagningens hemsida"
             to={savedCareCenterData.to}
            targetTo="_blank"
            radiusBottom
          />
        </div>
      </div>
    )
  }

  const NoCareUnitSelected = () => {
    return (
      <div className={styles['text-container']}>
        <div className={styles['text-box']}>
          <Typography margin align="center" tag="h3">
            {bodyTitle}
          </Typography>
          <Typography align="center" margin={{ t: '16px' }} tag="p">
            {bodyText}
          </Typography>
        </div>
        <Button
          margin={isMobile ? { b: '32px' } : { b: '56px' }}
          to="chat > onboarding > step 1"
          variant="primary"
        >
          välj förvald vårdcentral
        </Button>
      </div>
    )
  }

  return (
    <div className={styles.container}>
      <HeroHeader
        alignment={isMobile ? 'center' : 'left'}
        title={title}
        text={text}
        image={photo}
        alt="Child leaning on shoulder"
        color="light-blue"
      />
      {savedCareCenterData ? <CareUnitCard /> : <NoCareUnitSelected />}
    </div>
  )
}

CareUnitView.propTypes = {
  onClick: PropTypes.func,
  iconName: PropTypes.string,
  modalHeading: PropTypes.string,
  modalText: PropTypes.string,
  careUnit: PropTypes.object
}
