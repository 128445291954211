import React from 'react';
import Lottie from 'react-lottie';
import scss from './Loader.module.scss';
import heart from '../../assets/animation/heartloader.json';
import { marginToCssProp } from 'src/new-lib/_utils';
import { FixMeLater } from '../_types';

type LoaderProps = {
  show: boolean,
  size?: number,
  loop?: boolean,
  margin?: FixMeLater
}

const Loader: React.FC<LoaderProps> = ({ show, size = 80, loop, margin }) => {
  const render = () => {
    const defaultOptions = {
      loop: loop !== undefined && loop,
      autoplay: true,
      animationData: heart,
      renderer: 'svg'
    }

    return <Lottie options={defaultOptions} height={size} width={size} />
  }

  if (show) {
    return (
      <div className={scss.heart} style={{ margin: marginToCssProp(margin) }}>
        {render()}
      </div>
    )
  }

  return null
}

export default Loader
