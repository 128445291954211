import {initialState as appState} from './reducers/app/initialState';
import {initialState as consentState} from './reducers/consents/initialState';
import {initialState as newsletterState} from "./reducers/newsletter"
import {initialState as tosState} from "./reducers/terms-of-services"
import {initialState as contentState} from "./reducers/content"
//import config from 'api/config';
import jwt_decode from 'jwt-decode';

let createInitialState = () => {
    let sessionToken = localStorage.getItem('sessionToken');
    if (sessionToken === 'undefined') {
        localStorage.removeItem('idToken');
        localStorage.removeItem('sessionToken');
        localStorage.removeItem('code_verifier');
    }

    let identityInformation = '';
    if (sessionToken) {
        identityInformation = jwt_decode(sessionToken);
        if ((Date.now() / 1000) > identityInformation.exp) {
            localStorage.removeItem('sessionToken');
        }
    }

    return {
        app: {
            ...appState,
            userLoggedIn: identityInformation.exp > Date.now() / 1000,
            identityInformation: identityInformation,
            sessionToken
        },
        consent: {
            ...consentState
        },
        newsletter: {
            ...newsletterState
        },
        termsOfServices: {
            ...tosState
        },
        content: {
            ...contentState
        }
    };
};

export default createInitialState;
