import type { TosLegacy } from "src/api/services/TermsOfServiceAPI"
import type { GetLatestTosResponse } from "src/api/services/AccountAPI/AccountAPIModel"
import { AxiosError } from "axios"
import { initialState } from "./initialState"


type TosActions =
  { type: "fetch latest tos" }
  | { type: "fetch latest tos succeeded", payload: TosLegacy | GetLatestTosResponse }
  | { type: "fetch latest tos failed", payload: AxiosError }

export type TosDispatcher = (args: TosActions) => void


export const reducer = (state = initialState, action: TosActions) => {
  switch (action.type) {
    case "fetch latest tos":
      return { ...state, latestTermsOfService: { isLoading: true } };
    case "fetch latest tos succeeded":
      return { ...state, latestTermsOfService: { data: action.payload, isLoading: false } };
    case "fetch latest tos failed":
      return { ...state, latestTermsOfService: { error: action.payload, isLoading: false } }

    default:
      return state;
  }
};
