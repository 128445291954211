import React from 'react'
import styles from './ActionMenuRow.module.scss'
import { Icon, IconNames, IconSizes } from '../Icon'
import { Typography } from '../Typography'
import { LinkTo, To, Targets } from '../LinkTo'
import classNames from 'classnames'
import { Colors, FixMeLater } from '../_types'

/**
 * @typedef {{
 *  icon: string
 *  iconSize: "sm" | "md" | "lg"
 *  label: string
 *  to: string
 *  radiusTop: boolean
 *  radiusBottom: boolean
 *  wide: boolean
 *  targetTo: '_self' | '_blank' |'_parent' | '_top'
 * }} Props
 */

interface Props {
  iconName?: IconNames
  iconSize?: IconSizes
  label?: string
  color?: Colors
  to?: To
  radiusTop?: boolean
  radiusBottom?: boolean
  wide?: boolean
  targetTo?: Targets
  track?: FixMeLater
}

export const ActionMenuRow = ({
  iconName,
  iconSize,
  label,
  color,
  to,
  radiusTop,
  radiusBottom,
  wide,
  targetTo = '_self',
  track
}: Props) => {
  const containerClassNames = classNames({
    [styles.container]: true,
    [styles[color]]: true,
    [styles['container-wide']]: wide,
    [styles['radius-top']]: radiusTop,
    [styles['radius-bottom']]: radiusBottom
  })

  const navArrowDefault = `${styles['arrow-icon-container']} ${styles['icon-default']}`
  const navArrowOnHover = `${styles['arrow-icon-container']} ${styles['icon-on-hover']}`

  return (
    <LinkTo
      target={targetTo}
      className={containerClassNames}
      to={to}
      displayLinkIcon={false}
      dataTrack={track}
    >
      <div className={styles['icon-default']}>
        <Icon
          name={iconName}
          size={iconSize}
          color='primary'
          margin={{ r: 16, b: 4 }}
        />
      </div>
      <div className={styles['icon-on-hover']}>
        <Icon
          name={iconName}
          size={iconSize}
          color='hover'
          margin={{ r: 16, b: 4 }}
        />
      </div>
      <Typography tag='label' transform='uppercase' weight='600' htmlFor={undefined} lineHeight={undefined} display={undefined} spacing={undefined}>
        {label}
      </Typography>
      <div className={navArrowDefault}>
        <Icon name='nav-arrow-right' color='primary' />
      </div>
      <div className={navArrowOnHover}>
        <Icon name='nav-arrow-right' color='hover' />
      </div>
    </LinkTo>
  )
}
