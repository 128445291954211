import React from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { getCareCentersSelector } from '../../store/selectors';
import { SelectedCareUnitView } from './SelectedCareUnitView';
import { actions } from '../../store/reducers/consents';
import { paths } from '../../utils/urlPaths';

export type ICareUnit = {
  id: string;
  hsaid: string;
  name: string;
  street: string;
  postcode: string;
  postarea: string;
  phone: string;
  webAddress: string;
  careAreas: string[];
  type: string;
  region: string;
  onClick: () => void;
  idExt: string;
  isDigitalProvider: boolean;
  isDigitalProviderActive: boolean;
  isPatientListable: boolean;
  isbvc: boolean;
  videoCallDelegation: boolean;
}

interface ISelectedCareUnitProps extends RouteComponentProps {
  centers: any[];
  dispatchSelectCareCenter: (selectedCenter: any) => void;
  history: any,
  match: any
}

const SelectedCareUnit = ({
  centers,
  history,
  match,
  dispatchSelectCareCenter,
}: ISelectedCareUnitProps) => {
  const saveEnterHealthCareUnit = (hcu: ICareUnit) => {
    dispatchSelectCareCenter(hcu);
  }

  const onClickSelectRegion = () => {
    history.push(paths["chat > onboarding > step 1"]);
  }

  const getCareUnits = (): ICareUnit[] => {
    const filteredCareUnits = centers.filter(
      (c: any) => c.healthcareunitRegions === match.params.region
    )

    return filteredCareUnits.map((unit: any) => ({
      id: unit.id,
      hsaid: unit.HsaId,
      name: unit.healthcareunit,
      street: unit.healthcareunitAddress,
      postcode: unit.healthcareunitZipCode,
      postarea: unit.cityname,
      phone: unit.healthcareunitPhoneNumber,
      webAddress: unit.healthcareunitWebAddress,
      careAreas: unit.healthcareAreas,
      type: unit.type,
      region: unit.healthcareunitRegions,
      onClick: () => saveEnterHealthCareUnit(unit),
      idExt: unit.idExt,
      isDigitalProvider: unit.isDigitalProvider,
      isDigitalProviderActive: unit.isDigitalProviderActive,
      isPatientListable: unit.isPatientListable,
      isbvc: unit.isbvc,
      videoCallDelegation: unit.videoCallDelegation,
    }))
  }

  return (
    <SelectedCareUnitView
      region={history.location.state ? history.location.state as string : ''}
      careUnits={getCareUnits()}
      onClickRegionSelect={onClickSelectRegion}
      onSaveHcu={saveEnterHealthCareUnit}
    />
  )
}

const mapStateToProps = (state: any) => ({
  centers: getCareCentersSelector(state),
})

const mapDispatchToProps = (dispatch: any, { history }) => ({
  dispatchSelectCareCenter: (selectedCenter: any) => {
    dispatch(actions.selectSwitchBoardHCU(selectedCenter.idExt));
    dispatch(actions.updateAssociatedCareGivers())
      .then(() => history.push(paths["chat > settings > modal"]))
  },
})

export const SelectedCareUnitPage = withRouter(
  connect(mapStateToProps, mapDispatchToProps)(SelectedCareUnit)
)
