import type { TosApi } from "src/api/services/TermsOfServiceAPI";
import type { AccountApi } from "src/api/services/AccountAPI/AccountAPI";
import type { TosDispatcher } from "./reducer";
import type { InitialState as AppState } from "src/store/reducers/app/initialState"
import type { InitialState as TosState } from "src/store/reducers/terms-of-services/initialState"
import { isError } from "src/new-lib/_utils";
import { defaultFeatureConfig } from "src/store/reducers/featureManager/ducks"

type Api = {
  services: {
    termsOfServices: TosApi
    account: AccountApi
  }
}

type GetState = () => {
  app: AppState
  termsOfServices: TosState
  featureManager: typeof defaultFeatureConfig
}


export const actions = {
  fetchLatestTermsOfService() {
    return async (dispatch: TosDispatcher, getState: GetState, api: Api) => {
      const state = getState();
      const latestTos = state.termsOfServices.latestTermsOfService;
      const token = state.app.sessionToken
      const useV2 = state.featureManager.useService2

      if (latestTos.data || latestTos.isLoading) return


      dispatch({ type: "fetch latest tos" });
      const payload = useV2
        ? await api.services.account.getLatestTos(token)
        : await api.services.termsOfServices.getLatestTos()

      if (isError(payload))
        dispatch({ type: "fetch latest tos failed", payload })
      else
        dispatch({ type: "fetch latest tos succeeded", payload })
    };
  },
};