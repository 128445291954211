import NetworkError from "../NetworkError";
import config from "../config";
import { AxiosInstance } from "axios";
import { isAxiosError } from "src/new-lib/_utils";
import { trackAxiosError } from "src/new-lib/_utils/analytics";

export type TosLegacy = {
  id: string,
  content: string
}

export type TosApi = InstanceType<typeof TermsOfServicesAPI>

export class TermsOfServicesAPI {
  private _http: AxiosInstance
  private _endpointLatest: string
  private _endpointAccept: (termsId: string) => string
  private _endpointToAccept: string

  constructor(http: AxiosInstance) {
    this._http = http;

    const serviceBase = `${config.base}/api/v1/terms-of-services`

    this._endpointLatest = `${serviceBase}/latest`;
    this._endpointAccept = (termsId) => `${serviceBase}/${termsId}/accept`
    this._endpointToAccept = `${serviceBase}/to-accept`;
  }

  async getLatestTos() {
    try {
      const res = await this._http.get<TosLegacy>(this._endpointLatest)
      return res.data
      
    } catch (error: unknown) {
      if (isAxiosError(error)) {
        trackAxiosError(error)
        return error
      }
      throw error
    }
  }

  async getTosToAccept(token: string): Promise<TosLegacy | null> {
    const res = await this._http.get<TosLegacy>(this._endpointToAccept, {
      headers: { Authorization: `Bearer ${token}` }
    });

    switch (res.status) {
      case 200:
        return res.data;
      default:
        return null;
    }
  }

  async acceptTos(id: string, token: string): Promise<undefined> {
    const res = await this._http.post(this._endpointAccept(id), undefined, {
      headers: { Authorization: `Bearer ${token}` }
    });

    switch (res.status) {
      case 200:
      case 204:
        return undefined;
      default:
        throw new NetworkError(res.status, res.data);
    }
  }
}
