import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { Button } from '../../new-lib/Button'
import { Typography } from '../../new-lib/Typography'
import styles from './LoginView.module.scss'
import { Loader } from 'src/new-lib/Loader'

type Props = {
  onLoginButtonClick: () => void
  loading: boolean
}

export const LoginView = ({ onLoginButtonClick, loading }: Props) => {
  const [mHeader, mParagraph, mButton] = [
    { b: '24px' },
    { b: '32px' },
    { b: '0px' }
  ]

  const [clicked, setClicked] = useState(false)

  const handleBtnClick = () => {
    onLoginButtonClick()
    setClicked(true)
  }

  return (
    <section className={styles.root}>
      <article className={styles.container}>
        <Loader show margin={{ b: '20px' }} />

        <Typography margin={mHeader} tag="h1" align="center">
          Logga in hos Capio
        </Typography>
        <Typography margin={mParagraph} tag="p" align="left">
          Få tillgång till rådgivning och hjälp online
        </Typography>

        <Button
          loading={loading || clicked}
          expand
          margin={mButton}
          onClick={handleBtnClick}
          track={{
            name: 'login',
            category: 'digital_care',
            action: 'authenticate'
          }}
        >
          Logga in
        </Button>
      </article>
    </section>
  )
}

LoginView.propTypes = {
  onLoginButtonClick: PropTypes.func.isRequired
}
