import React, { useMemo } from "react";
import { arrayOf, bool, func, object, string } from "prop-types";
import { ScreenContainer } from "./ScreenContainer";
import { Text, Select, Button, AlertBox } from "../../lib";
import { InfoDialog } from "../../lib";
import { createPath, paths } from "../../utils/urlPaths";
import { Icon } from "../../lib/Icon"


export const ProfileChatSettingsScreen = ({
    selectedRegion,
    regions,
    onRegionChange,
    selectedCareCenter,
    careCenters,
    onCareCenterChange,
    onSaveButtonClick,
    formError,
    submitting,
    showConfirmDialog,
    dialogListingHealthcareUnit,
    onConfirmDialogCloseButtonClick
}) => {


    const { listingInfo, listingLink } = useMemo(() => {
        if (!dialogListingHealthcareUnit) {
            return {
                listingLink: "",
                listingInfo: "",
            }
        }
        if (dialogListingHealthcareUnit.healthcareunitRegions === "Stockholm") {
            return {
                listingLink: createPath({ name: "listing > sign (hsaId)", params: [dialogListingHealthcareUnit.HsaId] }),
                listingInfo: `Om du vill så kan du lista dig på ${dialogListingHealthcareUnit.healthcareunit} via vår digitala listningstjänst.`,
            }
        }
        return {
            listingLink: paths["1177"],
            listingInfo: `Om du vill kan du lista dig på ${dialogListingHealthcareUnit.healthcareunit} via 1177.`,
        }

    }, [showConfirmDialog]);

    return (
        <ScreenContainer>
            <Text tag="h1">Chattinställningar</Text>
            <Text tag="p" style={{ marginBottom: "40px" }}>Här finner du inställningar för dina digitala vårdbesök.</Text>

            <Text tag="h2" style={{ marginBottom: "15px" }}>Vårdcentral</Text>
            <Text tag="p" style={{ marginBottom: "15px" }}>Välj vilken vårdcentral du vill ska vara förvald vid digitala vårdbesök.</Text>

            <Select
                label="Välj region"
                defaultValue={selectedRegion || "Välj region"}
                onChange={onRegionChange}
                fullWidth
                style={{ marginBottom: "15px" }}
                disabled={submitting}
                e2e="chat_settings_select_region"
            >
                {regions.map(r => r === selectedRegion ? null : <option key={r} value={r}>{r}</option>)}
            </Select>

            <Select
                label="Välj vårdcentral"
                defaultValue={selectedCareCenter || "Välj vårdcentral"}
                onChange={onCareCenterChange}
                fullWidth
                disabled={submitting}
                e2e="chat_settings_select_unit"
            >
                {careCenters.map(c => c === selectedCareCenter ? null : <option key={c} value={c}>{c}</option>)}
            </Select>


            <AlertBox text={formError} hidden={!formError} />
            <div style={{ marginTop: "40px" }}>
                <Button
                    onClick={onSaveButtonClick}
                    loading={submitting}
                    e2e="chat_settings_save_btn"
                >Spara val
                </Button>
            </div>

            <InfoDialog
                renderIcon={(size) =>
                (<Icon
                    name="info-circle"
                    size={size}
                    color="success"

                />)}
                heading="Ditt nya val har genomförts!"
                body={(
                    <>
                        <Text tag="p">{listingInfo}</Text>
                    </>)}
                open={showConfirmDialog}
                actions={
                    <>
                        <Button style={{ marginRight: "20px" }} to={listingLink}>Lista mig</Button>
                        <Button secondary onClick={onConfirmDialogCloseButtonClick}>Stäng</Button>

                    </>}
            />

        </ScreenContainer>
    )
}


ProfileChatSettingsScreen.propTypes = {
    selectedRegion: string,
    regions: arrayOf(string).isRequired,
    onRegionChange: func.isRequired,
    selectedCareCenter: string,
    healtcareUnits: arrayOf(string).isRequired,
    onCareCenterChange: func.isRequired,
    onSaveButtonClick: func.isRequired,
    formError: string,
    submitting: bool,
    showConfirmDialog: bool,
    dialogListingHealthcareUnit: object,
    onConfirmDialogCloseButtonClick: func.isRequired,
}