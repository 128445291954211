import React, { useMemo, useState, useRef, useEffect } from 'react'

type Props = {
  visibleOffset?: number
  wrapperStyles?: string
  placeholder?: React.ReactNode
  children?: React.ReactNode
}

export const RenderIfVisible = ({
  visibleOffset = 1000,
  wrapperStyles = '',
  placeholder = '',
  children,
}: Props) => {
  const [isVisible, setIsVisible] = useState<boolean>(true)
  const placeholderHeight = useRef()
  const wrapperRef = useRef(null)

  useEffect(() => {
    if (wrapperRef.current) {
      placeholderHeight.current = wrapperRef.current.offsetHeight
      const observer = new IntersectionObserver((entries) => {
        setIsVisible(entries[0].isIntersecting)
      },
        { rootMargin: `${visibleOffset}px 0px ${visibleOffset}px 0px` }
      )

      observer.observe(wrapperRef.current)
      return () => {
        if (wrapperRef.current) observer.unobserve(wrapperRef.current)
      }
    }
    return () => {}
  }, [])

  
  const _wrapperClass = useMemo(
    () => `${wrapperStyles}`,
    [wrapperStyles]
  )
  
  const placeholderStyle = { height: placeholderHeight.current }

  return (
    <div ref={wrapperRef} className={_wrapperClass}>
      {isVisible
        ? <>{children}</>
        : <div style={placeholderStyle}>{placeholder}</div>
      }
    </div>
  )
}
