import React, { Component } from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import style from './Text.module.scss';

class Text extends Component {
  render() {
    const {
      h1,
      h2,
      h3,
      h4,
      bold,
      body,
      body2,
      bodyLeft,
      contain,
      children,
      italic,
      className,
      id,
      e2e
    } = this.props;

    const comprisedStyle = classNames(
      {
        [style.h1]: h1,
        [style.h2]: h2,
        [style.h3]: h3,
        [style.bold]: bold,
        [style.body]: body,
        [style.body2]: body2,
        [style.bodyLeftAligned]: bodyLeft,
        [style.italic]: italic
      },
      className,
      { [style.contain]: contain }
    );

    const res = [
      {
        getComponent: (id, text, className, style) => (
          <h1 id={id} className={className} style={style} data-e2e={e2e}>
            {text}
          </h1>
        ),
        selected: h1
      },
      {
        getComponent: (id, text, className, style) => (
          <h2 id={id} className={className} style={style} data-e2e={e2e}>
            {text}
          </h2>
        ),
        selected: h2
      },
      {
        getComponent: (id, text, className, style) => (
          <h3 id={id} className={className} style={style} data-e2e={e2e}>
            {text}
          </h3>
        ),
        selected: h3
      },
      {
        getComponent: (id, text, className, style) => (
          <h4 id={id} className={className} style={style} data-e2e={e2e}>
            {text}
          </h4>
        ),
        selected: h4
      },
      {
        getComponent: (id, text, className, style) => (
          <p id={id} className={className} style={style} data-e2e={e2e}>
            {text}
          </p>
        ),
        selected: body
      },
      {
        getComponent: (id, text, className, style) => (
          <p id={id} className={className} style={style} data-e2e={e2e}>
            {text}
          </p>
        ),
        selected: body2
      },
      {
        getComponent: (id, text, className, style) => (
          <p id={id} className={className} style={style} data-e2e={e2e}>
            {text}
          </p>
        ),
        selected: bodyLeft
      }
    ].find(x => x.selected === true);

    if (!res) {
      return null;
    }

    return res.getComponent(id, children, comprisedStyle, this.props.style);
  }
}

Text.propTypes = {
  bold: PropTypes.bool,
  h1: PropTypes.bool,
  h2: PropTypes.bool,
  h3: PropTypes.bool,
  h4: PropTypes.bool,
  body: PropTypes.bool,
  bodyLeft: PropTypes.bool,
  children: PropTypes.any,
  className: PropTypes.string,
  style: PropTypes.object,
  contain: PropTypes.bool,
  id: PropTypes.string,
  e2e: PropTypes.string,
};

export default Text;
