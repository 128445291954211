import React, { useState, useEffect } from "react";
import { withRouter } from "react-router-dom";
import PropTypes from "prop-types";
import styles from "./ActionTopbarMobile.module.scss";
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import SwipeableDrawer from '@material-ui/core/SwipeableDrawer';
import { Icon } from "../Icon"
import { Logo } from "../Logo";
import { MenuItem, MenuList } from "../MenuList";
import Divider from "@material-ui/core/Divider";
import { Button, IconButton } from "../Button";
import { Text } from "../Text";

const DrawerButton = ({ icon, text, onClick }) => {

    return (
        <IconButton onClick={onClick} icon={
            <div>
                {icon}
                <Text tag="p" variant="pxs" transform="capitalize" align="center" style={{ color: "#fff" }} xs>{text}</Text>
            </div>
        } />
    )
}

const _TopbarMobileDrawer = ({ actions, history  }) => {
    
    const [open, setOpen] = useState(false);
    const toggleOpenAnd = (open) => (handler) => (event) => {
        if (event && event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
            return;
        }
        setOpen(open);
        handler && handler();
    }

    const toggleOpen = open => event => toggleOpenAnd(open)()(event);

    useEffect(() => {
        setOpen(false);
    }, [history.location.pathname]);

    return (
            <>

                <DrawerButton
                    onClick={toggleOpen(true)}
                    icon={<Icon name="nav-menu" color="light" />}
                    text="meny" />

                <SwipeableDrawer
                    elevation={0}
                    PaperProps={{ classes: { root: styles.drawerContainer } }}
                    anchor={"right"}
                    open={open}
                    onClose={toggleOpen(false)}
                    onOpen={toggleOpen(true)}
                >
                    <div className={styles.drawerCloseButtonContainer}>
                        <DrawerButton
                            onClick={toggleOpen(false)}
                            icon={<Icon name="remove" color="light" />}
                            text="stäng" />
                    </div>
                    <div className={styles.drawerPrimaryActionsContainer}>
                        <MenuList>
                            {actions.primary.map((link) => <MenuItem key={link.key} style={{ height: "70px" }} text={link.text} color="#fff" to={link.to} onClick={link.onClick} iconLeft={<Icon color="light" name={link.icon} />} onClick={toggleOpen(false)} />)}
                        </MenuList>
                    </div>
                    <Divider variant="middle" />
                    <div className={styles.drawerSecondaryActionsContainer}>
                        <MenuList>
                            {
                                actions.secondary.links.map((link) => (
                                    <MenuItem
                                        key={link.key}
                                        style={{ height: "40px" }}
                                        text={link.text}
                                        color="#fff"
                                        to={link.to}
                                        onClick={toggleOpenAnd(false)(link.onClick)}
                                    />))
                            }
                            {
                                actions.secondary.buttons.map((btn) => (
                                    <li key={btn.key} className={styles.buttonLi}>
                                        <Button
                                            secondary
                                            style={{
                                                color: "#fff",
                                                borderColor: "#fff",
                                                marginTop: "25px",
                                                marginBottom: "25px",
                                            }}
                                            to={btn.to}
                                            onClick={toggleOpenAnd(false)(btn.onClick)}
                                            children={btn.text}
                                        />
                                    </li>
                                ))
                            }
                        </MenuList>
                    </div>

                </SwipeableDrawer>
            </>
    );
}

const TopbarMobileDrawer = withRouter(_TopbarMobileDrawer);

const ActionTopbarMobile = ({ actions, logoIsNotLink }) => {
    return (
        <AppBar elevation={0} classes={{ root: styles.root }}>
            <Toolbar className={styles.toolbar}>
                <div className={styles.row}>
                    <div className={styles.leftSlot}>
                        <Logo isNotLink={logoIsNotLink} size={20} color="light" style={{ marginTop: "20px", marginBottom: "20px",  }} />
                    </div>
                    <div className={styles.rightSlot}>
                        <TopbarMobileDrawer actions={actions} />
                    </div>
                </div>
            </Toolbar>
        </AppBar>
    );
}

ActionTopbarMobile.propTypes = {
    primaryActions: PropTypes.array,
    secondaryActions: PropTypes.array,
    logoIsNotLink: PropTypes.bool,
}


export { ActionTopbarMobile }