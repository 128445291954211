import { useEffect } from 'react'
import { useMediaQuery } from '@material-ui/core'
import scss from '../_styles/index.module.scss'

export const useDeviceSize = () => {
  const isMatch = useMediaQuery(`(min-width: ${scss.desktopMinWidth})`)
  return { isMobile: !isMatch, isDesktop: isMatch }
}

export const useDisableScroll = (isDisabled: boolean) => {
  useEffect(() => {
    if (isDisabled) document.body.style.overflow = 'hidden'
    else document.body.style.overflow = 'unset'
  }, [isDisabled])
}
