import { useState, useMemo } from "react";

export const useHealthcareUnits = ({ healthcareUnits, idExtOfInitialHcu, }) => {
    
    const { healthcareunit: initialHealthcareUnit, healthcareunitRegions: initialRegion } =
        useMemo(() => healthcareUnits.find(cc => cc.idExt === idExtOfInitialHcu) || { healthcareunit: "", healthcareunitRegions: "" }
            , [idExtOfInitialHcu]);

    const mappedHealhcareUnits =
        useMemo(() => healthcareUnits.map(hcu => hcu.healthcareunit), [healthcareUnits.length]);

    const [selectionError, setSelectionError] = useState("");
    
    const [selectedRegion, setRegion] = useState(initialRegion)
    const [selectedHealthcareUnit, setHealthcareUnit] = useState(initialHealthcareUnit);

    const filteredHealthcareUnits =
        useMemo(() => {
            const regionHasBeenTouched = selectedRegion !== initialRegion;

            if (regionHasBeenTouched) {
                setHealthcareUnit(null);
            }

            if (selectedRegion === "default" || !selectedRegion) {
                return mappedHealhcareUnits;
            }

            return mappedHealhcareUnits.filter(mappedHcu => healthcareUnits.find(hcu => hcu.healthcareunit === mappedHcu).healthcareunitRegions === selectedRegion);
        }, [selectedRegion]);

        

    return { 
        selectedRegion, 
        setRegion: ev => {
            setRegion(ev.target.value);
            setSelectionError("");
        },
        selectedHealthcareUnit,
        initialHealthcareUnit,
        setHealthcareUnit: ev => {
            setHealthcareUnit(ev.target.value);
            setSelectionError("");
        },
        filteredHealthcareUnits,
        selectionError,
        setSelectionError
    }
}