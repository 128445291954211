export const APPLICATION_GLOBAL_LOADING = 'APPLICATION_GLOBAL_LOADING';
export const USER_LOGGED_IN = 'USER_LOGGED_IN';
export const SAVE_SESSION_TOKEN = 'SAVE_SESSION_TOKEN';
export const SAVE_ID_TOKEN = 'SAVE_ID_TOKEN';
export const SAVE_IDENTITY_INFORMATION = 'SAVE_IDENTITY_INFORMATION';
export const TOGGLE_SIDE_DRAWER = 'TOGGLE_SIDE_DRAWER';
export const USER_IS_REGISTERED = 'USER_IS_REGISTERED';
export const USER_IS_NOT_REGISTERED = 'USER_IS_NOT_REGISTERED';
export const ERROR_OCCURED = 'ERROR_OCCURED';
export const SIGN_OUT = 'SIGN_OUT';
export const END_SESSION = 'END_SESSION';
export const CREATE_SESSION_TOKEN = 'CREATE_SESSION_TOKEN';
export const SAVE_CODE = 'SAVE_CODE';
export const SAVE_SIGN_CODE = 'SAVE_SIGN_CODE';
export const SAVE_REDIRECT_URI = 'SAVE_REDIRECT_URI';
export const SAVE_LISTING_PDF_URI = 'SAVE_LISTING_PDF_URI';
