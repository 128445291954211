import React, { useState, useEffect } from 'react'
import styles from './ListingSelectView.module.scss'
import { Dropdown } from '../../new-lib/Dropdown'
import { Button } from '../../new-lib/Button'
import { Typography } from '../../new-lib/Typography'
import { Icon, IconNames } from '../../new-lib/Icon'
import { Modal } from '../../new-lib/Modal'
import { useDeviceSize } from '../../new-lib/_utils'
import PromptCardImage from '../../assets/images/listing-prompt-card-img.png'
import propTypes from 'prop-types'
import { analytics } from 'src/new-lib/_utils/analytics'

/**
 * @description ListingSelect View
 *
 **/

type Props = {
  regions: Array<string>
  selectedRegion: string
  onRegionChange: (value: string) => void
  careCenters: Array<string>
  selectedCareCenter: string
  onCareCenterChange: (value: string) => void
  onNextButtonClick: () => void
  infoDialogIsOpen: boolean
  onInfoDialogClose: () => void
  protectedIdModalIsOpen: boolean
  onProtectedIdModalClose: () => void
}

type StatementType = {
  icon: IconNames
  text: string
}

const infoHeaderTitle = 'Lista dig hos Capio'
const infoHeaderBody =
  'I Sverige är vårdvalet fritt och personligt. Du kan välja vilken vårdmottagning du vill, oavsett om den ligger där du är folkbokförd eller någon annanstans.'

const promptTitle = 'Hos oss är din hälsa i goda händer - vi erbjuder dig:'
const promptStatements: StatementType[] = [
  { icon: 'check', text: 'Digital kontakt inom 15 minuter' },
  { icon: 'check', text: 'Tid på din vårdcentral inom 24 h' },
  { icon: 'check', text: 'Tillgång till 100+ vårdcentraler' }
]
const promptBodyText =
  'För oss på Capio är det viktigt att du känner dig trygg med de personer du möter när du besöker oss och vår ambition är att du i möjligaste mån får träffa samma läkare från gång till gång.'

export const ListingSelectView = ({
  regions,
  selectedRegion,
  onRegionChange,
  careCenters,
  selectedCareCenter,
  onCareCenterChange,
  onNextButtonClick,
  infoDialogIsOpen,
  onInfoDialogClose,
  protectedIdModalIsOpen,
  onProtectedIdModalClose
}: Props) => {
  const [regionErrorMessage, setRegionErrorMessage] = useState('')
  const [careCenterErrorMessage, setCareCenterErrorMessage] = useState('')

  const { isMobile, isDesktop } = useDeviceSize()

  const handleRegionChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const value = (event as unknown) as string
    onRegionChange(value)
  }
  const handleCareCenterChange = (ev: React.ChangeEvent<HTMLSelectElement>) => {
    onCareCenterChange((ev as unknown) as string)
    analytics.track({
      name: 'listing_primary_care_unit',
      category: 'listing',
      action: 'select_primary_care_unit'
    })
  }

  useEffect(() => {
    if (selectedRegion) setRegionErrorMessage('')
    if (selectedCareCenter) setCareCenterErrorMessage('')
  }, [selectedRegion, selectedCareCenter])

  type PromptProps = {
    title: string
    promptStatements: StatementType[]
    bodyText: string
  }

  const PromptCard = ({ title, promptStatements, bodyText }: PromptProps) => {
    return (
      <section className={styles['prompt-card']}>
        <img
          className={styles['prompt-card-image-half']}
          src={PromptCardImage}
          alt="patient talking to nurse"
        />
        <div className={styles['prompt-card-info-half']}>
          <div className={styles['prompt-card-statements']}>
            <Typography tag="h2" margin={{ b: 16 }}>
              {title}
            </Typography>
            {promptStatements.map((statement, i) => (
              <div key={i} className={styles['statement-container']}>
                <span>
                  <Icon name={statement.icon} />
                </span>
                <Typography tag="p" margin={{ l: 8 }}>
                  {statement.text}
                </Typography>
              </div>
            ))}
          </div>
          <div className={styles['prompt-card-body-text']}>
            <Typography tag="p" align={isMobile ? 'left' : 'right'} margin={{}}>
              {bodyText}
            </Typography>
          </div>
        </div>
      </section>
    )
  }

  const SelectUnit = () => {
    return (
      <section className={styles['select-unit-container']}>
        <div className={styles['select-unit-content']}>
          <Typography tag="h2" margin={{ b: 32 }}>
            Välj vårdcentral
          </Typography>
          <Dropdown
            title="Region"
            defaultValue="Välj region"
            margin={{ b: 24 }}
            options={regions}
            onChange={handleRegionChange}
            value={selectedRegion}
            errorMessage={regionErrorMessage}
          />
          <Dropdown
            title="Mottagning"
            defaultValue="Välj mottagning"
            margin={{ b: 32 }}
            options={careCenters}
            onChange={handleCareCenterChange}
            value={selectedCareCenter}
            errorMessage={careCenterErrorMessage}
          />
          <div className={styles.buttons}>
            <Button
              variant="primary"
              expand={isMobile}
              childMargin={isDesktop && { r: 8 }}
              onClick={onNextButtonClick}
            >
              fortsätt
            </Button>
            <Button variant="secondary-dark" to="home" expand={isMobile}>
              avbryt
            </Button>
          </div>
        </div>
      </section>
    )
  }

  return (
    <>
      <Modal
        title="Vi kan inte lista dig"
        text="Vänligen kontakta din vårdcentral så hjälper de dig"
        isOpen={protectedIdModalIsOpen}
        handleClose={onProtectedIdModalClose}
        iconName={'info'}
      />
      <Modal
        title="Du måste lista dig via 1177"
        text="I den region du befinner dig i använder du 1177 Vårdguiden för att lista dig själv eller ditt barn på en av våra vårdcentraler"
        isOpen={infoDialogIsOpen}
        handleClose={onInfoDialogClose}
        closeButton={{
          variant: isMobile ? 'secondary-dark' : 'text',
          text: 'avbryt'
        }}
        iconName={'share-link'}
      >
        <Button
          variant="primary"
          iconRight="share-link"
          childMargin={{ r: 8 }}
          to="https://listning.1177.se/"
          targetTo="_blank"
        >
          lista dig på 1177
        </Button>
      </Modal>
      <section className={styles['info-header']}>
        <div className={styles['info-header-content']}>
          <Typography
            tag="h1"
            align="center"
            margin={{ b: isMobile ? 16 : 24 }}
          >
            {infoHeaderTitle}
          </Typography>
          <Typography
            tag="p"
            align="center"
            lineHeight={isMobile ? 'md' : 'xl'}
            margin={{}}
          >
            {infoHeaderBody}
          </Typography>
        </div>
      </section>
      <div className={styles['body-container']}>
        <div className={styles['body-content']}>
          <PromptCard
            title={promptTitle}
            promptStatements={promptStatements}
            bodyText={promptBodyText}
          />
          <SelectUnit />
        </div>
      </div>
    </>
  )
}
