import { ExternalChatView } from "./ExternalChatView"
import { To } from "src/new-lib/LinkTo";

export const ExternalChatPage = ({defaultHCU}) => {
    const unit = defaultHCU()

    return <ExternalChatView 
        unitName={unit.healthcareunit}
        toUnit={unit.digitalProviderExternalUri as To}
        toHome="home"
    />
}