import styles from './FlowScreen.module.scss'
import config from '../../api/config'
import { Header } from 'src/new-lib/Header'

type Props = {
  targetSrc: string
  onLoad: () => void
}

export const FlowScreen = ({ targetSrc, onLoad }: Props) => {
  const isIOS15 = window.visualViewport !== undefined


  return (
    <div style={{ width: '100vw', height: '100vh' }}>
      <Header isLoggedIn variant='chat' />
      <div style={{ height: isIOS15 ? '80vh' : '100vh', display: 'flex', flex: 1 }}>
        <iframe
          allowTransparency={true}
          title='CapioGo'
          className={styles.respIframe}
          src={targetSrc}
          allow={`camera ${config.capioGoOrigin}; geolocation; microphone ${config.capioGoOrigin};`}
          onLoad={onLoad}
        />
      </div>
    </div>
  )
}
