import { connect } from 'react-redux'
import { Switch, Route, withRouter } from 'react-router-dom'
import { paths } from '../utils/urlPaths'
import { Feature } from '../featureManager'
import {
  RegistrationLayout,
  RegistrationFormPage,
  RegistrationAcceptTosPage,
  LoggedInLayout,
  LoggedOutLayout,
  ProfileEditContactInfoPage,
  ListingSelectPage,
  ListingConfirmPage,
  ListingReceiptPage,
  FastListingSelectPage,
  FastListingConfirmPage,
  FindCareUnitPage
} from '.'
import {
  SelectCareUnitRegionView,
  SelectedCareUnitPage,
  CareUnitPage
} from 'src/pages/PreSelectedCareUnit'
import { SelectedModalView } from 'src/pages/PreSelectedCareUnit/SelectedModalView'
import { FaqPage } from 'src/pages/Faq'
import { FixMeLater } from 'src/new-lib/_types'

type UserIsRegistered = { userIsRegistered: boolean }

export const RegistrationRoutes = () => (
  <Switch>
    <LoggedOutLayout>
      <Route
        path={paths['registration > contact']}
        render={() => <RegistrationFormPage />}
      />

      <Route
        path={paths['registration > ToS']}
        render={() => <RegistrationAcceptTosPage />}
      />
    </LoggedOutLayout>
  </Switch>
)

export const ProfileRoutes = () => (
  <Switch>
    <LoggedInLayout>
      <Route
        exact
        path={paths['my profile']}
        render={() => <ProfileEditContactInfoPage />}
      />

      <Route
        exact
        path={paths['chat > onboarding > step 1']}
        render={() => <SelectCareUnitRegionView />}
      />

      <Route
        exact
        path={paths['chat > onboarding > step 2 (region)']}
        render={() => <SelectedCareUnitPage />}
      />

      <Route
        exact
        path={paths['chat > settings > selected']}
        render={() => <CareUnitPage />}
      />

      <Route
        exact
        path={paths['chat > settings > modal']}
        render={() => <SelectedModalView />}
      />
    </LoggedInLayout>
  </Switch>
)

export const FaqRoutes = () => (
  <Switch>
    <LoggedInLayout>
      <FaqPage />
    </LoggedInLayout>
  </Switch>
)

const _ListingRoutes = ({ userIsRegistered }: UserIsRegistered) => {
  localStorage.setItem('fastlistingMode', 'inactive')
  return (
    <Switch>
      <LoggedInLayout>
        <Route
          exact
          path={paths['listing > select']}
          component={ListingSelectPage}
        />

        <Route
          exact
          path={paths['listing > sign (hsaId)']}
          component={ListingConfirmPage}
        />

        <Route
          exact
          path={paths['listing > completed']}
          component={() => (
            <ListingReceiptPage userIsRegistered={userIsRegistered} />
          )}
        />
      </LoggedInLayout>
    </Switch>
  )
}

const mapStateToProps = (state: FixMeLater) => ({
  userIsRegistered: state.app.userIsRegistered
})

export const ListingRoutes = withRouter(
  connect(mapStateToProps)(_ListingRoutes)
)

const _FastListingRoutes = ({ userIsRegistered }: UserIsRegistered) => {
  const routes = (
    <>
      <Route
        path={paths['fast listing > sign (hsaId)']}
        component={() => <FastListingConfirmPage />}
        exact
      />

      <Route
        exact
        path={paths['fast listing > select']}
        component={() => <FastListingSelectPage />}
      />

      <Route
        exact
        path={paths['fast listing > completed']}
        component={() => (
          <ListingReceiptPage userIsRegistered={userIsRegistered} />
        )}
      />
    </>
  )

  return userIsRegistered ? (
    <Switch>
      <LoggedInLayout>{routes}</LoggedInLayout>
    </Switch>
  ) : (
    <Switch>
      <RegistrationLayout>{routes}</RegistrationLayout>
    </Switch>
  )
}

export const FastListingRoutes = withRouter(
  connect(mapStateToProps)(_FastListingRoutes)
)

export const HealthcareUnitsRoutes = () => {
  return (
    <Feature enabled="findHealthcareUnits">
      <Switch>
        <LoggedInLayout>
          <FindCareUnitPage />
        </LoggedInLayout>
      </Switch>
    </Feature>
  )
}
