import React, { useMemo, useState } from 'react'
import classNames from 'classnames'
import scss from './Checkbox.module.scss'
import { rem, useDeviceSize } from '../_utils'
import { FixMeLater } from '../_types/'

interface Props {
  onChange?: (ev: React.ChangeEvent) => void
  label?: string
  name?: string
  defaultChecked?: boolean
  error?: string
  disabled?: boolean
  dummy?: boolean
  track?: FixMeLater
  forwardedRef?: React.RefObject<HTMLInputElement>
}

export const Checkbox = ({
  onChange,
  label,
  name = label,
  defaultChecked,
  error,
  disabled,
  track,
  dummy,
  forwardedRef
}: Props) => {
  const boxClasses = classNames({
    [scss['default-box']]: !error,
    [scss['error-box']]: error && true,
    [scss['disabled-box']]: disabled && true,
    [scss['disabled-box-dummy']]: dummy && true
  })

  const textClasses = classNames({
    [scss.label]: true,
    [scss['default-label-color']]: !error,
    [scss['error-label-color']]: error && true,
    [scss['disabled-label-color']]: disabled
  })

  const [showTicker, setShowTicker] = useState(null)

  const onInputChange: React.ChangeEventHandler<HTMLInputElement> = (ev) => {
    onChange(ev)
    setShowTicker(ev.target.checked)
  }

  const renderTicker = () =>
    showTicker === null && defaultChecked ? (
      <CheckboxTicker />
    ) : (
      showTicker && <CheckboxTicker />
    )

  return (
    <div className={scss.container}>
      <label className={scss['form-control']}>
        <div className={scss.checkbox}>
          <input
            data-track={track}
            className={boxClasses}
            defaultChecked={defaultChecked}
            type="checkbox"
            name={name}
            onChange={onInputChange}
            disabled={disabled}
            ref={forwardedRef}
          />
          {renderTicker()}
        </div>
        <span className={textClasses}>{label}</span>
      </label>
      <span className={scss['error-helper']}>{error}</span>
    </div>
  )
}

const CheckboxTicker = () => {
  const { isMobile } = useDeviceSize()

  const value = useMemo(() => {
    const value = isMobile ? 24 : 16
    return {
      size: rem(value),
      viewBox: `0 0 ${value} ${value}`
    }
  }, [isMobile])

  return (
    <svg
      className={scss.tick}
      width={value.size}
      height={value.size}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M5.33325 13.6522L9.42631 18.281C9.87435 18.7877 10.6857 18.7128 11.0333 18.1326L18.6666 5.39136"
        stroke="#0D3050"
        strokeWidth="2"
        strokeLinecap="round"
      />
    </svg>
  )
}
