import { UnsubscribeType } from "src/api/services/UnsubscribeAPI";
import {
    UNSUBSCRIBE_NEWSLETTER,
    UNSUBSCRIBE_NEWSLETTER_FAILED,
    UNSUBSCRIBE_NEWSLETTER_SUCCEEDED
  } from "../newsletter/action-types"

export function unsubscribeNewsletter( type: UnsubscribeType, unsubscribeToken: string, messageId: string ) {
    return async (dispatch, getState, api) => {
      const { unsubscribeToken: unsubscribedToken } = getState().newsletter;
  
      if (unsubscribedToken === unsubscribeToken) {
        // Only need to unsubscribe once
        return;
      }
  
      dispatch({ type: UNSUBSCRIBE_NEWSLETTER, payload: unsubscribeToken });
  
      try {
        await api.services.unsubscribe.unsubscribe(type, unsubscribeToken, messageId);
        dispatch({ type: UNSUBSCRIBE_NEWSLETTER_SUCCEEDED });
      } catch (e) {
        dispatch({ type: UNSUBSCRIBE_NEWSLETTER_FAILED });
      }
    }
  }