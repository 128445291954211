import React, { useState, useRef, useEffect } from "react"
import scss from "./Modal.module.scss"
import classNames from "classnames"
import { useDisableScroll } from '../_utils'
import type { ModalSizes } from "./Modal"

type Props = {
  isOpen: boolean,
  size?: ModalSizes
  handleClose: () => void
  children: React.ReactNode
}

export const EmptyModal = ({ isOpen: externalIsOpen, size = "sm", handleClose, children }: Props) => {
  const [internalIsOpen, setInternalIsOpen] = useState(false)
  const [closeModalAnimation, setCloseModalAnimation] = useState(false)

  const backgroundRef = useRef<HTMLDivElement>(null)

  useDisableScroll(internalIsOpen)

  const closeModal = () => {
    setCloseModalAnimation(true)

    const handleAnimation = () => {
      setCloseModalAnimation(false)
      setInternalIsOpen(false)
      handleClose()
    }

    backgroundRef.current.addEventListener('animationend', () => handleAnimation())
    backgroundRef.current.removeEventListener('animationend', () => handleAnimation())
  }

  const handleBackgroundClick = (ev: React.MouseEvent<HTMLDivElement>) => {
    const target = ev.target as HTMLDivElement
    if (target.id === 'modal-background') {
      ev.preventDefault()
      closeModal()
    }
  }

  // since the close button will be created externally from this component, 
  // when it gets clicked the `closeModal` function within this modal won't be called,
  // causing the modal to just "disappear" without any animations.
  useEffect(() => {    
    if (externalIsOpen) setInternalIsOpen(true)
    else if (internalIsOpen) closeModal()
  }, [externalIsOpen])

  const backgroundClassNames = classNames({
    [scss.background]: true,
    [scss["background-open"]]: internalIsOpen,
    [scss["background-close"]]: closeModalAnimation,
  })

  const modalClassNames = classNames({
    [scss['modal-base']]: true,
    [scss['modal-lg']]: size === 'lg',
    [scss['modal-md']]: size === 'md',
    [scss['modal-sm']]: size === 'sm',
    [scss['modal-open']]: internalIsOpen,
    [scss['modal-close']]: closeModalAnimation
  })

  return (
    <>
      {internalIsOpen && (
        <div
          id="modal-background"
          className={backgroundClassNames}
          onClick={handleBackgroundClick}
          ref={backgroundRef}>
          <div className={modalClassNames}>
            <div className={scss["content-base-empty-modal"]}>
              {children}
            </div>
          </div>
        </div>
      )}
    </>
  )
}
