import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';

import styles from './ErrorPage.module.scss';
import Text from "./Text";

export class ErrorPage extends Component {
  render() {
    return (
      <div className={styles.errorPage}>
        <div className={styles.content}>
          <div className={styles.textContainer}>
            <div className={styles.title}>
              <Text h1>Något gick fel</Text>
            </div>
            <Text body>
              Tyvärr gick något fel. Var god försök igen lite senare eller kontakta oss om
              felet kvarstår.
            </Text>
          </div>
        </div>
      </div>
    );
  }
}

export default withRouter(ErrorPage);
