import React, { useState } from "react";
import classNames from "classnames";
import MuiNativeSelect from '@material-ui/core/NativeSelect';
import MuiInputBase from "@material-ui/core/InputBase";
import MuiInputLabel from "@material-ui/core/InputLabel";
import MuiFormControl from "@material-ui/core/FormControl";
import styles from "./Select.module.scss";
import { string, func, bool, element, object } from "prop-types";


const Select = ({ defaultValue, label, onChange, error, disabled, style, children, fullWidth, e2e }) => {
    const [selectIsFocused, setSelectIsFocused] = useState(false);

    const inputClasses = classNames({
        [styles.input]: true,
        [styles.inputColor]: !selectIsFocused && !error,
        [styles.inputFocusedColor]: selectIsFocused && !error,
        [styles.inputDisabledColor]: disabled,
        [styles.inputErrorColor]: !disabled && error || false,
    });

    const labelId = `${label}_label_id`;

    return (
        <MuiFormControl fullWidth={fullWidth} error disabled={disabled} style={style}>
            <MuiInputLabel htmlFor={labelId} classes={{ root: styles.label }}>{label}</MuiInputLabel>
            <MuiNativeSelect
                fullWidth={true}
                defaultValue={defaultValue}
                onChange={onChange}
                onFocus={() => setSelectIsFocused(true)}
                onBlur={() => setSelectIsFocused(false)}
                classes={{ root: styles.select }}
                inputProps={{id: labelId, "data-e2e": e2e}}
                input={<MuiInputBase classes={{ root: inputClasses }}
                />}
            >
                <option aria-label="defaultValue">{defaultValue}</option>
                {children}
            </MuiNativeSelect>
        </MuiFormControl>);
}


Select.propTypes = {
    defaultValue: string,
    label: string,
    onChange: func,
    fullWidth: bool,
    error: bool,
    disabled: bool,
    style: object,
    children: element,
    fullWidth: bool,
    e2e: string,
}

export { Select };
