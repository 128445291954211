import React, { Component } from 'react'
import { connect } from 'react-redux'
import queryString from 'query-string'
import { actions } from '../../store/reducers/app'
import { paths } from '../../utils/urlPaths'
import { LoadingScreen } from '../../new-lib/LoadingScreen/LoadingScreen'

class SignCallback extends Component {
  fetchStateAndComplete = (nextRoute) => {
    if (this.props.app.userIsRegistered) {
      this.props
        .dispatch(actions.fetchMinimumUserState())
        .then((data) => {
          this.props.history.push(nextRoute)
        })
        .catch(() => {
          this.props.history.push(nextRoute)
        })
    } else {
      this.props.history.push(nextRoute)
    }
  }

  componentDidMount () {
    const urlQueryParams = queryString.parse(this.props.history.location.search)
    const fastListingMode = localStorage.getItem('fastlistingMode')
    if (urlQueryParams.error === 'access_denied') {
      if (fastListingMode) {
        this.props.history.replace(paths["fast listing > select"])
      } else {
        this.props.history.replace(paths["listing > select"])
      }
    }

    this.props.dispatch(actions.saveSignCode(urlQueryParams.code))
    if (urlQueryParams.error !== 'access_denied') {
      this.props
        .dispatch(actions.signListingContract())
        .then(() => {
          if (fastListingMode === 'inactive') {
            this.fetchStateAndComplete(paths["listing > completed"])
          } else if (fastListingMode) {
            this.fetchStateAndComplete(paths["fast listing > completed"])
          } else {
            this.fetchStateAndComplete(paths["listing > completed"])
          }
        })
        .catch((err) => {
          console.log(err)
        })
    }
  }

  render () {
    return <LoadingScreen userIsLoggedIn />
  }
}

export const SignCallbackPage = connect((state) => {
  return {
    app: state.app
  }
})(SignCallback)
