import React, { Component } from 'react'
import { connect } from 'react-redux'
import { paths } from '../../../utils/urlPaths'
import { SelectRegionView } from '../../SeekCare'

class RegionSelect extends Component {
  sortRegions = (regions) => {
    return regions.sort((a, b) => {
      if (a.value < b.value) { return -1 }
      if (a.value > b.value) { return 1 }
      return 0
    })
  }

  selectRegion = (region) => {
    this.props.onSwitchBoardRegionSelect(region)
    if (this.props.location.state && this.props.location.state.saveMode) {
      //TODO: Investigate what saveMode means???
      this.props.history.push(paths["switchboard > select unit (2)"], { saveMode: true })
    } else {
      this.props.history.push(paths["switchboard > select unit (2)"])
    }
  }

  generateRegions = () => {
    return this.sortRegions(this.props.regions).map(d => ({ name: d.value, onClick: () => this.selectRegion(d.value) }))
  }

  render () {
    return (
      <SelectRegionView regions={this.generateRegions()} />
    )
  }
}

export const RegionSelectPage = connect()(RegionSelect)
