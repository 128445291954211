import React from "react";
import { Link } from "react-router-dom";
import MUIButton from "@material-ui/core/Button";
import styles from "./Button.module.scss";
import PropTypes, { string } from "prop-types";
import classNames from "classnames";
import { Icon } from "../Icon"
import { isWebUrl } from "../../utils/functions";
import Loader from "react-loader-spinner";
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import scss from "../../lib/_styles/variables.module.scss";
import { useDeviceSize } from "../_hooks";

export const Button = ({
    primary,
    secondary,
    tertiary,
    small,
    flat,
    alert,
    disabled,
    startIcon,
    endIcon,
    onClick,
    to,
    onFocus,
    onBlur,
    style,
    children,
    ariaDescribedBy,
    loading,
    noPadding=false,
    noMargin=false,
    dynamicWidth,
    e2e,
 }) => {

    const _style = { minWidth: !dynamicWidth ? "150px" : null, ...style }

    primary = !secondary && !tertiary && !alert && !flat;

    const root = classNames({
        [styles.primary]: primary,
        [styles.secondary]: !alert && secondary,
        [styles.secondaryAlert]: alert && secondary,
        [styles.tertiary]: tertiary,
        [styles.small]: small,
        [styles.alert]: alert,
        [styles.flat]: flat,
        [styles.padding]: !loading,
        [styles.paddingLoading]: loading,
        [styles.noPadding]: noPadding,
        [styles.noMargin]: noMargin,
    });

    const variant = mapArgsToMUIVariant({ primary, secondary, tertiary, flat });

    const render = (eIcon) => (
      <MUIButton
        classes={{ root, label: styles.label }}
        style={_style}
        variant={variant}
        disableElevation
        onClick={onClick}
        onFocus={onFocus}
        onBlur={onBlur}
        disabled={disabled || loading}
        component={to ? "div" : "button"}
        startIcon={<div style={{height: "100%"}}>{startIcon}</div>}
        endIcon={eIcon}
        aria-describedby={ariaDescribedBy}
        data-e2e={e2e}
      >
        {(loading && (
          <Loader
            type="Oval"
            color={mapArgsToLoaderColor({ alert, primary })}
            width={34}
            height={34}
          />
        )) ||
          children}
      </MUIButton>
    );

    const { isBiggerThanTablet } = useDeviceSize();

    if (to && isWebUrl(to)) {
        return <a style={{ textDecoration: "none" }} href={to} target={isBiggerThanTablet ? "_blank" : "self"}>{render(<div style={{ marginBottom: 4 }}><Icon size="xs" color={primary && "light" || alert && "alert"} name="expand" /></div>)}</a>
    }
    if (to) {
        return <Link style={{ textDecoration: "none" }} to={to}>{render(endIcon)}</Link>
    }

    return render(endIcon);

}

Button.propTypes = {
    primary: PropTypes.bool,
    secondary: PropTypes.bool,
    tertiary: PropTypes.bool,
    small: PropTypes.bool,
    flat: PropTypes.bool,
    alert: PropTypes.bool,
    disabled: PropTypes.bool,
    onClick: PropTypes.func,
    to: PropTypes.string || PropTypes.object,
    onBlur: PropTypes.func,
    startIcon: PropTypes.element,
    endIcon: PropTypes.element,
    style: PropTypes.object,
    ariaDescribedBy: PropTypes.any,
    loading: PropTypes.bool,
    e2e: string,
}


const mapArgsToMUIVariant = ({ secondary, flat }) => {
    if (secondary) {
        return "outlined";
    }
    if (flat) {
        return "text"
    }
    return "contained";
}

const mapArgsToLoaderColor = ({ alert, primary }) => {
    if (alert) {
        return scss.alert;
    }
    if (primary) {
        return "#fff";
    }
    return scss.black;
}
