import React from 'react'
import light from '../../assets/logos/capio-white-ramsey-logo.svg'
import dark from '../../assets/logos/capio-blue-ramsey-logo.svg'
import { rem } from '../_utils'
import PropTypes from 'prop-types'

const logos = {
  light,
  dark,
}

const sizes = {
  sm: rem('20px'),
  md: rem('40px'),
  lg: rem('56px'),
}

type Props = {
  size?: keyof typeof sizes;
  color?: keyof typeof logos;
  style?: React.CSSProperties;
}

export const Logo = ({ size = 'md', color = 'light', style = {} }: Props) => (
  <img
    src={logos[color]}
    alt="Capios logotyp"
    data-e2e="capio_logo"
    style={{ ...style, height: sizes[size] }}
  />
)

Logo.propTypes = {
  size: PropTypes.oneOf(['sm', 'md', 'lg']),
  color: PropTypes.oneOf(['light', 'dark']),
  style: PropTypes.object,
}


