import { isValidPhoneNumber } from 'react-phone-number-input/mobile'
import parseDate from "date-fns/parse";
import differenceInYears from "date-fns/differenceInYears"
import isBefore from "date-fns/isBefore";

export const isSwedishCellPhoneNumber = {
  rule: val => {
    const regex = RegExp(
      /^((\d{2}[1-9]\d{0,2})|([0]\d{1,3}))((\d{2}(\d{2}){2})|(\d{3}(\d{3})*(\d{2})+))$/
    );
    if (!(val.length >= 10)) {
      return false;
    }
    return regex.test(val);
  },
  errorMessage: "Fältet innehåller inte ett giltigt mobilnummer."
};

export const isValidMobilePhoneNumber = {
  rule: val => {
    return isValidPhoneNumber(val);
  },
  errorMessage: "Fältet innehåller inte ett giltigt mobilnummer."
};

export const isEmailAddress = {
  rule: val => {
    const regex = RegExp(/^[-!#$%&'*+\/0-9=?A-Z^_a-z`{|}~](\.?[-!#$%&'*+\/0-9=?A-Z^_a-z`{|}~])*@[a-zA-Z0-9](-*\.?[a-zA-Z0-9])*\.[a-zA-Z](-?[a-zA-Z0-9])+$/);
    return regex.test(val);
  },
  errorMessage: "Fältet innehåller inte en giltig e-post."
};

export const isValidPersonalNumberOfMinor = {
  rule: pn => {
    if (!personalNumberContainsBirthDateOfMinor(pn))
        return false;

    let original_ssn = Object.assign({}, pn);
    pn = pn
      .replace(/\D/g, "")
      .split("")
      .reverse()
      .slice(0, 10);

    if (pn.length !== 10) {
      return false;
    }

    let sum = pn
      .map(function(n) {
        return Number(n);
      })
      .reduce(function(previous, current, index) {
        if (index % 2) current *= 2;
        if (current > 9) current -= 9;
        return previous + current;
      });
    return 0 === sum % 10 && Object.keys(original_ssn).length === 12;
  },
  errorMessage:
    "Kräver giltigt personnummer för barn under 18 år (12 siffror)."
};

export const personalNumberContainsBirthDateOfMinor = (ssn, now = new Date()) => {
  const birthDay = parseDate(ssn.substring(0, 8), "yyyyMMdd", now);

  if (!isBefore(birthDay, now)) {
    return false;
  }

  const age = differenceInYears(now, birthDay);

  if (age >= 18) {
    return false;
  }

  return true;
};
