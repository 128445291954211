import React, { ReactEventHandler, useState } from 'react'
import styles from './InfoCheckbox.module.scss'
import { Icon, IconNames } from '../Icon'
import { Checkbox } from './Checkbox'
import { Modal, ModalSizes } from '../Modal'
import { marginToCssProp } from '../_utils'
import { FixMeLater } from '../_types'
import { TextAligns } from '../Typography'
import TextReader from 'src/new-lib/Checkbox/TextReader'
import { ModalContent } from '../Modal/Modal'

type Props = {
  onChange: (ev: React.ChangeEvent) => void
  label: string
  name?: string
  defaultChecked?: boolean
  error?: string
  disabled?: boolean
  track?: FixMeLater
  forwardedRef?: React.RefObject<HTMLInputElement>
  modalHeading: string | string[]
  modalText: ModalContent
  modalTextAlign?: TextAligns
  modalSize?: ModalSizes
  modalIcon?: IconNames
  buttonCloseLabel?: string
  margin?: FixMeLater
  text?: string
  animation?: boolean
  secondAnimation?: boolean
}

export const InfoCheckbox = ({
  defaultChecked,
  disabled,
  onChange,
  label,
  name = label,
  modalIcon,
  modalHeading,
  modalText,
  modalSize,
  modalTextAlign = 'center',
  buttonCloseLabel,
  error,
  track,
  margin,
  forwardedRef,
  text,
  animation,
  secondAnimation
}: Props) => {
  const [modalIsOpen, setModalIsOpen] = useState<boolean>(false)

  const handleClose = () => setModalIsOpen(false)
  const handleOpen: ReactEventHandler = (ev) => {
    ev.preventDefault()
    setModalIsOpen(true)
  }

  return (
    <div
      className={text ? styles.second : styles.container}
      style={{ margin: marginToCssProp(margin) }}
    >
      <Checkbox
        defaultChecked={defaultChecked}
        disabled={disabled}
        onChange={onChange}
        label={label}
        name={name}
        data-track={track}
        error={error}
        forwardedRef={forwardedRef}
      />

      {text ? (
        <div className={styles.second} onClick={handleOpen}>
          <TextReader
            text={secondAnimation}
            animation={animation}
            centered
            children={text}
          />
        </div>
      ) : (
        <button className={styles.button} onClick={handleOpen}>
          <Icon size="md" name="info" color="primary" />
        </button>
      )}
      <Modal
        iconName={modalIcon}
        isOpen={modalIsOpen}
        handleClose={handleClose}
        closeButton={{
          variant: 'primary',
          text: buttonCloseLabel
        }}
        title={modalHeading}
        text={modalText}
        align={modalTextAlign}
        size={modalSize}
      />
    </div>
  )
}
