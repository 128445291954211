import React, { Component } from 'react'
import { Switch, Route, withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import queryString from 'query-string'
import config from '../../api/config'

import styles from './Switchboard.module.scss'
import { actions as consentActions } from '../../store/reducers/consents'
import { paths } from '../../utils/urlPaths'
import { ContactCareUnitView } from '../SeekCare'
import { HealthCareUnitSelectPage } from './HealthCareUnitSelect'
import { RegionSelectPage } from './RegionSelect/RegionSelectPage'
import { DefaultUnitChoicePage } from './DefaultUnitChoice'
import { UnitNotIntegratedPage } from './UnitNotIntegrated'
import { ExternalChatPage } from './ExternalChat'
import { SwitchboardLayout } from '../_layouts/Switchboard'
import { LoadingScreen } from '../../new-lib/LoadingScreen/LoadingScreen'
import { getDisplayName } from 'src/store/selectors'
import { SeekCarePage } from '../SeekCare'

class Switchboard extends Component {
  constructor (props) {
    super(props)
    this.state = {
      load: true,
      buttonLoading: false
    }
  }

  componentDidUpdate () {
    if (this.props.location.pathname === paths["switchboard"]) {
      this.setState({ load: true })
      this.componentDidMount()
    }
  }

  getURLParams = () => {
    return queryString.parse(this.props.location.search)
  }

  checkSaveMode = () => {
    if (this.props.location.state && this.props.location.state.saveMode) {
      this.props.history.push(paths["switchboard > select region"], { saveMode: true })
      this.setState({ load: false })
      return true
    }
    return false
  }

  checkCapioGoEdgeCase = () => {
    const extractDefaultHCU = this.props.centers.find(
      (hcu) => hcu.type === 'digitalvc'
    )
    const urlQueryParams = queryString.parse(this.props.location.search)
    if (urlQueryParams.hid === extractDefaultHCU.idExt) {
      this.props.history.push({
        pathname: paths["switchboard > pre-selected unit"],
        search: this.props.location.search
      })
      this.setState({ load: false })
      return true
    }
    return false
  }

  checkDirectEntry = () => {
    const urlQueryParams = queryString.parse(this.props.location.search)
    const extractSelectedHCU = this.props.centers.find(
      (hcu) => hcu.idExt === urlQueryParams.hid
    )
    const isDirectEntryApplicable =
      extractSelectedHCU &&
      extractSelectedHCU.idExt &&
      extractSelectedHCU.isDigitalProvider &&
      extractSelectedHCU.isDigitalProviderActive

    const isDirectEntryGranted =
      isDirectEntryApplicable && extractSelectedHCU.type === 'specialist'

    const isDirectEntryForced = isDirectEntryApplicable && urlQueryParams.de

    if (isDirectEntryGranted || isDirectEntryForced) {
      const url = new URL(config.flow)
      url.searchParams.append(
        'hcuss',
        extractSelectedHCU.digitalProviderRelativeUri
      )
      window.location = url
      return true
    }
    return false
  }

  checkUnitHasExternalChat = () => {
    const urlQueryParams = queryString.parse(this.props.location.search)
    const extractSelectedHCU = this.props.centers.find(
      (hcu) => hcu.idExt === urlQueryParams.hid
    )

    if (
      extractSelectedHCU &&
      !extractSelectedHCU.isDigitalProvider &&
      extractSelectedHCU.digitalProviderExternalUri
    ) {
      this.props.history.push({
        pathname: paths["switchboard > external chat"],
        search: this.props.location.search,
        state: { hcu: extractSelectedHCU }
      })
      this.setState({ load: false })
      return true
    }
    return false
  }

  checkSpecialistUnitIsNotIntegrated = () => {
    const urlQueryParams = queryString.parse(this.props.location.search)
    const extractSelectedHCU = this.props.centers.find(
      (hcu) => hcu.idExt === urlQueryParams.hid
    )

    if (
      extractSelectedHCU &&
      extractSelectedHCU.type === 'specialist' &&
      extractSelectedHCU.isDigitalProvider &&
      !extractSelectedHCU.isDigitalProviderActive
    ) {
      this.props.history.push({
        pathname: paths["switchboard > non-integrated unit (obsolete?)"],
        search: this.props.location.search,
        state: { hcu: extractSelectedHCU }
      })
      this.setState({ load: false })
      return true
    }
    return false
  }

  checkSpecialistUnitHasNoChat = () => {
    const urlQueryParams = queryString.parse(this.props.location.search)
    const extractSelectedHCU = this.props.centers.find(
      (hcu) => hcu.idExt === urlQueryParams.hid
    )

    if (
      extractSelectedHCU &&
      extractSelectedHCU.type === 'specialist' &&
      !extractSelectedHCU.isDigitalProvider &&
      !extractSelectedHCU.isDigitalProviderActive
    ) {
      this.props.history.push({
        pathname: paths["switchboard > unit without chat"],
        search: this.props.location.search,
        state: { hcu: extractSelectedHCU }
      })
      this.setState({ load: false })
      return true
    }
    return false
  }

  componentDidMount = () => {
    /* Check saveMode */
    if (this.checkSaveMode()) {
      return
    }

    /* Check Direct Entry case */
    if (this.checkDirectEntry()) {
      return
    }

    /* Check Capio Go edge case */
    if (this.checkCapioGoEdgeCase()) {
      return
    }

    if (this.checkSpecialistUnitIsNotIntegrated()) {
      return
    }

    if (this.checkUnitHasExternalChat()) {
      return
    }

    if (this.checkSpecialistUnitHasNoChat()) {
      return
    }

    /* Check other HCU specific HID flows */
    const urlQueryParams = queryString.parse(this.props.location.search)
    const extractSelectedHCU = this.props.centers.find(
      (hcu) => hcu.idExt === urlQueryParams.hid
    )

    if (
      this.props.myInformation.shouldSyncHealthCareManual &&
      extractSelectedHCU
    ) {
      // Send to Default with the target HCU
      this.props.history.push({
        pathname: paths["switchboard > specified unit"],
        search: this.props.location.search
      })
      this.setState({ load: false })
      return
    } else {
      if (
        extractSelectedHCU &&
        extractSelectedHCU.idExt !==
          this.props.myInformation.primaryHealthCareEntityId
      ) {
        // Confirm with the new target HCU
        this.props.history.push({
          pathname: paths["switchboard > specified unit"],
          search: this.props.location.search
        })
        this.setState({ load: false })
        return
      }
    }

    if (this.props.myInformation.shouldSyncHealthCareManual) {
      this.props.history.push({
        pathname: paths["switchboard > select unit (1)"],
        search: this.props.location.search
      })
      this.setState({ load: false })
    } else {
      const persistedHcu = this.props.centers.find(
        (hcu) =>
          hcu.idExt === this.props.myInformation.primaryHealthCareEntityId
      )
      if (
        persistedHcu &&
        persistedHcu.isDigitalProvider &&
        !persistedHcu.isDigitalProviderActive
      ) {
        this.props.history.push({
          pathname: paths["switchboard > non-integrated unit"],
          search: this.props.location.search
        })
        this.setState({ load: false })
        return
      }
      if (
        persistedHcu &&
        !persistedHcu.isDigitalProvider &&
        persistedHcu.digitalProviderExternalUri
      ) {
        this.props.history.push({
          pathname: paths["switchboard > external chat"],
          search: this.props.location.search
        })
        this.setState({ load: false })
        return
      }
      if (
        persistedHcu &&
        !persistedHcu.isDigitalProvider &&
        !persistedHcu.isDigitalProviderActive
      ) {
        this.props.history.push({
          pathname: paths["switchboard > unit without chat"],
          search: this.props.location.search
        })
        this.setState({ load: false })
        return
      }
      if (persistedHcu) {
        const url = new URL(config.flow)
        url.searchParams.append(
          'hcuss',
          persistedHcu.digitalProviderRelativeUri
        )
        window.location = url
        return
      }

      this.props.history.push({
        pathname: paths["switchboard > select unit (1)"],
        search: this.props.location.search
      })
      this.setState({ load: false })
    }
  }

  getDefaultHCU = () => {
    if (this.props.location.state && this.props.location.state.idExt) {
      return this.props.centers.find(
        (hcu) => hcu.idExt === this.props.location.state.idExt
      )
    }

    if (this.getURLParams().hid) {
      return this.props.centers.find(
        (hcu) => hcu.idExt === this.getURLParams().hid
      )
    }

    return this.props.centers.find(
      (hcu) => hcu.idExt === this.props.myInformation.primaryHealthCareEntityId
    )
  }

  getRemappedHCU = () => ({
    ...this.getDefaultHCU(),
    name: this.getDefaultHCU().healthcareunit,
    phone: this.getDefaultHCU().healthcareunitPhoneNumber
  })

  render () {
    return (
      <SwitchboardLayout
        displayName={this.props.displayName}
        variant={this.props.loadedApp ? 'default' : 'none'}
      >
        <div>
          {this.state.load === true
            ? (
              <div className={styles.loader}>
                <div>
                  <LoadingScreen userIsLoggedIn />
                </div>
              </div>
              )
            : (
              <div>
                <Switch>
                  <Route
                    exact
                    path={paths["switchboard > select unit (1)"]}
                    render={(routeComponentProps) => (
                      <SeekCarePage
                        {...routeComponentProps}
                        onClickRegionChoice={() => {
                          this.props.history.push(paths["switchboard > select region"])
                        }}
                      />
                    )}
                  />
                  <Route
                    exact
                    path={paths["switchboard > pre-selected unit"]}
                    render={(routeComponentProps) => (
                      <DefaultUnitChoicePage
                        {...routeComponentProps}
                        defaultHCU={this.props.centers.find(
                          (hcu) => hcu.type === 'digitalvc'
                        )}
                        otherButtonText='Välj mottagning'
                      />
                    )}
                  />
                  <Route
                    exact
                    path={paths["switchboard > non-integrated unit (obsolete?)"]}
                    render={(routeComponentProps) => (
                      <UnitNotIntegratedPage
                        {...routeComponentProps}
                        defaultHCU={() => {
                          if (
                            this.props.location.state &&
                          this.props.location.state.idExt
                          ) {
                            return this.props.centers.find(
                              (hcu) =>
                                hcu.idExt === this.props.location.state.idExt
                            )
                          }

                          if (this.getURLParams().hid) {
                            return this.props.centers.find(
                              (hcu) => hcu.idExt === this.getURLParams().hid
                            )
                          }

                          if (
                            this.props.myInformation.primaryHealthCareEntityId
                          ) {
                            return this.props.centers.find(
                              (hcu) =>
                                hcu.idExt ===
                              this.props.myInformation.primaryHealthCareEntityId
                            )
                          }
                        }}
                      />
                    )}
                  />
                  <Route
                    exact
                    path={paths["switchboard > unit without chat"]}
                    render={(routeComponentProps) => (
                      <ContactCareUnitView
                        {...routeComponentProps}
                        careUnit={this.getRemappedHCU()}
                        onCapioGoClick={(e) => {
                          this.setState({ buttonLoading: true })
                          window.location = config.flow
                        }}
                        capioGoIsLoading={this.state.buttonLoading}
                      />
                    )}
                  />
                  <Route
                    exact
                    path={paths["switchboard > external chat"]}
                    render={(routeComponentProps) => (
                      <ExternalChatPage
                        {...routeComponentProps}
                        defaultHCU={() => {
                          if (
                            this.props.location.state &&
                          this.props.location.state.idExt
                          ) {
                            return this.props.centers.find(
                              (hcu) =>
                                hcu.idExt === this.props.location.state.idExt
                            )
                          }

                          if (this.getURLParams().hid) {
                            return this.props.centers.find(
                              (hcu) => hcu.idExt === this.getURLParams().hid
                            )
                          }

                          return this.props.centers.find(
                            (hcu) =>
                              hcu.idExt ===
                            this.props.myInformation.primaryHealthCareEntityId
                          )
                        }}
                      />
                    )}
                  />
                  <Route
                    exact
                    path={paths["switchboard > specified unit"]}
                    render={(routeComponentProps) => (
                      <DefaultUnitChoicePage
                        {...routeComponentProps}
                        forceSave={() => {
                          const unit = this.props.centers.find(
                            (hcu) => hcu.idExt === this.getURLParams().hid
                          )
                          return unit.type === 'vardcentral'
                        }}
                        defaultHCU={this.props.centers.find(
                          (hcu) => hcu.idExt === this.getURLParams().hid
                        )}
                        otherButtonText='Välj annan mottagning'
                      />
                    )}
                  />
                  <Route
                    exact
                    path={paths["switchboard > select region"]}
                    render={(routeComponentProps) => (
                      <RegionSelectPage
                        {...routeComponentProps}
                        regions={this.props.regions.regions.map((region) => ({
                          label: region,
                          value: region
                        }))}
                        onSwitchBoardRegionSelect={(region) => {
                          this.props.dispatch(
                            consentActions.selectSwitchBoardRegion(region)
                          )
                        }}
                        selectedSwitchBoardRegion={
                        this.props.switchBoard.selectedRegion
                      }
                      />
                    )}
                  />
                  <Route
                    exact
                    path={paths["switchboard > select unit (2)"]}
                    render={(routeComponentProps) => (
                      <HealthCareUnitSelectPage
                        {...routeComponentProps}
                        regions={this.props.regions.regions.map((region) => ({
                          label: region,
                          value: region
                        }))}
                        onSwitchBoardRegionSelect={(region) => {
                          this.props.dispatch(
                            consentActions.selectSwitchBoardRegion(region)
                          )
                        }}
                        selectedSwitchBoardRegion={
                        this.props.switchBoard.selectedRegion
                      }
                        persistedCenter={this.props.centers.find(
                          (hcu) =>
                            hcu.idExt ===
                          this.props.myInformation.primaryHealthCareEntityId
                        )}
                        centers={this.props.centers.map((center) => ({
                          idExt: center.idExt,
                          type: center.type,
                          name: center.healthcareunit,
                          region: center.healthcareunitRegions,
                          healthcareunitPhoneNumber:
                          center.healthcareunitPhoneNumber,
                          digitalProviderRelativeUri:
                          center.digitalProviderRelativeUri,
                          isDigitalProvider: center.isDigitalProvider,
                          isDigitalProviderActive: center.isDigitalProviderActive,
                          digitalProviderExternalUri:
                          center.digitalProviderExternalUri
                        }))}
                      />
                    )}
                  />
                </Switch>
              </div>
              )}
        </div>
      </SwitchboardLayout>
    )
  }
}

export const SwitchboardPage = withRouter(
  connect((state) => {
    return {
      myInformation: state.consent.myInformation,
      switchBoard: state.consent.switchBoard,
      centers: state.healthCenter.digitalCenters,
      regions: state.location,
      displayName: getDisplayName(state)
    }
  })(withRouter(Switchboard))
)
