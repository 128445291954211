import useMediaQuery from "@material-ui/core/useMediaQuery";
import scss from "../_styles/variables.module.scss";


export const useDeviceSize = () => {
    const isMobileSmall = useMediaQuery(`(max-width: ${scss.breakpointMobileSm})`);
    const isBiggerThanMobile = useMediaQuery(`(min-width: ${scss.breakpointTablet})`);
    const isBiggerThanTablet = useMediaQuery(`(min-width: ${scss.breakpointLaptop})`)
    return {
        isMobileSmall,
        isBiggerThanMobile,
        isMobile: !isBiggerThanMobile,
        isBiggerThanTablet,
    }
}