import { bool, element } from 'prop-types'
import React from 'react'
import { InfoDialog } from '../../lib'

export const ListingProtectedIdDialog = ({ closeButton, isOpen }) => {
  return (
    <InfoDialog
      heading='Listning'
      body='Vi kan inte lista dig, vänligen kontakta din vårdcentral!'
      actions={closeButton}
      open={isOpen}
    />
  )
}

ListingProtectedIdDialog.propTypes = {
  closeButton: element.isRequired,
  isOpen: bool
}
