import React, { useState, useEffect } from "react";
import connect from "react-redux/es/connect/connect";
import { withRouter } from "react-router-dom";
import { actions as appActions } from "../../store/reducers/app";
import { actions as termsOfServicesActions } from "../../store/reducers/terms-of-services"
import { ProfileTermsAndDataScreen } from "."
import { paths } from "../../utils/urlPaths";

const Page = ({ termsOfService, dispatchCancelAccount, dispatchGetTos }) => {

    const [dialogIsOpen, setDialogIsOpen] = useState(false);

    useEffect(() => dispatchGetTos(), [])

    return (
        <ProfileTermsAndDataScreen 
            cancelDialogIsOpen={dialogIsOpen}
            onCancelButtonClick={() => setDialogIsOpen(true)} 
            onDialogCancelClick={() => setDialogIsOpen(false)} 
            onDialogConfirmClick={dispatchCancelAccount} 
            termsOfService={termsOfService} />
    )
}

const mapStateToProps = (state) => ({
    termsOfService: state.termsOfServices.latestTermsOfService
})


const mapDispatchToProps = (dispatch, { history }) => ({
    dispatchCancelAccount: () => dispatch(appActions.deleteUserInfo())
    .then(() => {
        setTimeout(() => {
            history.replace(paths["logout"]);
          }, 1000);
    }),
    dispatchGetTos: () => dispatch(termsOfServicesActions.fetchLatestTermsOfService())
});

export const ProfileTermsAndDataPage = withRouter(connect(mapStateToProps, mapDispatchToProps)(Page))

