import React from 'react'
import styles from './SearchCareCta.module.scss'
import classNames from 'classnames'
import { Typography } from '../Typography'
import { Image } from '../_types'

type Props = {
  title?: string
  text?: string
  image?: Image
  alt?: string
  children?: React.ReactNode
}

export const SearchCareCta = ({ title, text, image, alt, children }: Props) => {
  const ctaContainerClassNames = classNames({
    [styles['cta-container']]: true,
    [styles['cta-container-with-image']]: image
  })
  const contentContainerClassNames = classNames({
    [styles['content-container']]: true,
    [styles['content-container-with-image']]: image
  })

  return (
    <section className={ctaContainerClassNames}>
      {image && <img className={styles.image} src={image} alt={alt} />}
      <div className={contentContainerClassNames}>
        <div className={styles.content}>
          <Typography tag='h2' align='center' margin={{ b: 16 }}>{title}</Typography>
          <Typography tag='p' align='center' fontSize='sm' margin={{}}>{text}</Typography>
          <div className={styles['buttons-container']}>
            {children}
          </div>
        </div>
      </div>
    </section>
  )
}
