import React from "react";
import { Text } from "../Text";
import Markdown from 'markdown-to-jsx';
import styles from "./TermsOfService.module.scss";
import textStyles from "../Text/Text.module.scss";
import Loader from "react-loader-spinner";
import scss from "../../lib/_styles/variables.module.scss";

export const TermsOfService = ({ termsOfService }) => {
    const overrides = {
        h2: { component: Text, props: { tag: "h2", variant: "h3" } },
        h3: { component: Text, props: { tag: "p", weight: 900 } },
        p: { component: Text, props: { tag: "p"} },
        ul: { props: { className: styles.ul + " " + textStyles.p } },
        li: { props: { className: styles.li } }
    }

    const isLoading = termsOfService === "loading" || termsOfService === "uninitialized";
    const isError = termsOfService === "error" || termsOfService === "none"

    if (isLoading) {
        return (
            <div className={styles.loader} >
                <Loader
                    type="Oval"
                    color={scss.primary}
                    width={80}
                    height={80} />
            </div>
        )
    }

    const content = termsOfService ? termsOfService.content : undefined;

    if (isError || !content) {
        return (
            <Text tag="p">{copy.failedToLoad}</Text>
        )

    }

    return (
        <article className={styles["terms-of-conditions"]}>
            <Markdown options={{overrides}}>{termsOfService.content || ""}</Markdown>
        </article>
    )
}

const copy ={
    failedToLoad: "Vi kan inte ladda innehållet för tillfället. Vänligen försök igen senare"
}