import React, { useEffect, useState } from 'react'
import { paths } from '../../utils/urlPaths'
import { ListingReceiptView } from './ListingReceiptView'

export const ListingReceiptPage = ({ userIsRegistered }) => {
  const [unit, setUnit] = useState([])
  useEffect(() => {
    const items = localStorage.getItem('selected_center_name')
    setUnit(items)
  }, [unit])

  return <ListingReceiptView careUnit={unit} userIsRegistered={userIsRegistered} />
}
