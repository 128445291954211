import React from 'react';
import PropTypes from 'prop-types';
import { Typography } from 'src/new-lib/Typography';
import { Button } from 'src/new-lib/Button';
import styles from './PreselectedCareUnitRegion.module.scss';
import { LinkRow } from 'src/new-lib/LinkRow';
import { connect } from 'react-redux';
import { useDeviceSize } from '../../new-lib/_utils';
import { actions } from '../../store/reducers/consents';
import { withRouter } from 'react-router-dom';


/**
 * @description Select Care-unit region view
 */

type Props = {
  regions: string[]
}

export const SelectCareUnitRegion: React.FC<Props> = ({ regions }) => {
  const { isMobile } = useDeviceSize()
  return (
    <div className={styles.container}>
      <div className={styles['content-container']}>
        <Typography tag="h2">Välj region</Typography>
        <section>
          {regions.map((region) => (
            <LinkRow
              to={{name: "chat > onboarding > step 2 (region)", params: [region] }}
              rightIconName='chevron-right'
              rightIconSize='md'
              key={region}
              title={region}
            />
          ))}
          <Button
            expand
            iconRight={isMobile ? 'close' : null}
            childMargin={{ r: 8 }}
            margin={{ t: '32px', b: '40px' }}
            to="home"
            variant={isMobile ? 'secondary-light' : 'text'}
          >
            avbryt
          </Button>
        </section>
      </div>
    </div>
  )
}

const mapStateToProps = (state: any) => ({
  regions: state.location.regions
})

const mapDispatchToProps = (dispatch: any) => ({
  dispatchSelectCareCenter: (center: any) =>
    dispatch(
      actions.selectSwitchBoardRegion({
        region: center.healthcareunitRegions
      })
    )
})

export const SelectCareUnitRegionView = withRouter(
  connect(mapStateToProps, mapDispatchToProps)(SelectCareUnitRegion)
)

SelectCareUnitRegionView.propTypes = {
  regions: PropTypes.array.isRequired
}
