import { useState } from 'react'
import styles from './Faq.module.scss'
import Markdown from 'markdown-to-jsx'
import { options } from 'src/new-lib/_styles'
import { Typography } from 'src/new-lib/Typography'
import { useDeviceSize } from 'src/new-lib/_utils'
import { Accordion } from 'src/new-lib/Accordion'
import { HeroHeader } from 'src/new-lib/Hero'
import type { FAQContent } from "src/api/services/ContentAPI"
import { ContentState } from 'src/store/reducers/content/contentState'


type Props = { content: ContentState["faq"] }

export const FaqView = (props: Props) => {
  const faq = props.content.data
  const [selectedAccordion, setSelectedAccordion] = useState<string | null>(null)
  const [leftSectionsColumn, rightSectionsColumn] = splitArray(faq.sections)
  
  const handleAccordionClick = (id: string) => setSelectedAccordion(selectedAccordion === id ? null : id)

  return (
    <main className={styles.body}>
      <HeroHeader
        alignment="center"
        title={faq.headerTitle}
        text={faq.headerSubTitle}
        color="middle-salmon"
      />
      <section className={styles["container"]}>
        <SectionsColumn
          sections={leftSectionsColumn}
          selectedAccordion={selectedAccordion}
          handleAccordionClick={handleAccordionClick}
        />
        <SectionsColumn
          sections={rightSectionsColumn}
          selectedAccordion={selectedAccordion}
          handleAccordionClick={handleAccordionClick}
        />
      </section>
    </main>
  )
}

type SectionsColumnProps = {
  sections: FAQContent["sections"]
  selectedAccordion: string | null
  handleAccordionClick: (id: string) => void
}

const SectionsColumn = ({ sections, selectedAccordion, handleAccordionClick }: SectionsColumnProps) => {
  const { isMobile } = useDeviceSize()

  return (
    <div className={styles["sections-container"]}>
      {sections.map(section => (
        <div className={styles["section"]}>
          <Typography tag='h3' margin={isMobile ? {} : { b: '24px' }}>{section.title}</Typography>
          <ul className={styles["section-list"]}>
          {section.qna.map(qna => (
            <li>
              <Accordion
                slim
                selectedAccordion={selectedAccordion}
                id={`${qna.id}-${qna.question}`}
                headerContent={<Typography tag="h5" fontSize="md" margin={{}}>{qna.question}</Typography>}
                onClick={handleAccordionClick}>
                <Markdown options={options}>
                  {qna.answer || ''}
                </Markdown>
              </Accordion>
            </li>
          ))}
          </ul>
        </div>
      ))}
    </div>
  )
}


const splitArray: <T>(arr: T[]) => [T[], T[]] = (arr) => {
  const midIndex = Math.ceil(arr.length / 2)

  const firstHalf = arr.slice(0, midIndex)
  const secondHalf = arr.slice(midIndex)

  return [firstHalf, secondHalf]
}

