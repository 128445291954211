import React, { useState, useRef, useEffect } from 'react'
import styles from './Accordion.module.scss'
import classNames from 'classnames'
import { Icon, IconSizes, IconNames } from 'src/new-lib/Icon'

/**
 * @description Accordion Component
 */

interface Props {
  id: string
  selectedAccordion?: string | null
  onClick: (id: string) => void
  headerContent?: React.ReactNode
  children?: React.ReactNode
  iconName?: IconNames
  iconSize?: IconSizes
  slim?: boolean
  expand?: boolean
}

export const Accordion = ({
  id,
  selectedAccordion,
  onClick,
  headerContent,
  children,
  iconName,
  iconSize = 'lg',
  slim,
  expand
}: Props) => {
  const [bodyContentHeight, setBodyContentHeight] = useState('0px')

  const bodyContentRef = useRef(null)

  const toggleAccordion = () => onClick(id)

  useEffect(() => {
    setBodyContentHeight(
      selectedAccordion === id
        ? `${bodyContentRef.current.scrollHeight}px`
        : '0px'
    )
  }, [selectedAccordion])

  const accordionClasses = classNames({
    [styles.accordion]: true,
    [styles['accordion-open']]: selectedAccordion === id,
    [styles['accordion-slim']]: slim,
    [styles['expand']]: expand,
    [styles['accordion-with-icon']]: iconName
  })

  return (
    <section id={id} className={accordionClasses}>
      <div className={styles['accordion-header']} onClick={toggleAccordion}>
        {iconName && (
          <div className={styles['left-icon']}>
            <Icon name={iconName} size={iconSize} />
          </div>
        )}
        <div className={styles['header-content']}>{headerContent}</div>
        <div className={styles['chevron-icon']}>
          <Icon name="chevron-down" size="md" />
        </div>
      </div>

      <div
        ref={bodyContentRef}
        className={styles['accordion-body']}
        style={{ maxHeight: bodyContentHeight }}
      >
        {children}
        <div className={styles['button-margin-bottom-filler-div']} />
      </div>
    </section>
  )
}
