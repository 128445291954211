import { useEffect } from "react";
import { actions } from "../../store/reducers/app";
import config from "../../api/config";
import { LoadingScreen } from "../../new-lib/LoadingScreen/LoadingScreen";
import { paths } from "../../utils/urlPaths";
import { FixMeLater } from "src/new-lib/_types";
import { connect } from "react-redux";
import { useParams } from 'react-router-dom';
 type Props = {
    dispatch: FixMeLater;
    history: FixMeLater;
  };
  
export const VerifyCode =({dispatch,history} :Props)=>{
  const { code } = useParams<{code: string}>();
  
  useEffect(()=>{
    
    console.log("Path Param: ", code)
    dispatch(actions.saveCode(code));
    dispatch(actions.saveRedirectURI(`${config.redirectBase}/vericode`));
    dispatch(actions.verifyPhoneCode(code))
    .then(() => {
        history.push({
        pathname: paths["home"],
        });
    });

  },[]);

  return <LoadingScreen />;
}

//export default VerifyCode;
export const VerifyCodePage = connect((state: FixMeLater) => {
  return {
    app: state.app,
  };
})(VerifyCode);
