import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { actions } from '../../store/reducers/consents'
import { paths } from '../../utils/urlPaths'
import { ListingConfirmPage } from './ListingConfirmPage'

const _FastListingConfirmPage = ({
  dispatchGetSparInfo,
  addressIsAvailable
}) => {
  useEffect(() => {
    if (!addressIsAvailable) {
      dispatchGetSparInfo()
    }
  }, [addressIsAvailable, dispatchGetSparInfo])

  return (
    <ListingConfirmPage selectCareCenterPath={paths["fast listing > select"]} />
  )
}

const mapStateToProps = (state) => ({
  userIsRegistered: state.app.userIsRegistered,
  sparIsLoading: state.consent.sparLoading,
  addressIsAvailable: state.consent.myInformation.streetAddress && true,
  centers: state.healthCenter.digitalCenters
})

const mapDispatchToProps = (dispatch) => ({
  dispatchGetSparInfo: () => dispatch(actions.fetchSparInfo())
})

export const FastListingConfirmPage = withRouter(
  connect(mapStateToProps, mapDispatchToProps)(_FastListingConfirmPage)
)
