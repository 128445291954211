type IdentityInformation = {
  iss: string
  nbf: number
  iat: number
  exp: number
  aud: string
  scope: string[],
  amr: string[],
  client_id: string
  sub: string
  auth_time: number
  idp: string
  name: string
  personalNumber: string
  givenName: string
  surname: string
  ipAddress: string
  identifier: string
  firstName: string
  lastName: string
  cid: string
  sid: string
  jti: string
}

export type InitialState = {
  applicationGlobalLoading: boolean
  userIsRegistered: boolean
  userLoggedIn: boolean
  sessionToken: string
  idToken: string
  identityInformation: IdentityInformation | {}
  sideDrawerToggled: boolean
  code: string
  signCode: string
  redirectURI: string
  listingPdfURI: string
  errorOccured: boolean
}


export const initialState: InitialState = {
  applicationGlobalLoading: false,
  userIsRegistered: false,
  userLoggedIn: false,
  sessionToken: '',
  idToken: '',
  identityInformation: {},
  sideDrawerToggled: false,
  code: '',
  signCode: '',
  redirectURI: '',
  listingPdfURI: '',
  errorOccured: false
};
