import React, { Component } from 'react'
import { connect } from 'react-redux'
import queryString from 'query-string'
import { actions } from '../../store/reducers/app'
import { actions as accountActions } from '../../store/reducers/accounts'
import { paths, createPath } from '../../utils/urlPaths'
import config from '../../api/config'
import { encapsulate } from '../../utils/stringUtils'
import { LoadingScreen } from '../../new-lib/LoadingScreen/LoadingScreen'
import { FixMeLater } from 'src/new-lib/_types'
import store from "../../store/configureStore"

type Props = {
  dispatch: FixMeLater
  history: FixMeLater
}

class Callback extends Component<Props> {
  componentDidMount () {
    const urlQueryParams = queryString.parse(this.props.history.location.search)
    const state = JSON.parse(atob(urlQueryParams.state))
    this.props.dispatch(actions.saveCode(urlQueryParams.code))
    this.props.dispatch(
      actions.saveRedirectURI(`${config.redirectBase}/callback`)
    )
    this.props
      .dispatch(actions.createSessionToken())
      .then(() => {
        this.props
          .dispatch(store.getState().featureManager.useService2 ? accountActions.fetUserInfo() : actions.fetUserInfo())
          .then((isRegistered: boolean) => {
            if (isRegistered) {
              this.props.dispatch(actions.userIsRegistered())
            }
            if (state.additional_parameters.fast_listing) {
              this.props.history.push({
                pathname: createPath({ name: "fast listing > sign (hsaId)", params: [state.additional_parameters.hsa_id]}),
                search: `?fastListing=${state.additional_parameters.fast_listing}&hsaid=${state.additional_parameters.hsa_id}`
              })
              return
            }
            if (state.additional_parameters.next_route) {
              this.props.history.push({
                pathname: paths["home"],
                search: `?service=${
                  state.additional_parameters.next_route
                }&hid=${encapsulate(
                  state.additional_parameters.hcu_id
                )}&de=${encapsulate(state.additional_parameters.direct_entry)}`
              })
            } else {
              this.props.history.push({
                pathname: paths["home"]
              })
            }
          })
          .catch(() => {
            // ToDo: Kolla 404, 4xx eller 5xx
            this.props.history.push({
              pathname: paths["registration > ToS"],
              search: `?service=${
                state.additional_parameters.next_route
              }&hid=${encapsulate(
                state.additional_parameters.hcu_id
              )}&de=${encapsulate(state.additional_parameters.direct_entry)}`
            })
          })
      })
      .catch(() => {
        this.props.history.push(paths["login"])
      })
  }

  render () {
    return <LoadingScreen />
  }
}

export const CallbackPage = connect((state: FixMeLater) => {
  return {
    app: state.app
  }
})(Callback)
