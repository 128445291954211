import React, { useMemo, useState } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { ListingSelectView } from './ListingSelectView'
import { getCareCentersSelector } from '../../store/selectors'
import { createPath, paths } from '../../utils/urlPaths'
import { actions as consentActions } from '../../store/reducers/consents'

export const _ListingSelectPage = ({ centers, history }) => {
  const selectedRegion = 'Stockholm'
  const regions = [selectedRegion]
  const careCenters = useMemo(
    () =>
      centers
        .filter((c) => c.healthcareunitRegions === selectedRegion)
        .map((c) => c.healthcareunit),
    [centers.length]
  )
  const [selectedCareCenter, setSelectedCareCenter] = useState(null)
  const [selectionError, setSelectionError] = useState('')

  const onNextButtonClick = () => {
    if (!selectedCareCenter) {
      setSelectionError('Du glömde välja vårdcentral.')
    } else {
      history.replace(createPath({ name: "fast listing > sign (hsaId)", params: [centers.find((center) => center.healthcareunit === selectedCareCenter)
        .HsaId]})
      )
    }
  }

  return (
    <ListingSelectView
      regions={regions}
      selectedRegion={selectedRegion}
      onRegionChange={() => {}}
      careCenters={careCenters}
      selectedCareCenter={selectedCareCenter}
      onCareCenterChange={(e) => setSelectedCareCenter(e.target.value)}
      formError={selectionError}
      onNextButtonClick={onNextButtonClick}
      infoDialogIsOpen={false}
      onInfoDialogClose={() => {}}
      careCenterSelectDisabled={false}
      regionSelectDisabled
    />
  )
}

const mapStateToProps = (state) => ({
  centers: getCareCentersSelector(state)
})

const mapDispatchToProps = (dispatch) => ({
  dispatchSelectCareCenter: (center) =>
    dispatch(
      consentActions.selectCenter({
        label: center.healthcareunit,
        value: center.healthcareunit,
        idExt: center.idExt,
        region: center.healthcareunitRegions
      })
    )
})

export const FastListingSelectPage = withRouter(
  connect(mapStateToProps, mapDispatchToProps)(_ListingSelectPage)
)
