import React from "react";
import collection from "../../assets/sl-icons/collection.svg";
import scss from "../../lib/_styles/variables.module.scss";
import PropTypes from 'prop-types'

/**
 * @typedef {"filled-" | ""} Variant
 * @typedef {{
 *  name:
 * "arrow-right"
 * | `${Variant}arrow-right-thick`
 * | "arrow-left"
 * | "check-circle"
 * | "close"
 * | "cog"
 * | "expand"
 * | "hospital-board"
 * | "house"
 * | "info-circle"
 * | "heart"
 * | "nav-menu"
 * | "pill"
 * | "question-circle"
 * | "remove"
 * | "search"
 * | "profile"
 * | "profile-circle"
 * | "pin"
 * | "medical-note"
 * | "inbox"
 *  color: "dark" | "light" | "gray" | "alert" | "tertiary" | "primary" | "success",
 * size: "xxs" | "xs" | "sm" | "md" | "lg" | "xl" | "xxl"
 * }} Props
 */

const sizes = {
    xxs: "12px",
    xs: "16px",
    sm: "18px",
    md: "20px",
    lg: "24px",
    xl: "36px",
    xxl: "48px"
}

const colors = {
  light: "#fff",
  gray: "#9e9e9e",
  dark: "#000",
  alert: scss.alert,
  tertiary: scss.tertiary,
  primary: scss.primary,
  success: scss.success,
}

/**
 * @description Api for rendering various svg-icons
 * 
 * To add additional icons add an svg as symbol to src/assets/sl-icons/collection, 
 * assign it an id that can be referred to in the jsdoc typdef to support intellisense
 * @param {Props} props
 * @return {JSXNode}
 */
export const Icon = ({ name, size = "md", color = "dark" }) => {

  return (
    <svg height={sizes[size]} width={sizes[size]} fill={colors[color]}>
      <use xlinkHref={`${collection}#${name}`}></use>
    </svg>
  );
};

Icon.propTypes = {
  size: PropTypes.oneOf(["xxs", "xs", "sm", "md", "lg", "xl", "xxl"])
}