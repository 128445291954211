import {
  UNSUBSCRIBE_NEWSLETTER,
  UNSUBSCRIBE_NEWSLETTER_FAILED,
  UNSUBSCRIBE_NEWSLETTER_SUCCEEDED
} from "./action-types"

export function unsubscribeNewsletter( type, unsubscribeToken, messageId ) {
  return async (dispatch, getState, api) => {
    const { unsubscribeToken: unsubscribedToken } = getState().newsletter;

    if (unsubscribedToken === unsubscribeToken) {
      // Only need to unsubscribe once
      return;
    }

    dispatch({ type: UNSUBSCRIBE_NEWSLETTER, payload: unsubscribeToken });

    try {
      await api.services.newsletter.unsubscribe(type, unsubscribeToken, messageId);
      dispatch({ type: UNSUBSCRIBE_NEWSLETTER_SUCCEEDED });
    } catch (e) {
      dispatch({ type: UNSUBSCRIBE_NEWSLETTER_FAILED });
    }
  }
}