import { ConsentAPI } from "./services/ConsentAPI";
import CenterAPI from "./services/CenterAPI";
import { AppAPI } from "./services/AppAPI";
import { LocationAPI } from "./services/LocationAPI";
import { ListingAPI } from "./services/ListingAPI";
import { NewsletterAPI } from "./services/NewsletterAPI";
import { TermsOfServicesAPI } from "./services/TermsOfServiceAPI";
import { FeaturesApi } from "./services/FeaturesApi";
import { PhoneValidationAPI } from "./services/PhoneValidationAPI";
import { CmsApi } from "./services/ContentAPI";
import { AccountAPI } from "./services/AccountAPI";
import { UnsubscribeAPI } from "./services/UnsubscribeAPI";

export default class Api {
  constructor(http) {
    const httpClient = http.create();

    httpClient.interceptors.request.use((config) => {
      if (this.sessionToken) {
        config.headers["Authorization"] = `Bearer ${this.sessionToken}`;
      }
      return config;
    });

    httpClient.interceptors.response.use(
      (response) => {
        // Injection point on response object

        return response;
      },
      (error) => {
        // Response object contains other than 2xx
        console.log("HTTP interceptor on error code: ", error);

        if (this.onError) {
          this.onError(error);
        }
        //localStorage.removeItem('sessionToken');
        return Promise.reject(error);
      }
    );

    /**
     * Alternative client used for strapi web requests.
     *
     * It does not share authentication token with rest of system,
     * and permission errors should not trigger logout.
     */
    const strapiClient = http.create();

    this.services = {
      consent: new ConsentAPI(httpClient),
      app: new AppAPI(httpClient),
      center: new CenterAPI(strapiClient),
      location: new LocationAPI(strapiClient),
      listing: new ListingAPI(httpClient),
      newsletter: new NewsletterAPI(httpClient),
      termsOfServices: new TermsOfServicesAPI(httpClient),
      features: new FeaturesApi(httpClient),
      phoneValidation: new PhoneValidationAPI(httpClient),
      content: new CmsApi(strapiClient),
      account: new AccountAPI(httpClient),
      unsubscribe: new UnsubscribeAPI(httpClient),
    };
  }
}
