import React, { Component } from "react";

import { connect } from "react-redux";
import { paths } from "../../../utils/urlPaths";
import config from '../../../api/config';
import { UnitNotIntegratedScreen } from "./UnitNotIntegratedScreen";


class UnitNotIntegrated extends Component {
    constructor(props) {
        super(props);
        this.state = {
            buttonLoading: false
        };
    }

    render() {
        return (
            <UnitNotIntegratedScreen
                healthCareUnitName={this.props.defaultHCU().healthcareunit}
                externalHealthCareUnitURL={`${config.capioGoOrigin}${this.props.defaultHCU().digitalProviderRelativeUri}`}
                onClickSettings={(e) => {
                    e.preventDefault(); this.props.history.push(paths["chat > settings"])
                }}
                otherButtonLoading={this.state.buttonLoading}
                otherButtonOnClick={e => {
                    this.setState({ buttonLoading: true });
                    window.location = config.flow
                }}
                isSavable={() => {
                    return this.props.defaultHCU().type === 'vardcentral'
                }}

            />
        );
    }
}

export const UnitNotIntegratedPage = connect()(UnitNotIntegrated);
