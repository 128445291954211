import { formatPhoneNumber } from "react-phone-number-input";

export const tryExtractCountryCodeFromPhoneString = (str) => {
    const result = /^\+\d\d/.exec(str);
    return result && result[0];
};

export const isWebUrl = (str) => {
    const urlPattern = new RegExp("(^http[s]?:\/{2})|(^www)|(^\/{2})");
    return urlPattern.test(str);
}

export const splitStringByWebUrl = (str) => {
    return str.split(/(?:www|https?)[^\s]+/);
}

export const tryExtractWebUrlsFromString = (str) => {
    const result = str.matchAll(/(?:www|https?)[^\s]+/g);
    return Array.from(result, s => s[0]);
    
}

export const capitalizeString = (str) => {
    return str && str.length > 1 && str[0].toUpperCase() + str.substring(1).toLowerCase();
}

export const formatPhoneLegacy = (phoneNumber) => {
    const countryNumber = tryExtractCountryCodeFromPhoneString(phoneNumber);
    const phone = countryNumber
        ? phoneNumber
        : `+46${phoneNumber}`;

    const phoneFormatted = formatPhoneNumber(phone).replace(/-|\s/g, "");

    return {
        countryCode: countryNumber ?? "+46",
        subscriberNumber: phoneFormatted
    }
}
