import { ReactNode } from 'react';
import styles from './Typography.module.scss';
import { marginToCssProp } from '../_utils';
import { FixMeLater } from '../_types';

export type Tags =
  'h1'
  | 'h2'
  | 'h3'
  | 'h4'
  | 'h5'
  | 'h6'
  | 'p'
  | 'em'
  | 'label'
  | 'span';


export type Variants = 
  'h1'
  | 'h1Plus'
  | 'h2'
  | 'h3'
  | 'h4'
  | 'h5'
  | 'h6'
  | 'p'
  | 'psm'
  | 'pxs'
  | 'em'
  | 'label'
  | 'span'
  | 'inputError'
  | 'labelError';

export type TextColors = "primary" | "secondary"

export type TextAligns =
  'left'
  | 'right'
  | 'center'
  | 'start'
  | 'end'
  | 'justify'
  | 'inherit'
  | 'initial'
  | 'unset';

export type Transforms =
  'none'
  | 'capitalize'
  | 'lowercase'
  | 'uppercase'
  | 'inherit'
  | 'initial'
  | 'unset';

export type Weights = "400" | "600" | "700";

export type FontSizes = 'xs' | 'sm' | 'md';

export type LineHeights = "xs" | "sm" | "md" | "lg" | "xl";

export type Displays =
  'block'
  | 'inline'
  | 'inline-block'
  | 'flex'
  | 'inline-flex'
  | 'grid'
  | 'inline-grid'
  | 'table'
  | 'table-cell'
  | 'table-row'
  | 'none';

export type Spacings = "sm" | "md" | "lg" | "xl";
export type Margin = `${number}px`

interface TypographyProps {
  tag: Tags;
  variant?: Variants;
  color?: TextColors;
  align?: TextAligns;
  transform?: Transforms;
  margin?: FixMeLater | { t?: Margin; b?: Margin; l?: Margin; r?: Margin; h?: Margin; v?: Margin; };
  children: ReactNode;
  htmlFor?: string;
  weight?: Weights;
  fontSize?: FontSizes;
  lineHeight?: LineHeights;
  display?: Displays;
  spacing?: Spacings;
}

type TagProps = {
  style: {
    textAlign: TextAligns
    textTransform: Transforms
    margin: string
  }
  className: string
  children: ReactNode
}

const renderTag = {
  h1: (props: TagProps) => <h1 {...props}>{props.children}</h1>,
  h2: (props: TagProps) => <h2 {...props}>{props.children}</h2>,
  h3: (props: TagProps) => <h3 {...props}>{props.children}</h3>,
  h4: (props: TagProps) => <h4 {...props}>{props.children}</h4>,
  h5: (props: TagProps) => <h5 {...props}>{props.children}</h5>,
  p: (props: TagProps) => <p {...props}>{props.children}</p>,
  em: (props: TagProps) => <em {...props}>{props.children}</em>,
  label: (props: TagProps) => <label {...props}>{props.children}</label>,
  span: (props: TagProps) => <span {...props}>{props.children}</span>,
};

type ClassesProps = {
  [key: string]: string;
};

const classes: ClassesProps = {
  h1: styles.h1,
  h1Plus: styles['h1-plus'],
  h2: styles.h2,
  h3: styles.h3,
  h4: styles.h4,
  h5: styles.h5,
  p: styles.p,
  psm: styles['p-sm'],
  pxs: styles['p-xs'],
  em: styles.em,
  label: styles.label,
  span: styles.span,
  inputError: styles['input-error'],
  labelError: styles['label-error'],
};

const applyFontStyleClasses = (
  variant: Variants,
  color: TextColors,
  weight: Weights,
  fontSize: FontSizes,
  lineHeight: LineHeights,
  display: Displays,
  spacing: Spacings
) => {
  let classNames = `${classes[variant]}`

  if (color) classNames += ` ${styles[`color-${color}`]}`
  if (weight) classNames += ` ${styles[`weight-${weight}`]}`
  if (fontSize) classNames += ` ${styles[`font-size-${fontSize}`]}`
  if (lineHeight) classNames += ` ${styles[`line-height-${lineHeight}`]}`
  if (display) classNames += ` ${styles[`display-${display}`]}`
  if (spacing) classNames += ` ${styles[`letter-spacing-${spacing}`]}`

  return classNames
}

export const Typography = ({
  tag,
  variant = tag,
  color,
  align = 'left',
  transform = 'none',
  margin,
  children,
  htmlFor,
  weight,
  fontSize,
  lineHeight,
  display,
  spacing
}: TypographyProps) => {
  return renderTag[tag]({
    style: {
      textAlign: align,
      textTransform: transform,
      margin: marginToCssProp(margin),
    },
    className: applyFontStyleClasses(variant, color, weight, fontSize, lineHeight, display, spacing),
    children,
  })
}
