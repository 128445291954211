import React from "react";
import Zoom from "@material-ui/core/Zoom";
import { Text } from "../Text";
import styles from "./AlertBox.module.scss";
import { bool, string } from "prop-types";


export const AlertBox = ({ text, hidden }) => {
    return (

        <Zoom in={!hidden} exit style={{ marginBottom: hidden && "30px" || "0px" }}>
            <div className={hidden ? styles.hidden : styles.displayed}>
                <Text color="#ffffff" tag="p" align="center" variant="psm" style={{ marginBottom: "0px" }}>
                    {text}
                </Text>
            </div>
        </Zoom>
    )
}


AlertBox.propTypes = {
    text: string,
    hidden: bool,
}