import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { Modal } from 'src/new-lib/Modal'
import { Button } from 'src/new-lib/Button'
import { connect } from 'react-redux'
import { getCareCentersSelector } from '../../store/selectors'
import { CareUnitView } from './CareUnitView'

/**
 * @description Modal Selected CareUnit View
 */

type ModalPageProps = {
  header?: string;
  text?: string;
  savedData?: {
    careUnit: string;
    phoneNumber: string;
    address: string;
    postCode: string;
    city: string;
    to: string;
  };
  path?: string;
  buttonText?: string;
  icon?: string;
}
type ModalViewProps = {
  savedCenterId?: string;
  centers?: any[];
}

const ModalPage = ({ header, text, savedData, path, buttonText, icon }: ModalPageProps) => {
  const [modalIsOpen, setModalIsOpen] = useState(true)

  const regionIsNotAvailable = (region: string) => {
    const regions = [
      'Bro',
      'Bromma',
      'BandHagen',
      'Enskede',
      'Farsta',
      'Hässelby',
      'Johanneshov',
      'Järfälla',
      'Lidingö',
      'Nynäshamn',
      'Solna',
      'Stockholm',
      'Skogås',
      'Södertälje',
      'Skärholmen',
      'Upplands Väsby',
      'Ösmo'
    ]
    return regions.find((i) => i === region)
  }

  if (regionIsNotAvailable(savedData.city)) {
    header = 'Lista dig på din förvalda vårdcentral'
    text = `Du har nu uppdaterat din förvalda vårdcentral. Du kan också lista dig på ${savedData.careUnit}.`
    buttonText = 'lista dig hos capio'
  } else {
    header = 'Lista dig på 1177'
    text = `Du har uppdaterat din förvalda vårdcentral. Om du vill lista dig på ${savedData.careUnit} kan du göra det på 1177.`
    buttonText = 'till 1177'
    icon = 'completed'
  }

  return (
    <div>
      <CareUnitView savedCareCenterData={savedData} />
      <Modal
        isOpen={modalIsOpen}
        title={header}
        text={text}
        iconName={icon ? 'completed' : 'info'}
        size='sm'
        closeButton={{
          variant: 'text',
          text: 'stäng'
        }}
        handleClose={() => setModalIsOpen(false)}
      >
        {icon
          ? (
            <Button
              iconRight='share-link'
              variant='primary'
              to="1177"
              targetTo='_blank'
              childMargin={{ r: '8px' }}
            >
              {buttonText}
            </Button>
          )
          : (
            <Button to="listing > select" variant='primary' track={{
              name: 'listing_cta',
              category: 'listing',
              action: 'to_listing'
            }}>
              {buttonText}
            </Button>
          )}
      </Modal>
    </div>
  )
}

export const ModalView = ({ savedCenterId, centers }: ModalViewProps) => {
  const getSavedCareCenterData = () => {
    if (!savedCenterId) return null

    const careUnit = centers.find((i) => i.idExt === savedCenterId)

    return {
      careUnit: careUnit.healthcareunit,
      phoneNumber: careUnit.healthcareunitPhoneNumber,
      address: careUnit.healthcareunitAddress,
      postCode: careUnit.healthcareunitZipCode,
      city: careUnit.cityname,
      to: careUnit.healthcareunitWebAddress
    }
  }
  return <ModalPage savedData={getSavedCareCenterData()} header={undefined} text={undefined} path={undefined} buttonText={undefined} icon={undefined} />
}

export const SelectedModalView = connect((state: any) => {
  return {
    centers: getCareCentersSelector(state),
    savedCenterId: state.consent.myInformation.primaryHealthCareEntityId
  }
})(ModalView)

SelectedModalView.propTypes = {
  header: PropTypes.string,
  text: PropTypes.string,
  icon: PropTypes.string
}
