import { featureConfigOfSession } from "./sessionUtils";

export const defaultFeatureConfig = {
    findHealthcareUnits: true,
    smsValidation: false,
    eid2Enabled: true,
    useService2: false
}

const initialState = {
    ...defaultFeatureConfig,
    reloadIntervalId: null,
};

export const SET_FEATURE_CONFIG = "SET_FEATURE_CONFIG";
export const SET_CONFIG_RELOAD_INTERVAL_ID = "SET_CONFIG_RELOAD_INTERVAL_ID";

export const actions = {
    setFeatureConfig: payload => ({
        type: SET_FEATURE_CONFIG,
        payload,
    }),
    setReloadIntervalId: payload => ({
        type: SET_CONFIG_RELOAD_INTERVAL_ID,
        payload,
    })
}


export const reducer = (state = initialState, { type, payload }) => {
    if (type === SET_FEATURE_CONFIG) {
        featureConfigOfSession.set(payload || defaultFeatureConfig);
        return {
            ...state,
            ...(payload || defaultFeatureConfig),
        }
    }
    else if (type == SET_CONFIG_RELOAD_INTERVAL_ID) {
        return {
            ...state,
            reloadIntervalId: payload,
        }
    }
    else {
        return state;
    }
}
