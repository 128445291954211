import React, { useState, useMemo, useEffect } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { FindCareUnitView } from './FindCareUnitView'
import { createPath, paths } from '../../utils/urlPaths'
import config from '../../api/config'
import { FixMeLater } from 'src/new-lib/_types'
import { To } from 'src/new-lib/LinkTo'
import { CareUnit } from './FindCareUnitView'

type HealthcareUnit = {
  id: number
  HsaId: string
  healthcareunit: string
  healthcareunitAddress: string
  healthcareunitZipCode: string
  cityname: string
  healthcareunitPhoneNumber: string
  healthcareunitWebAddress: To
  healthcareAreas: string[] | []
  type: "specialist" | "digitalvc" | "vardcentral"
  healthcareunitRegions: string
  Url1177: To
  digitalProviderRelativeUri: To
  healthcareunitLatitude: string
  healthcareunitLongitude: string
  idExt: string
  isDigitalProvider: boolean
  isDigitalProviderActive: boolean
  digitalProviderExternalUri?: To
  isPatientListable: boolean
  isbvc: boolean
  videoCallDelegation: boolean
}

type Props = {
  healthcareUnits: HealthcareUnit[]
  regions: FixMeLater
  location: FixMeLater
  history: FixMeLater
}

const _FindCareUnitPage = ({ healthcareUnits, regions, location, history }: Props) => {
  const urlParams = new URLSearchParams(location.search)
  const hcuType = urlParams.get('type')

  const filteredUnits: HealthcareUnit[] = useMemo(() => {
    if (hcuType) {
      return healthcareUnits.filter(hcu => hcu.type === hcuType)
    }
    return healthcareUnits
  }, [healthcareUnits, hcuType])

  const units = useMemo(() => filteredUnits.map(unit => ({
    id: unit.id,
    hsaid: unit.HsaId,
    name: unit.healthcareunit,
    street: unit.healthcareunitAddress,
    postcode: unit.healthcareunitZipCode,
    postarea: unit.cityname,
    phone: unit.healthcareunitPhoneNumber,
    webAddress: unit.healthcareunitWebAddress,
    careAreas: unit.healthcareAreas,
    type: unit.type,
    region: unit.healthcareunitRegions,
    chat: onChatButtonClick(unit, history)
  })
  ), [filteredUnits]) as CareUnit[]

  const isDistinct = (value: FixMeLater, index: FixMeLater, self: FixMeLater) => self.indexOf(value) === index
  const careAreas = useMemo(() => filteredUnits.flatMap(hcu => hcu.healthcareAreas).filter(isDistinct),
    [filteredUnits])

  const [careAreaState, setCareAreaState] = useState({
    selected: '',
    onSelect: (ev: React.ChangeEvent<HTMLSelectElement>) => {
      setCareAreaState({ ...careAreaState, selected: ev.target.value })
    },
    items: careAreas
  })

  useEffect(() => {
    setCareAreaState({ ...careAreaState, items: careAreas.sort() })
  }, [careAreas])

  const [regionState, setRegionState] = useState({
    selected: '',
    onSelect: (ev: React.ChangeEvent<HTMLSelectElement>) => {
      setRegionState({ ...regionState, selected: ev.target.value })
    },
    items: regions
  })

  useEffect(() => {
    setRegionState({ ...regionState, items: regions })
  }, [regions])

  return (
    <FindCareUnitView
      careAreas={careAreaState}
      regions={regionState}
      careUnits={units}
    />
  )
}

const onChatButtonClick = (unit: HealthcareUnit, history: FixMeLater) => {
  if (unit.isDigitalProvider && unit.isDigitalProviderActive) {
    const url = new URL(config.flow)
    url.searchParams.append('hcuss', createPath(unit.digitalProviderRelativeUri))

    const onClick = () => { window.location.href = url.href }
    return onClick
  }

  if (unit.isDigitalProvider) {
    const url = `${config.capioGoOrigin}${unit.digitalProviderRelativeUri}`
    return url
  }

  if (unit.digitalProviderExternalUri) {
    const onClick = () => {
      history.push({
        pathname: paths["switchboard"],
        search: `?hid=${unit.idExt}`
      })
    }
    return onClick
  }

  return null
}

const mapStateToProps = (state: FixMeLater) => ({
  healthcareUnits: state.healthCenter.digitalCenters,
  regions: state.location.regions
})

export const FindCareUnitPage = withRouter(connect(mapStateToProps)(_FindCareUnitPage))
