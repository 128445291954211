import React, {Component} from "react";
import { connect } from "react-redux";
import config from '../../../api/config';
import {actions} from "../../../store/reducers/consents";


class DefaultUnitChoice extends Component {
    constructor(props) {
        super(props);
    }

    enterHCU = () => {
        let url = new URL(config.flow);
        url.searchParams.append('hcuss', this.props.defaultHCU.digitalProviderRelativeUri);
        window.location = url;
    };

    onClickDefaultUnit = () => {
        if (this.props.forceSave) {
            this.props.dispatch(actions.selectSwitchBoardHCU(this.props.defaultHCU.idExt));
            this.props.dispatch(actions.updateAssociatedCareGivers())
                .then(() => {
                    this.enterHCU();
                })
                .catch(() => {
                    this.enterHCU();
                });
            return;
        }
        this.enterHCU();
    }


    render() {
        return (
            <>
            {this.onClickDefaultUnit()}
            </>
        )
    }
}

export const DefaultUnitChoicePage = connect()(DefaultUnitChoice);
