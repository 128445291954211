import { useEffect } from 'react'
import { connect } from 'react-redux'
import queryString from 'query-string'
import { actions } from '../../store/reducers/newsletter'
import { actions as unsubscribeAction } from '../../store/reducers/unsubscribe'
import { paths } from '../../utils/urlPaths'
import { UnsubscribeView } from './UnsubscribeView'
import { LoggedOutLayout } from 'src/pages'
import { FixMeLater } from 'src/new-lib/_types'
import store from "../../store/configureStore"

type Props = {
  match: FixMeLater
  dispatch: FixMeLater
  error: boolean
  loading: boolean
  location: FixMeLater
}

const _UnsubscribePage = ({
  match,
  dispatch,
  error,
  loading,
  location
}: Props) => {
  const { type, token } = (match && match.params)
  const { messageId } = queryString.parse(location.search)

  const validType = type === 'email' || type === 'sms'

  if (!token || !validType) {
    error = true
    loading = false
  }

  useEffect(() => {
    if (validType && token) {
      dispatch(store.getState().featureManager.useService2 ? unsubscribeAction.unsubscribeNewsletter(type, token, messageId) : actions.unsubscribeNewsletter(type, token, messageId))
    }
  }, [validType, type, token, dispatch, messageId])

  return (
    <LoggedOutLayout>
      <UnsubscribeView
        error={error}
        loading={loading}
      />
    </LoggedOutLayout>
  )
}

const mapStateToProps = (state: FixMeLater) => {
  const response = state.newsletter.unsubscribeResponse

  return ({
    error: response === 'error',
    loading: response === undefined || response === 'loading',
    homePath: state.app.userLoggedIn ? paths["home"] : '/'
  })
}

export const UnsubscribePage = connect(mapStateToProps)(_UnsubscribePage)
