import type { TosLegacy } from "src/api/services/TermsOfServiceAPI"
import type { GetLatestTosResponse } from "src/api/services/AccountAPI/AccountAPIModel"
import type { AxiosError } from "axios"

export type InitialState = {
  latestTermsOfService: {
    isLoading: boolean
    data?: TosLegacy | GetLatestTosResponse
    error?: AxiosError
  }
  status: {
    isLoading: boolean
    data?: "outdated" | "up to date"
    error?: AxiosError
  }
}

export type TosState = InitialState["latestTermsOfService"]

export const initialState: InitialState = {
  latestTermsOfService: { isLoading: false },
  status: { isLoading: false }
};
