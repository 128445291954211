import { useEffect } from "react";


const loadCookiebotScript = () => {
    const existingScript = document.getElementById("Cookiebot");
  
    if (existingScript) {
      console.log("Cookiebot script already loaded");
      return;
    }
  
    var script = document.createElement("script");
    script.id = "Cookiebot";
    script.src = "https://consent.cookiebot.com/uc.js";
    script.setAttribute("data-cbid", "65f3c763-5ad7-4429-84ae-ff66af9fbd25");
    script.setAttribute("data-blockingmode", "auto");
    script.type = "text/javascript";
    document.head.appendChild(script);
  
  
  }

  /**
   * @description The cookiebot proxy page was built to be consumed by our react native app.
   * For more information go to: https://capioonline.atlassian.net/wiki/spaces/SD/pages/193265666/8866+-+Can+we+use+the+cookiebot+consent+manager+for+Piwik+React+Native#Problem
   */
export const CookiebotProxyPage = () => {
    useEffect(() => {
        loadCookiebotScript()
    }, [])

    return <></>
}