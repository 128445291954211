import { createStore, compose, applyMiddleware } from 'redux';
import thunk from 'redux-thunk';
import reduxImmutableStateInvariant from 'redux-immutable-state-invariant';
import createInitialState from './index';
import Axios from 'axios';
import config from '../api/config';
import rootReducer from './reducers';
import Api from '../api';

let configureStoreProd = (initialState = createInitialState()) => {
  const api = new Api(Axios);
  const store = createStore(
    rootReducer,
    initialState,
    applyMiddleware(thunk.withExtraArgument(api))
  );
  api.onError = error => {
    console.log(error);
    if (error.response.status === 403 || error.response.status === 401) {
      localStorage.removeItem('sessionToken');
      window.location = `${config.loginOverride}`;
    }
    if (error.response.status === 404) {
      console.log(api);
    }

  };
  return store;
};

let configureStoreDev = (initialState = createInitialState()) => {
  const api = new Api(Axios);
  const middlewares = [
    reduxImmutableStateInvariant(),
    thunk.withExtraArgument(api)
  ];
  const composeEnhancers =
    (window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({ trace: true, traceLimit: 25 })) || compose;

  const store = createStore(
    rootReducer,
    initialState,
    composeEnhancers(applyMiddleware(...middlewares))
  );
  api.onError = error => {
    console.log('interceptor on error: ', error);
    if ((error && error.response && error.response.status === 403) ||
      (error && error.response && error.response.status === 401)) {
      window.location = `${config.loginOverride}`;
    }
  };
  return store;
};

const configureStore =
  config.environment === "local" || config.environment === "dev"
    ? configureStoreDev
    : configureStoreProd;

const store = configureStore();

export default store;
