
import scss from './ExternalChatView.module.scss'
import { Typography } from 'src/new-lib/Typography'
import { useDeviceSize } from 'src/new-lib/_utils/hooks'
import { Button } from 'src/new-lib/Button/Button'
import { LinkCard } from 'src/new-lib/LinkCard'
import { To } from 'src/new-lib/LinkTo'
import config from 'src/api/config'


export type ExternalChatViewProps = {
  unitName: string,
  toUnit: To,
  toHome: To,
}

export const ExternalChatView = ({
  unitName,
  toUnit,
  toHome,
}: ExternalChatViewProps) => {
  const { isMobile } = useDeviceSize()

  const [mHeader, mParagraph] = [
    { b: '16px' },
    { b: '40px' },
  ]

  return (
    <section className={scss.root}>

      <section className={scss.card}>
        <Typography margin={mHeader} tag='h1' variant='h2' align='left'>Din chatt kommer öppnas i ett nytt fönster</Typography>
        <Typography margin={mParagraph} tag='p' align='left'>
          När du öppnar chatten till denna mottagning kommer den öppnas i ett nytt fönster. Du kommer också behöva logga in på nytt.
        </Typography>
        <LinkCard expand to={toUnit} target={'_blank'} leftIconName='stethoscope' rightIconName='share-link' rightIconSize='md'>
          <Typography tag='p' variant='h4' margin={{}}>{unitName}</Typography>
        </LinkCard>

        <Typography margin={{ t: "40px", b: "8px" }} tag='p' variant='psm' align='center'>
          Eller kontakta vår digitala mottagning
        </Typography>
        <div className={scss['button-container']}>
          <Button to={config.flow} expand={isMobile} margin={{ b: "16px" }}>Till Capio Go</Button>
          <Button variant={isMobile ? 'secondary-dark' : 'text'} to={toHome} expand={isMobile}>Avbryt</Button>
        </div>
      </section>

    </section>
    
  )
}