import { RootState } from "src/store/Model";
import { Props, SeekCareView } from "./SeekCareView";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { actions } from 'src/store/reducers/content'
import { useEffect } from "react";
import { Dispatch, bindActionCreators } from "redux";



const Page = (props: Props & { fetchContent: typeof actions.fetchFlowOnboardingStart}) => {
    useEffect(() => {
        props.fetchContent()
    }, [])
    return <SeekCareView {...props} />
  }
  
  
  const mapStateToProps = (state: RootState) => ({
    content: state.content.flowOnboardingStart
  })
  
  const mapDispatchToProps = (dispatch: Dispatch) => bindActionCreators({
    fetchContent: actions.fetchFlowOnboardingStart
  }, dispatch)
  
  export const SeekCarePage = withRouter(
    connect(mapStateToProps, mapDispatchToProps)(Page)
  );