import React from 'react'
import { Footer } from 'src/new-lib/Footer'
import { Header } from '../../../new-lib/Header'
import styles from './LoggedOutLayout.module.scss'

type Props = { children: React.ReactNode }

export const LoggedOutLayout = ({ children }: Props) => {
  return (
    <div className={styles.root}>
      <Header isLoggedIn={false} />
      {children}
      <section style={{ alignItems: 'end' }}>
        <Footer signedIn={false} />
      </section>
    </div>
  )
}
