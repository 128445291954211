import React from 'react'
import styles from './SelectRegionView.module.scss'
import PropTypes from 'prop-types'
import { Typography } from '../../new-lib/Typography'
import { LinkRow } from '../../new-lib/LinkRow'
import { Button } from '../../new-lib/Button'
import { useDeviceSize } from '../../new-lib/_utils'

type Region = { name: string, onClick: () => void }

type Props = {
  regions: Array<Region>
}

export const SelectRegionView = ({ regions}: Props) => {
  const { isMobile } = useDeviceSize()

  return (
    <section className={styles.body}>
      <div className={styles['select-region-container']}>
        <Typography tag='h2' margin={{}} align='center'>Välj region</Typography>
        <div className={styles['link-rows-container']}>
          {regions.map(({ name, onClick: handleClick }) => (
            <LinkRow
              key={name}
              title={name}
              rightIconName='chevron-right'
              rightIconSize='md'
              onClick={handleClick}
              track={{
                name: 'select_region',
                category: 'digital_care',
                action: 'select_care_provider',
              }}
            />
          ))}
        </div>
        <Button
          expand
          variant={isMobile ? 'secondary-light' : 'text'}
          to="switchboard > pre-selected unit"
          iconLeft={isMobile ? 'close' : null}
          childMargin={isMobile && { l: 8 }}
        >
          avbryt
        </Button>
      </div>
    </section>
  )
}

SelectRegionView.propTypes = {
  regions: PropTypes.array
}
