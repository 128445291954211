import React, { useState } from 'react'
import styles from './LinkCard.module.scss'
import classNames from 'classnames'
import { Icon, IconColors, IconNames, IconSizes, } from '../Icon'
import { LinkTo, Targets, To } from '../LinkTo'
import { getNumOfChildren } from '../_utils'

/**
* @description LinkCard Component
*
* This component replaces CareUnitCard, CareUnitAddressCard and WebTagLink
*/

type Props = {
  to?: To
  target?: Targets,
  onClick?: () => void
  wide?: boolean
  expand?: boolean
  leftIconName?: IconNames
  leftIconSize?: IconSizes
  rightIconName?: IconNames
  rightIconSize?: IconSizes
  children?: React.ReactNode
  dumb?: boolean
  centerIcons?: boolean
}

export const LinkCard = ({
  to,
  target,
  onClick,
  wide,
  expand,
  leftIconName,
  leftIconSize = 'lg',
  rightIconName,
  rightIconSize = 'lg',
  children,
  dumb,
  centerIcons
}: Props) => {
  const cardClassNames = classNames({
    [styles.card]: true,
    [styles['card-wide']]: wide && !expand,
    [styles['card-expand']]: expand,
    [styles['card-with-one-label-or-less']]: getNumOfChildren(children) <= 1,
    [styles['card-dumb']]: dumb,
    [styles['card-centered-icons']]: centerIcons,
  })

  const [iconColor, setIconColor] = useState<IconColors>('primary')

  const handleMouseEnter = () => {
    if (!dumb) setIconColor('hover')
  }
  const handleMouseLeave = () => {
    if (!dumb) setIconColor('primary')
  }

  const Card = () => (
    <>
      <div className={styles['top-row']}>
        <div className={styles['icon-default']}>
          <Icon color={iconColor} name={leftIconName} size={leftIconSize} />
        </div>
        <div className={styles['title-container']}>
          {children instanceof Array ? children[0] : children}
        </div>
        <div className={styles['right-icon']}>
          <Icon color={iconColor} name={rightIconName} size={rightIconSize} />
        </div>
      </div>

      <div className={styles['labels-container']}>
        {children instanceof Array && children.slice(1, children.length)}
      </div>
    </>
  )

  const CenteredIconsCard = () => (
    <>
      <div className={styles['centered-icons-left-icon']}>
        <Icon color={iconColor} name={leftIconName} size={leftIconSize} />
      </div>

      <div className={styles['centered-icons-text-container']}>
        {children}
      </div>
      
      <div className={styles['centered-icons-right-icon']}>
        <Icon color={iconColor} name={rightIconName} size={rightIconSize} />
      </div>
    </>
  )

  return to
    ? (
      <LinkTo
        className={cardClassNames}
        to={to}
        target={target}
        displayLinkIcon={false}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
      >
        { centerIcons ? <CenteredIconsCard /> : <Card /> }
      </LinkTo>
    ) : (
      <div
        className={cardClassNames}
        onClick={onClick}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
      >
        { centerIcons ? <CenteredIconsCard /> : <Card /> }
      </div>
    )
}
