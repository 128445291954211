import styles from './ContactCareUnitView.module.scss'
import { Typography } from 'src/new-lib/Typography'
import { LinkCard } from 'src/new-lib/LinkCard'
import { Button } from 'src/new-lib/Button'
import { useDeviceSize } from 'src/new-lib/_utils'

type Props = {
  careUnit: {
    name: string
    phone: string
    onClick: () => void
  }
  onBackClick: () => void
  onCapioGoClick: () => void
}

export const ContactCareUnitView = ({ careUnit, onBackClick, onCapioGoClick }: Props) => {
  // TODO: if isDigitalProvider is TRUE but not isDigitalProviderActive then the care unit does have a chat
  // but the onClick function will redirect to another view instead of going directly to doctrin. Need to get
  // the second views onClick function and pass it in to this view, (or rebuild the whole thing) but for now
  // it will render as if it doesn't have a chat. Which is only Capio Familjeläkarna Glommen in Halland
  // const hasChat = careUnit.isDigitalProvider && careUnit.isDigitalProviderActive
  // TODO: Uncomment ^above line^ when Switchboard has been rebuilt
  const hasChat = false

  const cardTitle = hasChat ? 'Din chatt kommer öppnas i ett nytt fönster' : 'Mottagningen har ingen chatt'
  const cardText = hasChat
    ? 'När du öppnar chatten till denna mottagning kommer den öppnas i ett nytt fönster. Du kommer också behöva logga in på nytt.'
    : 'Den mottagning du valt har tyvärr ingen chatt. Du når den enklast via telefon. Antingen kan du välja någon av våra andra mottagningar eller kontakta vår heldigitala mottagning, Capio Go.'

  const linkCardTitle = hasChat ? 'Chatta med mottagning' : 'Ring mottagning'

  const { isMobile } = useDeviceSize()

  const renderLinkCard = () => {
    return hasChat ? (
      <LinkCard
        onClick={careUnit.onClick}
        leftIconName='stethoscope'
        rightIconName={hasChat ? 'share-link' : 'phone'}
        rightIconSize='md'
      >
        <Typography tag='h4' margin={{}}>{careUnit.name}</Typography>
        {!hasChat && <Typography tag='p' margin={{ t: 8 }}>{careUnit.phone}</Typography>}
      </LinkCard>)
      : (
        <LinkCard
          to={`tel:${careUnit.phone}`}
          centerIcons={true}
          leftIconName='stethoscope'
          rightIconName={hasChat ? 'share-link' : 'phone'}
          rightIconSize='md'
        >
          <Typography tag='h4' margin={{}}>{careUnit.name}</Typography>
          {!hasChat && <Typography tag='p' margin={{ t: 8 }}>{careUnit.phone}</Typography>}
        </LinkCard>)
  }

  return (
    <div className={styles.body}>
      <div className={styles['card-container']}>
        {/* TODO: Uncomment this when Switchboard has been rebuilt */}
        {/* <div className={styles['back-link']} onClick={onBackClick}>
          <Icon name='nav-arrow-left' />
          <Typography
            tag='p'
            weight='700'
            fontSize='sm'
            transform='uppercase'
            spacing='xl'
            margin={{}}
            >
            välj annan mottagning
          </Typography>
        </div> */}
        <section className={styles.card}>
          <Typography tag='h2' margin={{ b: 16 }}>{cardTitle}</Typography>
          <Typography tag='p' margin={{ b: 40 }}>{cardText}</Typography>

          <Typography tag='p' fontSize='sm' align='center' margin={{ b: 8 }}>{linkCardTitle}</Typography>
          {renderLinkCard()}

          <Typography
            tag='p'
            fontSize='sm'
            align='center'
            margin={{ t: 40, b: 8 }}
          >
            Eller kontakta vår heldigitala mottagning
          </Typography>
          {/* <Button expand variant='primary' to={ROUTE_NAME.FLOW_REDIRECT_LANDING} margin={{ b: 16 }}>till capio go</Button> */}
          <Button expand onClick={onCapioGoClick} margin={{ b: 16 }}>till capio go</Button>
          {/* TODO: Link back to SeekCareView when Switchboard has been rebuilt (to={ROUTE_NAME.SWITCHBOARD.DEFAULT_PATH}) */}
          <Button expand variant={isMobile ? 'secondary-dark' : 'text'} to="home">avbryt</Button>
        </section>
      </div>
    </div>
  )
}
