import React from "react";
import { ScreenContainer } from "./ScreenContainer";
import { Text, Button, TermsOfService, InfoDialog } from "../../lib";
import { bool, func } from "prop-types";

export const ProfileTermsAndDataScreen = ({
    cancelDialogIsOpen,
    onCancelButtonClick,
    onDialogConfirmClick,
    onDialogCancelClick,
    termsOfService }) => {
    return (
        <ScreenContainer>
            <Text tag="h1">Villkor och datahantering</Text>

            <Text tag="h2" style={{ marginBottom: "5px" }}>Ta bort konto</Text>
            <Text tag="p" style={{ marginBottom: "10px" }}>{cancellationParagraph}</Text>

            <Button onClick={onCancelButtonClick} alert secondary e2e="tnd-cancel-acc-btn">
                Ta bort mitt konto
            </Button>
            <InfoDialog
                open={cancelDialogIsOpen}
                onCancellationButtonClick={onCancelButtonClick}
                heading="Bekräfta"
                body={<Text tag="p">Är du säker på att du vill avsluta ditt konto?</Text>}
                actions={(
                    <div>
                        <Button onClick={onDialogConfirmClick} style={{ marginRight: "20px" }} secondary>Ja</Button>
                        <Button onClick={onDialogCancelClick}>Nej</Button>
                    </div>)}
            />

            <Text tag="h2">Tjänstevillkor</Text>
            <TermsOfService termsOfService={ termsOfService } />
        </ScreenContainer>
    )
}


const cancellationParagraph =
    "Genom att klicka på knappen kommer du ta bort ditt användarkonto från vår digitala tjänst. " +
    "Detta innebär att dina kontaktuppgifter raderas, och dina samtycken återkallas. " +
    "Denna åtgärd kan inte ångras. Du kan när som helst registrera dig på nytt genom att logga in " +
    "med bank-id och slutföra registreringen av ditt användarkonto."


ProfileTermsAndDataScreen.propTypes = {
    cancelDialogIsOpen: bool.isRequired,
    onCancelButtonClick: func.isRequired,
    onDialogConfirmClick: func.isRequired,
    onDialogCancelClick: func.isRequired,
}
