import { CustomEvent, CustomDimensions } from '@piwikpro/react-piwik-pro'
import config from 'src/api/config'
import scss from '../_styles/index.module.scss'
import { AxiosError } from 'axios'

/**
 * @description A map of all defined events tracked in the application, so we easily can tell what is tracked.
 * Add events here so that they can be inferred by the tracking-function.
 */
const definedEvents = [
  {
    category: 'home',
    action: 'to_home',
    name: 'home_cta'
  },
  {
    category: 'home',
    action: 'to_home',
    name: 'seek_care_cta'
  },
  {
    category: 'my_profile',
    action: 'to_my_profile',
    name: 'my_profile_link'
  },
  {
    category: 'listing',
    action: 'to_listing',
    name: 'listing_dropdown_link'
  },
  {
    category: 'digital_care',
    action: 'to_digital_care',
    name: 'topbar_menu_link'
  },
  {
    category: 'awareness',
    action: 'to_faq',
    name: 'topbar_menu_link'
  },
  {
    category: 'listing',
    action: 'to_listing',
    name: 'listing_cta'
  },
  {
    category: 'listing',
    action: 'to_listing',
    name: 'listing_cta'
  },
  {
    category: 'digital_care',
    action: 'to_digital_care',
    name: 'seek_care_cta2'
  },
  {
    category: 'listing',
    action: 'sign_contract',
    name: 'listing_sign'
  },
  {
    category: 'listing',
    action: 'select_region',
    name: 'listing_region'
  },
  {
    category: 'listing',
    action: 'select_primary_care_unit',
    name: 'listing_primary_care_unit'
  },
  {
    category: 'digital_care',
    action: 'authenticate',
    name: 'login'
  },
  {
    category: 'digital_care',
    action: 'to_select_unit',
    name: 'select_unit_cta'
  },
  {
    category: 'digital_care',
    action: 'select_care_provider',
    name: 'select_unit'
  },
  {
    category: 'digital_care',
    action: 'select_care_provider',
    name: 'select_region'
  },
  {
    category: 'digital_care',
    action: 'to_digital_care',
    name: 'seek_care_cta3'
  },
  {
    category: 'app_diagnostics',
    action: 'error_reported',
    name: 'unhandled_error'
  },
  {
    category: 'app_diagnostics',
    action: 'error_reported',
    name: 'axios_error'
  }
] as const

/**
 * @description A map of all defined custom "event" dimensions.
 */
const eventDimensions = {
  content: config.piwikDimension.event.content,
  viewport: config.piwikDimension.event.viewport,
  pageContext: config.piwikDimension.event.pageContext,
  option: config.piwikDimension.event.option,
  errorMessage: config.piwikDimension.event.message,
  componentStack: config.piwikDimension.event.stackTrace,
  errorName: config.piwikDimension.event.errorName
} as const

type EventDimension = typeof eventDimensions
type EventDimensionName = keyof EventDimension

/**
 * @description A map of all defined session "event" dimensions.
 */
const sessionDimensions = {
  gender: {
    id: config.piwikDimension.session.gender,
    possibleValues: ['Male', 'Female']
  }
} as const

type SessionDimension = typeof sessionDimensions
type SessionDimensionName = keyof SessionDimension

type PartialRecord<K extends keyof any, T> = Partial<Record<K, T>>

export const createTrackFunction = {
  fromDefinedEvents: <
    TEvents extends readonly {
      category: string
      action: string
      name: string
    }[]
  >(
    _: TEvents
  ) => ({
    customDimensions: <TEDimensions extends {}, TSDimensions>(
      eDimensions: TEDimensions,
      sDimension: TSDimensions
    ) => ({
      track: (
        payload: TEvents[number] & { value?: number } & PartialRecord<
            EventDimensionName,
            string
          >
      ) => {
        Object.keys(payload).forEach((dimensionName) => {
          CustomDimensions.setCustomDimensionValue(
            eventDimensions.viewport,
            window.innerWidth < parseInt(scss.mobileMaxWidthPx)
              ? 'Mobile'
              : 'Desktop'
          )

          const dimensionId = eDimensions[dimensionName]
          if (dimensionId)
            CustomDimensions.setCustomDimensionValue(
              dimensionId,
              payload[dimensionName]
            )
        })

        CustomEvent.trackEvent(payload.category, payload.action, payload.name)
      },
      setSessionDimension: <T extends SessionDimensionName>(
        dimension: T,
        value: SessionDimension[T]['possibleValues'][number]
      ) => {
        CustomDimensions.setCustomDimensionValue(
          sessionDimensions[dimension].id,
          value
        )
      }
    })
  })
}

export const analytics = createTrackFunction
  .fromDefinedEvents(definedEvents)
  .customDimensions(eventDimensions, sessionDimensions)

export const trackGlobalError = (error: Error, errorInfo: React.ErrorInfo) => {
  analytics.track({
    category: 'app_diagnostics',
    action: 'error_reported',
    name: 'unhandled_error',
    errorMessage: error.message,
    componentStack: errorInfo.componentStack,
    errorName: error.name
  })
}

export const trackAxiosError = (error: AxiosError) => {
  analytics.track({
      category: "app_diagnostics",
      action: "error_reported",
      name: "axios_error",
      errorName: `${error.request.method} ${error.request.url} ${error.response?.status}`,
      errorMessage: `Http request: ${error.request.method} ${error.request.url} failed with ${error.response?.status} (${error.response?.statusText})`
  })
}