import React, { useState } from "react";
import { withRouter } from "react-router-dom";
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import { Button } from "../Button";
import styles from "./FlowTopbar.module.scss";
import { Icon } from "../Icon";
import { Text } from "../Text";
import { paths } from "../../utils/urlPaths";
import { InfoDialog } from "../Dialog";
import { func } from "prop-types";


export const ChatTopbar = () => {
    const [showLeaveDialog, setShowLeaveDialog] = useState(false);
    const [showGoToListingDialog, setShowGoToListingDialog] = useState(false);

    return (
        <>
            <FlowTopbarScreen
                onLeaveButtonClick={() => setShowLeaveDialog(true)}
                onListingButtonClick={() => setShowGoToListingDialog(true)}
            />
            <LeaveChatDialog
                open={showGoToListingDialog}
                confirmRoute={paths["listing > select"]}
                onCancel={() => setShowGoToListingDialog(false)}
                copy={{
                    heading: "Vill du lämna mötet?",
                    body: 'För att lista dig måste du lämna ditt digitala möte. Ditt ärende kommer att sparas och du kan när som helst gå tillbaka in i mötet igen genom "Starta digitalt möte".',
                    confirmButton: "Till listningtjänsten",
                    cancelButton: "Återgå till mötet"
                }}
            />

            <LeaveChatDialog
                open={showLeaveDialog}
                confirmRoute={paths["home"]}
                onCancel={() => setShowLeaveDialog(false)}
                copy={{
                    heading: "Vill du lämna mötet?",
                    body: 'Ditt ärende kommer att sparas och du kan när som helst gå tillbaka in i mötet igen genom "Starta digitalt möte".',
                    confirmButton: "Ja",
                    cancelButton: "Återgå till mötet"
                }}
            />
        </>)

}

export const SwitchboardTopbar = withRouter(({ history }) => (
    <FlowTopbarScreen
        onListingButtonClick={() => history.replace(paths["listing > select"])}
        onLeaveButtonClick={() => history.replace(paths["home"])}
    />
));

export const FlowTopbarScreen = ({
    onListingButtonClick,
    onLeaveButtonClick,
}) => {

    return (
        <AppBar classes={{ root: styles.root }} elevation={0}>
            <Toolbar className={styles.toolbar}>
                <div className={styles.row}>
                    <div className={styles.leftSlot}>
                        <Text tag="h2" align="center" style={{ marginTop: 30 }}>{'Digitalt möte'}</Text>
                    </div>
                    <div className={styles.centerSlot}>
                        <Button size="small" flat onClick={onListingButtonClick}>Välj oss</Button>
                    </div>
                    <div className={styles.rightSlot}>
                        <Button
                            flat
                            endIcon={<Icon color="alert" size="xs" name="close" />}
                            onClick={onLeaveButtonClick}
                            style={{ minWidth: 39, color: "#fa5454" }}
                        >
                            Lämna
                        </Button>
                    </div>

                </div>
            </Toolbar>

        </AppBar>
    );
};

FlowTopbarScreen.propTypes = {
    onListingButtonClick: func.isRequired,
    onLeaveButtonClick: func.isRequired,

}

const LeaveChatDialog = ({ open, onCancel, confirmRoute, copy: { heading, body, confirmButton, cancelButton } }) => (
    <InfoDialog
        renderIcon={(size) =>
        (<Icon
            name="question-circle"
            size={size}
            color="tertiary"

        />)}
        heading={heading}
        body={(
            <>
                <Text tag="p">
                    {body}
                </Text>
            </>)}
        open={open}
        actions={
            <div>
                <div style={{ marginBottom: "-15px" }}>
                    <Button style={{ marginRight: "20px", width: "100%" }} to={confirmRoute}>{confirmButton}</Button>
                </div>
                <div>
                    <Button style={{ width: "100%" }} secondary onClick={onCancel}>{cancelButton}</Button>
                </div>
            </div>}
    />
);


