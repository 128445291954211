import { initialState } from "./initialState"

import {
  UNSUBSCRIBE_NEWSLETTER,
  UNSUBSCRIBE_NEWSLETTER_FAILED,
  UNSUBSCRIBE_NEWSLETTER_SUCCEEDED
} from "./action-types"

export const reducer = (state = initialState, action) => {
  switch (action.type) {
    case UNSUBSCRIBE_NEWSLETTER:
      return {
        ...state,
        unsubscribeResponse: "pending",
        unsubscribeToken: action.payload,
      };
    case UNSUBSCRIBE_NEWSLETTER_FAILED:
      return {
        ...state,
        unsubscribeResponse: "error",
      };
    case UNSUBSCRIBE_NEWSLETTER_SUCCEEDED:
      return {
        ...state,
        unsubscribeResponse: "success",
      };
  default:
    return state;
  }
};
  