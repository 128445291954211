import NetworkError from '../NetworkError';
import config from '../config';

export default class CenterAPI {
  constructor(http) {
    this._http = http;
    this._endpoint = `${config.base}/cms/api/v1`;
  }

  async getAllDigitalProviders() {
    const res = await this._http.get(`${this._endpoint}/digital-providers`);

    if (res.status !== 200) {
      throw new NetworkError(res.status, res.statusText, res.data);
    }

    return res.data;
  }
}
