import { UpdatedTermsOfServiceModalView } from "./UpdatedTermsOfServiceModalView"
import { connect } from 'react-redux';
import { actions } from "../../store/reducers/terms-of-services/actions"
import { useEffect } from "react";
import { withRouter } from "react-router-dom"

const UpdatedTermsOfServiceModalElement = ({ termsOfService, tosAcceptance, userIsRegistered, dispatch, location }) => {
    const route = location ? location.pathname : "/"
    const isIgnoredRoute = route === "/" || route === "/callback"

    useEffect(() => {
        if (userIsRegistered && !isIgnoredRoute) {
            dispatch(actions.fetchTermsOfServiceToAccept())
        }

    }, [dispatch, userIsRegistered, isIgnoredRoute, route])

    const isOpen = !isIgnoredRoute && !!termsOfService.content && tosAcceptance !== "accepted";
    const acceptTos = () => dispatch(actions.acceptTermsOfServiceToAccept())

    return (
        <UpdatedTermsOfServiceModalView 
            isOpen={isOpen}
            termsOfService={termsOfService}
            tosAcceptance={tosAcceptance}
            acceptTos={acceptTos}
        />
    )
}

const mapStateToProps = state => ({
    termsOfService: state.termsOfServices.termsOfServiceToAccept,
    tosAcceptance: state.termsOfServices.tosAcceptance,
    userIsRegistered: state.app.userIsRegistered
})

export const UpdatedTermsOfServiceModal = withRouter(connect(mapStateToProps, null)(UpdatedTermsOfServiceModalElement))