import React from 'react'
import styles from './SeekCareView.module.scss'
import { SearchCareCta } from '../../new-lib/SearchCareCta'
import { Button } from '../../new-lib/Button'
import { Typography } from '../../new-lib/Typography'
import { useDeviceSize } from '../../new-lib/_utils'
import ctaImage from '../../assets/images/child-tie-shoes.png'
import config from 'src/api/config'
import { To } from 'src/new-lib/LinkTo'

import { RootState } from 'src/store/Model'

import { createTryGetContentValueFn } from 'src/api/services/ContentAPI'


export type Props = {
  preselectedCareUnit: { healthcareunit: string, healthcareunitWebAddress: To }
  onClickRegionChoice: () => void,
  content: RootState["content"]["flowOnboardingStart"]
}


export const SeekCareView = ({ preselectedCareUnit: careUnit, onClickRegionChoice, content }: Props) => {
  const { isMobile } = useDeviceSize()
  const tryGetValue = createTryGetContentValueFn(content)
  
  return (
    <div className={styles.body}>
      <div className={styles.content}>
        <SearchCareCta
          title={tryGetValue(c => c.title)}
          text={tryGetValue(c => c.infoText)}
          image={ctaImage}
          alt='child tying shoes'
        >
          {careUnit
            ? <Button expand variant='primary' to={careUnit.healthcareunitWebAddress}>{careUnit.healthcareunit}</Button>
            : <Button
              expand
              variant='primary'
              track={{
                name: 'select_unit_cta',
                category: 'digital_care',
                action: 'to_select_unit',
              }}
              onClick={onClickRegionChoice}
            >
              {tryGetValue(c => c.careUnitOption.text)}
            </Button>}
          <div>
            <Button expand variant='secondary-dark' onClick={() => { window.location = config.flow }}>{tryGetValue(c => c.capioGoOption.text)}</Button>
            <div className={styles['text-container']}>
              <Typography tag='p' fontSize='sm' align='center' display='inline' margin={{}}>{tryGetValue(c => c.capioGoInfoText.leftOfDivider)}</Typography>
              <span className={styles['text-divider']} />
              <Typography tag='p' fontSize='sm' align='center' display='inline' margin={{}}>{tryGetValue(c => c.capioGoInfoText.rightOfDivider)}</Typography>
            </div>
          </div>
          {careUnit &&
            <Button
              variant='text'
              onClick={onClickRegionChoice}
              margin={{ t: 14 }}
              track={{
                name: 'select_unit_cta',
                category: 'digital_care',
                action: 'to_select_unit',
              }}
            >
              välj annan mottagning
            </Button>}
        </SearchCareCta>
        <Button
          expand={isMobile}
          variant='secondary-light'
          iconLeft='nav-arrow-left'
          childMargin={{ l: 8 }}
          to="home"
        >
          {tryGetValue(c => c.backButton.text)}
        </Button>
      </div>
    </div>
  )
}

