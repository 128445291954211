import React from "react";
import connect from "react-redux/es/connect/connect";
import PropTypes from "prop-types";
import { actions as consentActions } from "../../store/reducers/consents";
import { actions as accountActions } from "../../store/reducers/accounts";
import { actions as appActions } from "../../store/reducers/app";
import { actions as termsOfServicesActions } from "src/store/reducers/terms-of-services";
import { actions as contentActions } from "../../store/reducers/content"
import { ProfileView } from "./ProfileView";
import {
  isValidMobilePhoneNumber,
  isEmailAddress,
} from "../../utils/input-validation";
import { validateZipCode as isValidZip, formatPhoneToE164 } from "src/new-lib/_utils";
import { withRouter } from "react-router-dom";
import { tryExtractCountryCodeFromPhoneString, formatPhoneLegacy } from "../../utils/functions";
import { paths } from "src/utils/urlPaths";
import { capitalizeWords } from "src/new-lib/_utils";

class Page extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      popup: false,
      isLoading: false,
      showHasSavedText: false,
      hasUpdatedPhone: false,
      mobileNumber: {
        value: this.props.myInformation.fullMobileNumber,
        errorMessage: "",
      },
      emailAddress: {
        value: this.props.myInformation.emailAddress,
        errorMessage: "",
      },
      streetAddress: {
        value: this.props.myInformation.streetAddress,
        errorMessage: "",
      },
      zipCode: {
        value: this.props.myInformation.zipCode,
        errorMessage: "",
      },
      postAddress: {
        value: this.props.myInformation.postAddress,
        errorMessage: "",
      },
      displayFormError: false,
    };
  }

  componentDidMount() {
    this.setState({ isLoading: true });

    const reducerActions = this.props.useService2 ? accountActions : consentActions
    const fetchContactInfoAction = this.props.useService2 ? accountActions.fetchContactInfo : appActions.fetchContactInfo

    this.props
      .dispatch(fetchContactInfoAction())
      .then((data) => {
        this.setState({
          mobileNumber: { value: data.fullMobileNumber, errorMessage: "" },
        });
        return this.props.dispatch(reducerActions.getAllConsentTypes());
      })
      .then((allConsents) => {
        return this.props
          .dispatch(reducerActions.getAllUserConsentTypes())
          .then((userConsents) => {
            this.props.dispatch(
              consentActions.syncAcceptedConsents({
                all: allConsents,
                user: userConsents,
              })
            );
          });
      })
      .then(() => this.setState({ isLoading: false }))
      .catch(() => this.setState({ isLoading: false }));
    
    this.props.dispatchGetTos();
    this.props.dispatchFetchContent()
  }

  setMobileNumber = (ev) => {
    const value = ev.target ? ev.target.value : "";
    this.setState((state) => {
      const { rule, errorMessage } = isValidMobilePhoneNumber;

      const countryEncodedValue =
        value && tryExtractCountryCodeFromPhoneString(value)
          ? value
          : `+46${value}`;

      const mobileNumber = rule(countryEncodedValue)
        ? { value, errorMessage: "" }
        : { value, errorMessage };

      return {
        mobileNumber,
        displayFormError: false,
      };
    });
  };

  setEmail = (ev) => {
    const value = ev.target ? ev.target.value : "";

    this.setState(() => {
      return isEmailAddress.rule(value)
        ? { displayFormError: false, emailAddress: { value, errorMessage: "" } }
        : {
          displayFormError: false,
          emailAddress: { value, errorMessage: isEmailAddress.errorMessage },
        };
    });
  };

  setStreetAddress = (ev) => {
    const value = ev.target ? ev.target.value : "";

    this.setState(() => {
      return {
        displayFormError: false,
        streetAddress: { value, errorMessage: "" },
      };
    });
  };

  setZipCode = (ev) => {
    const value = ev.target ? ev.target.value : "";

    if (isValidZip(value)) {
      this.setState({
        displayFormError: false,
        zipCode: { value: value, errorMessage: "" },
      });

    } else {
      this.setState({
        displayFormError: false,
        zipCode: { value, errorMessage: "Postnummer är ej giltigt." },
      });
    }
  };

  setPostAddress = (ev) => {
    const value = ev.target ? ev.target.value : "";

    this.setState(() => {
      return {
        displayFormError: false,
        postAddress: { value, errorMessage: "" },
      };
    });
  };

  onSaveChangesButtonClick = async () => {
    const mobileFieldError = this.state.mobileNumber.errorMessage;
    const emailFieldError = this.state.emailAddress.errorMessage;
    const zipCodeFieldError = this.state.zipCode.errorMessage;

    const mobileRequiredError = this.state.mobileNumber.value
      ? mobileFieldError
      : "Du måste ange ett mobilnummer.";

    const emailRequiredError = this.state.emailAddress.value
      ? emailFieldError
      : "Du måste ange en e-postadress.";

    const formIsValid =
      !mobileRequiredError &&
      !mobileFieldError &&
      !emailRequiredError &&
      !emailFieldError &&
      !zipCodeFieldError;

    const newContactFormState = {
      mobileNumber: {
        ...this.state.mobileNumber,
        errorMessage: mobileRequiredError,
      },
      emailAddress: {
        ...this.state.emailAddress,
        errorMessage: emailRequiredError,
      },
    };

    this.setState({ displayFormError: !formIsValid, ...newContactFormState });

    if (formIsValid) {
      const phoneNumber = this.state.mobileNumber.value
      const { countryCode, subscriberNumber } = this.props.useService2 ? formatPhoneToE164(phoneNumber) : formatPhoneLegacy(phoneNumber)
      
      this.props.dispatch(
        consentActions.onMyInfoChangeAll({
          fullMobileNumber: `${countryCode}${subscriberNumber}`,
          validatedMobileNumber: subscriberNumber,
          emailAddress: this.state.emailAddress.value,
          countryNumber: countryCode,
          zipCode: this.state.zipCode.value,
          streetAddress: this.state.streetAddress.value,
          postAddress: this.state.postAddress.value,
        })
      );


      this.setState({ isLoading: true });

      const reducerActions = this.props.useService2 ? accountActions : consentActions

      const updateUserResponse = await this.props.dispatch(reducerActions.updateContactInformation())
      await this.props.dispatch(reducerActions.saveAllUserConsentTypes())

      this.setState({ showHasSavedText: true, isLoading: false })
      if (this.props.useService2) {
        this.setState({ hasUpdatedPhone: !updateUserResponse.phone.isValidated })
      }
    }
  };

  getName = () => {
    if (
      this.props.myInformation.firstName &&
      this.props.myInformation.lastName &&
      this.props.myInformation.protectedIdentity === "N"
    ) {
      return capitalizeWords(
        `${this.props.myInformation.firstName} ${this.props.myInformation.lastName}`
      );
    } else return null;
  };

  render() {
    return (
      <>
        <ProfileView
          content={this.props.content}
          currentPhone={this.props.myInformation.fullMobileNumber}
          phoneField={{
            value: this.state.mobileNumber.value,
            errorText: this.state.mobileNumber.errorMessage,
            onChange: this.setMobileNumber,
            disable: this.state.isLoading,
          }}
          emailField={{
            value: this.state.emailAddress.value,
            onChange: this.setEmail,
            errorText: this.state.emailAddress.errorMessage,
            disable: this.state.isLoading,
          }}
          streetField={{
            value: this.state.streetAddress.value,
            onChange: this.setStreetAddress,
            errorText: "",
            disable: this.state.isLoading,
          }}
          zipCodeField={{
            value: this.state.zipCode.value,
            onChange: this.setZipCode,
            errorText: this.state.zipCode.errorMessage,
            disable: this.state.isLoading,
          }}
          cityField={{
            value: this.state.postAddress.value,
            onChange: this.setPostAddress,
            errorText: "",
            disable: this.state.isLoading,
          }}
          consents={this.props.consentTypes.map((con) => ({
            key: con.consentTypeId,
            disable: this.state.isLoading,
            checked: con.isAccepted,
            title: con.name,
            paragraphs: [con.description],
            onChange: (event) =>
              this.props.dispatch(
                consentActions.toggleConsentTypeCheckbox(
                  con.consentTypeId,
                  event.target.checked
                )
              ),
          }))}
          onSaveChangesButtonClick={this.onSaveChangesButtonClick}
          showHasSavedText={this.state.showHasSavedText}
          resetHasSavedText={() => {
            this.setState({ showHasSavedText: false });
          }}
          onDeleteAccountClick={() => {
            this.setState({ isLoading: true });
            this.props.dispatchCancelAccount();
          }}
          termsOfService={this.props.termsOfService}
          loading={this.state.isLoading}
          hasValidatedPhone={this.props.myInformation.hasValidatedPhone}
          onOtpSuccess={() => this.setState({ hasUpdatedPhone: true })}
          phoneValidationFeatureIsEnabled={this.props.phoneValidationFeatureIsEnabled}
          hasUpdatedPhone={this.state.hasUpdatedPhone}
        />
      </>
    );
  }
}

Page.propTypes = {
  onBackPath: PropTypes.string,
  consentTypes: PropTypes.array,
};

const mapStateToProps = (state) => ({
  consentTypes: state.consent.consentTypes,
  myInformation: state.consent.myInformation,
  termsOfService: state.termsOfServices.latestTermsOfService,
  content: state.content.profile,
  phoneValidationFeatureIsEnabled: state.featureManager.smsValidation,
  useService2: state.featureManager.useService2
});

const mapDispatchToProps = (dispatch, { history }) => ({
  dispatchCancelAccount: () =>
    dispatch(appActions.deleteUserInfo()).then(() => {
      setTimeout(() => {
        history.replace(paths["logout"]);
      }, 1000);
    }),

  dispatchGetTos: () => dispatch(termsOfServicesActions.fetchLatestTermsOfService()),

  dispatchFetchContent: () => dispatch(contentActions.fetchProfile()),

  dispatch,
});

export const ProfileEditContactInfoPage = withRouter(
  connect(mapStateToProps, mapDispatchToProps)(Page)
);


//TODO: FIX use new validateZipCode