import React, { useState } from 'react'
import styles from './SelectCareUnitView.module.scss'
import PropTypes from 'prop-types'
import { Typography } from '../../new-lib/Typography'
import { LinkTo, To } from '../../new-lib/LinkTo'
import { Icon } from '../../new-lib/Icon'
import { TextField } from '../../new-lib/TextField'
import { LinkRow } from '../../new-lib/LinkRow'
import { Button } from '../../new-lib/Button'
import {
  useDeviceSize,
  getCareUnitIconName,
  capitalizeWords
} from 'src/new-lib/_utils'

const cardTitle = 'Välj en mottagning'
const cardText = 'Om du inte hittar den mottagning som du vill kontakta har den tyvärr ingen chatt. Du hittar alla mottagningar och deras kontaktuppgifter under '

type CareUnit = {
  careAreas: string[] | []
  chat: null | To | (() => void)
  hsaid: string
  id: number
  name: string
  phone: string
  postarea: string
  postcode: string
  region: string
  street: string
  type: "specialist" | "digitalvc" | "vardcentral"
  webAddress: To
  onClick: () => void

  healthcareunitLatitude: string
  healthcareunitLongitude: string
  idExt: string
  isDigitalProvider: boolean
  isDigitalProviderActive: boolean
  isPatientListable: boolean
  isbvc: boolean
  videoCallDelegation: boolean
}

type Props = {
  region: string
  careUnits: CareUnit[]
  onClickRegionSelect: () => void
}

export const SelectCareUnitView = ({
  region,
  careUnits,
  onClickRegionSelect
}: Props) => {
  const [filteredCareUnits, setFilteredCareUnits] = useState<CareUnit[]>(careUnits)

  const { isMobile } = useDeviceSize()

  const handleSearchCareUnitChange = (ev: React.ChangeEvent) => {
    const target = ev.target as HTMLInputElement
    const _filteredCareUnits = []

    for (let i = 0; i < careUnits.length; i++) {
      const careUnit = careUnits[i]
      const careUnitDoesIncludeSearchValue = careUnit.name.toUpperCase().includes(target.value.toUpperCase())
      if (careUnitDoesIncludeSearchValue) _filteredCareUnits.push(careUnit)
    }

    setFilteredCareUnits(_filteredCareUnits)
  }

  const getShownCareUnitsText = () => {
    let text = 'Visar '

    if (filteredCareUnits.length === 0) text = 'Hittade 0 mottagningar'
    else if (filteredCareUnits.length === 1) text += '1 mottagning'
    else text += `alla ${filteredCareUnits.length} mottagningar`

    text += ` i ${capitalizeWords(region)}`
    return text
  }

  return (
    <div className={styles.body}>
      <section className={styles.card}>

        <div className={styles['back-link']} onClick={onClickRegionSelect}>
          <Icon name='nav-arrow-left' /><Typography tag='em' margin={{}}>Välj annan region</Typography>
        </div>

        <Typography tag='h2' margin={{ b: 16 }}>{cardTitle}</Typography>
        <Typography tag='p' display='inline' margin={{}}>{cardText}</Typography>

        <LinkTo className={styles['inline-link-text']} to='#'>
          <Typography tag='p' display='inline' margin={{}}> Hitta Mottagning</Typography>
        </LinkTo>

        <TextField
          labelId='1'
          title='Sök mottagning'
          labelText='Mottagningens namn'
          name='search-unit'
          onChange={handleSearchCareUnitChange}
          margin={{ t: isMobile ? 40 : 32 }}
        />

        <Typography tag='p' margin={{ t: isMobile ? 24 : 32 }}>{getShownCareUnitsText()}</Typography>
        <div className={styles['link-rows-container']}>
          {filteredCareUnits.map(({ id, onClick: handleClick, name, careAreas, postcode, postarea }) => (
            <LinkRow
              key={id}
              onClick={handleClick}
              title={name}
              leftIconName={getCareUnitIconName(careAreas)}
              rightIconName='chevron-right'
              rightIconSize='md'
              track={{
                name: 'select_unit',
                category: 'digital_care',
                action: 'select_care_provider',
              }}
            >
              <Typography tag='p' margin={{}}>{postcode} {postarea}</Typography>
            </LinkRow>
          ))}
        </div>
        <Button
          expand
          variant={isMobile ? 'secondary-light' : 'text'}
          iconLeft='close'
          childMargin={{ l: 8 }}
          margin={isMobile && { t: 24, b: 32 }}
          to="switchboard"
        >
          avbryt
        </Button>
      </section>
    </div>
  )
}
