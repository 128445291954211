import styles from './Accordion.module.scss'
import classNames from 'classnames'
import { Icon } from 'src/new-lib/Icon'

interface Props {
  slim?: boolean
  expand?: boolean
  expandHeight?: boolean
}

export const AccordionSkeleton = ({ slim, expand, expandHeight }: Props) => {
  const skeletonClasses = classNames({
    [styles.skeleton]: true,
    [styles['skeleton-slim']]: slim,
    [styles.expand]: expand,
    [styles['expand-height']]: expandHeight,
  })

  return (
    <div className={skeletonClasses}>
      <div className={styles['skeleton-icon']} />
      <div className={styles['skeleton-header']} />
      <div className={styles['chevron-icon']}><Icon name='chevron-down' size='md' /></div>
    </div>
  )
}
