import { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { connect } from "react-redux";
import { OtpPhoneValidationModal } from './OtpPhoneValidationModal';
import { paths } from 'src/utils/urlPaths';
import { ContentState } from "src/store/reducers/content/contentState";
import { createTryGetContentValueFn } from "src/api/services/ContentAPI";

const blockedPaths: string[] = [
  paths["flow > capio go"],
  paths["switchboard"],
  paths["switchboard > external chat"],
  paths["switchboard > non-integrated unit (obsolete?)"],
  paths["switchboard > other choice?"],
  paths["switchboard > pre-selected unit"],
  paths["switchboard > select region"],
  paths["switchboard > select unit (1)"],
  paths["switchboard > select unit (2)"],
  paths["switchboard > specified unit"],
  paths["switchboard > unit without chat"],
]

type Props = {
  hasValidatedPhone: boolean
  phoneValidationFeatureIsEnabled: boolean
  content: ContentState["global"]
}

const Component = ({ hasValidatedPhone, phoneValidationFeatureIsEnabled, content }: Props) => {
  const [modalIsOpen, setModalIsOpen] = useState(false)
  const [blockedPath, setBlockedPath] = useState("")

  const history = useHistory()
  const tryGetValue = createTryGetContentValueFn(content)

  const handleCloseModal = () => setModalIsOpen(false)
  const handleOtpSuccess = () => {
    handleCloseModal()
    history.push(blockedPath)
  }

  useEffect(() => {
    if (!phoneValidationFeatureIsEnabled) return

    const unblock = history.block((location) => {
      if (blockedPaths.includes(location.pathname) && !hasValidatedPhone) {
        setModalIsOpen(true)
        setBlockedPath(location.pathname)
        return false
      }

      return undefined
    })

    return () => unblock()
  }, [hasValidatedPhone, phoneValidationFeatureIsEnabled, history])


  return <OtpPhoneValidationModal
    title={tryGetValue(c => c.phoneValidationModal.title)}
    infoText={tryGetValue(c => c.phoneValidationModal.infoText)}
    buttonText={tryGetValue(c => c.phoneValidationModal.buttonText)}
    isOpen={modalIsOpen}
    onClose={handleCloseModal}
    onSuccess={handleOtpSuccess}
  />
};

const mapStateToProps = (state: any) => ({
  hasValidatedPhone: state.consent.myInformation.hasValidatedPhone as boolean,
  phoneValidationFeatureIsEnabled: state.featureManager.smsValidation as boolean,
  content: state.content.global as ContentState["global"]
})

export const ValidatePhoneOnBlockedPathsModal = connect(mapStateToProps)(Component)
