import React from 'react'
import styles from './Dropdown.module.scss'
import PropTypes from 'prop-types'
import { Typography } from '../Typography'
import { useDeviceSize, marginToCssProp } from '../_utils'
import { Icon } from '../Icon'
import { FixMeLater } from '../_types'

type Props = {
  title?: string
  errorMessage?: string
  options?: string[]
  margin?: FixMeLater
  onChange: React.ChangeEventHandler<Element>
  value?: string
  defaultValue?: string
  disabled?: boolean
}

export const Dropdown = ({
  title,
  errorMessage,
  options,
  margin,
  onChange,
  value,
  defaultValue,
  disabled
}: Props) => {
  const { isMobile } = useDeviceSize()

  return (
    <div
      className={styles.container}
      style={{ margin: marginToCssProp(margin) }}
    >
      <Typography htmlFor='dropdown' fontSize={isMobile ? 'sm' : 'md'} tag='label'>
        {title}
      </Typography>
      <div className={styles.div}>
        <select
          id='dropdown'
          name='options-value'
          className={errorMessage ? styles['error-container'] : styles.dropdown}
          value={value || ''}
          onChange={onChange}
          disabled={disabled}
        >
          <option selected>{defaultValue}</option>
          {options && options.map((option) => (
            <option key={option} value={option}>
              {option}
            </option>
          ))}
        </select>
        <div className={styles.span}>
          <Icon name='chevron-down' size='md' />
        </div>
      </div>
      <Typography
        variant='labelError'
        align='right'
        tag='label'
      >
        {errorMessage}
      </Typography>
    </div>
  )
}

Dropdown.propTypes = {
  onChange: PropTypes.func.isRequired,
  value: PropTypes.string,
  handleChange: PropTypes.func
}
