import React, { useState, useMemo, useEffect } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { ListingSigningView } from './ListingSigningView'
import {
  getFullNameSelector,
  getCareCentersSelector
} from '../../store/selectors'
import { actions } from '../../store/reducers/consents'
import sha256 from 'crypto-js/sha256'
import Base64 from 'crypto-js/enc-base64'
import { paths } from '../../utils/urlPaths'

const VALIDATION_MESSAGE = {
  STREET: {
    REQ: 'Detta fält är obligatoriskt'
  },
  POSTCODE: {
    REQ: 'Detta fält är obligatoriskt',
    FORMAT: 'Detta fält är obligatoriskt'
  },
  POSTORT: {
    REQ: 'Detta fält är obligatoriskt'
  },
  FORM: {
    FORMAT: 'Detta fält är obligatoriskt'
  },
  LISTING: {
    TERMS: 'Detta fält är obligatoriskt'
  }
}

const _ListingConfirmPage = ({
  fullName,
  personNo,
  initialStreet,
  initialPostcode,
  initialPostort,
  centers,
  dispatchAddressInfo,
  dispatchInitializeSigningProcess,
  selectCareCenterPath = paths['listing > select'],
  match,
  history
}) => {
  //TODO: Continue here - name and region doesnt show
  const hsaId = match.params.hsaId

  const currentDate = new Date()
  const targetDate = new Date(2023, 4, 1)

  const selectedCenter = useMemo(() => {
    if (hsaId === 'none') {
      return { name: '******', region: '******' }
    }

    const center = centers.find((c) => c.HsaId === hsaId)

    return {
      idExt: center?.idExt,
      name: center?.healthcareunit,
      region: center?.healthcareunitRegions,
      hsaId
    }
  }, [hsaId])

  const [street, setStreet] = useState(initialStreet)

  const [streetError, setStreetError] = useState('')
  const onStreetChange = (ev) => {
    const value = ev.target.value || ''
    setStreet(value)

    if (value) {
      setStreetError('')
    } else {
      setStreetError(VALIDATION_MESSAGE.STREET.REQ)
    }
  }

  const [postcode, setPostcode] = useState(initialPostcode || '')
  const [postcodeError, setPostcodeError] = useState('')
  const postcodeIsValid = (value) =>
    /^\d{5}$/.test(value && value.replace(' ', ''))
  const onPostcodeChange = (ev) => {
    const value = ev.target.value || ''
    setPostcode(value)

    if (postcodeIsValid(value)) {
      setPostcodeError('')
    } else if (!value) {
      setPostcodeError(VALIDATION_MESSAGE.POSTCODE.REQ)
    } else {
      setPostcodeError(VALIDATION_MESSAGE.POSTCODE.FORMAT)
    }
  }

  const [postort, setPostort] = useState(initialPostort || '')
  const [postortError, setPostortError] = useState('')
  const onPostortChange = (ev) => {
    const value = ev.target.value || ''
    setPostort(value)

    if (value) {
      setPostortError('')
    } else {
      setPostortError(VALIDATION_MESSAGE.POSTORT.REQ)
    }
  }

  useEffect(() => {
    setStreet(initialStreet)
    setPostcode(initialPostcode)
    setPostort(initialPostort)
  }, [initialStreet, initialPostcode, initialPostort])

  const [termsAccepted, setTermsAccepted] = useState(false)
  const [formError, setFormError] = useState('')

  const onTermsAcceptedChange = (ev) => {
    if (formError === VALIDATION_MESSAGE.LISTING.TERMS) {
      setFormError('')
    }
    setTermsAccepted(ev.target.checked)
  }

  const [submitting, setSubmitting] = useState(false)
  const onSubmit = () => {
    let anyError = false

    const setFieldError = () => {
      setFormError(VALIDATION_MESSAGE.FORM.FORMAT)
      anyError = true
    }

    if (!termsAccepted) {
      setFormError(VALIDATION_MESSAGE.LISTING.TERMS)
      anyError = true
    }
    if (!street) {
      setStreetError(VALIDATION_MESSAGE.STREET.REQ)
      setFieldError()
    }
    if (!postcodeIsValid(postcode)) {
      setPostcodeError(VALIDATION_MESSAGE.POSTCODE.FORMAT)
      setFieldError()
    }
    if (!postcode) {
      setPostcodeError(VALIDATION_MESSAGE.POSTCODE.REQ)
      setFieldError()
    }
    if (!postort) {
      setPostortError(VALIDATION_MESSAGE.POSTORT.REQ)
      setFieldError()
    }
    if (currentDate < targetDate) {
      if (!anyError) {
        setFormError('')
        dispatchAddressInfo({ street, postcode, postort })

        setSubmitting(true)
        dispatchInitializeSigningProcess({
          selectedCenter,
          fullName,
          personNo,
          street,
          postcode,
          postort,
          centers
        }).then(() => setSubmitting(false))
      }
    }
  }

  return (
    <ListingSigningView
      navigateBack={() => history.push(selectCareCenterPath)}
      careCenterName={selectedCenter.name}
      careCenterRegion={selectedCenter.region}
      fullName={fullName}
      street={street}
      streetError={streetError}
      onStreetChange={onStreetChange}
      postcode={postcode}
      postcodeError={postcodeError}
      onPostcodeChange={onPostcodeChange}
      postAddress={postort}
      postAddressError={postortError}
      onPostAddressChange={onPostortChange}
      onTermsAcceptedChange={onTermsAcceptedChange}
      onSubmitButtonClick={onSubmit}
      formError={formError}
      isSubmitting={submitting}
      cancelListing={() => history.push(paths['home'], { replace: true })}
      selectCareCenterPath={selectCareCenterPath}
    />
  )
}

const mapStateToProps = (state) => ({
  fullName: getFullNameSelector(state),
  personNo: state.app.identityInformation.personalNumber,
  initialStreet: state.consent.myInformation.streetAddress,
  initialPostcode: state.consent.myInformation.zipCode,
  initialPostort: state.consent.myInformation.postAddress,
  selectedCenter: state.consent.selectedCenter,
  centers: getCareCentersSelector(state)
})

const mapDispatchToProps = (dispatch) => ({
  dispatchAddressInfo: ({ street, postcode, postort }) =>
    dispatch(
      actions.onMyInfoChangeAll({
        streetAddress: street,
        zipCode: postcode,
        postAddress: postort
      })
    ),
  dispatchInitializeSigningProcess: ({
    selectedCenter,
    fullName,
    personNo,
    street,
    postcode,
    postort
  }) => {
    const hiddenData = {
      isChild: false,
      selectedRegion: selectedCenter.region,
      center: selectedCenter.name,
      centerIdExt: selectedCenter.idExt || '',
      name: fullName,
      personalNumber: personNo,
      mobileNumber: '',
      emailAddress: '',
      streetAddress: street,
      zipCode: postcode,
      region: postort
    }

    const hiddenDataBackend = Object.assign({}, hiddenData)
    const visibleData = `Byt vårdcentral till: ${hiddenData.center}`

    const hash = sha256(hiddenData)
    const base64URLEncodedHash = Base64.stringify(hash)
      .replace(/\+/g, '-')
      .replace(/\//g, '_')
      .replace(/=/g, '')

    return dispatch(
      actions.createListingContract(
        visibleData,
        base64URLEncodedHash,
        hiddenDataBackend
      )
    )
      .then((data) => {
        localStorage.setItem('sign_id', data.id)
        localStorage.setItem('selected_center', selectedCenter)
        localStorage.setItem('selected_center_name', selectedCenter.name)
        window.location = data.redirect
      })
      .catch((err) => {
        console.log(err)
      })
  }
})

export const ListingConfirmPage = withRouter(
  connect(mapStateToProps, mapDispatchToProps)(_ListingConfirmPage)
)
