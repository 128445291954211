import NetworkError from "../NetworkError";
import config from "../config";

export class ListingAPI {
  constructor(http) {
    this._http = http;
    this._endpointSign = `${config.base}/api/v1`;
    //this._endpointSignTemp = `http://localhost:56814/api/v1`;
  }

  async createListingContract(token, payload) {
    const res = await this._http.post(
      `${this._endpointSign}/listing/contract`,
      payload,
      {
        headers: {
          "Content-type": "application/json",
          Authorization: `Bearer ${token}`
        }
      }
    );

    if (res.status !== 201) {
      throw new NetworkError(res.status, res.data);
    }
    return res;
  }

  async signListingContract(token ,payload) {
    let signId = localStorage.getItem("sign_id");
    const url = `${this._endpointSign}/listing/sign/${signId}`;

    const res = await this._http.put(url, payload, {
      headers: {
        "Content-Type": "application/json; charset=utf-8",
        Authorization: `Bearer ${token}`
      }
    });

    if (res.status !== 200) {
      throw new NetworkError(res.status, res.data);
    }
    return res.data;
  }
}
