import React from 'react'
import PropTypes from 'prop-types'
import styles from './ListingReceiptView.module.scss'
import checkBorder from '../../assets/sl-icons/check-border.svg'
import { Typography } from 'src/new-lib/Typography'
import { Button } from 'src/new-lib/Button'
import { useDeviceSize } from '../../new-lib/_utils'

/**
 * @description Listing receipt view
 */

type Props = {
  careUnit: string
  userIsRegistered: boolean
}

export const ListingReceiptView = ({
  careUnit,
  userIsRegistered
}: Props) => {
  const { isMobile } = useDeviceSize()

  return (
    <div className={styles.container}>
      <div className={styles.box}>
        <div className={styles.icon}>
          <img
            className={styles['icon-spin']}
            src={checkBorder}
            alt="check mark"
          />
        </div>
        <section className={styles.content}>
          <Typography margin={{}} align="center" tag="h1">
            Välkommen!
          </Typography>
          <Typography align="center" margin={{ t: '16px' }} tag="p">
            Listningen registreras i din region/landsting inom 2-3 dagar.
          </Typography>
          <Typography
            weight="600"
            margin={{ t: '24px' }}
            align="center"
            tag="p"
          >
            Du kommer listas på:
          </Typography>
          <Typography
            margin={
              isMobile ? { b: '32px', T: '4px' } : { t: '4px', b: '24px' }
            }
            align="center"
            tag="h4"
          >
            {careUnit}
          </Typography>
          {userIsRegistered
            ? <Button expand variant='primary' to="home">Tillbaka till Vård online</Button>
            : (
              <div>
                <Button expand margin={{ b: 18 }} to="home">Till Vård online</Button>
                <Button expand variant='text' to='https://www.capio.se'>Tillbaka till Capio.se</Button>
              </div>
              )}
        </section>
      </div>
    </div>
  )
}

ListingReceiptView.propTypes = {
  careUnit: PropTypes.string.isRequired,
  to: PropTypes.string.isRequired
}
