import React from 'react'
import styles from './WebCtaBlock.module.scss'
import classNames from 'classnames'
import { Typography } from '../Typography'
import { useDeviceSize, marginToCssProp } from '../_utils'
import { Colors, FixMeLater } from '../_types'

type Props = {
  title: string
  bodyText: string
  color?: Colors
  radiusRight?: boolean
  radiusLeft?: boolean
  radiusTop?: boolean
  radiusBottom?: boolean
  wide?: boolean
  margin?: FixMeLater
  dataTrack?: FixMeLater
  children?: React.ReactNode
}

export const WebCtaBlock = ({
  title,
  bodyText,
  color,
  radiusRight,
  radiusLeft,
  radiusTop,
  radiusBottom,
  wide,
  margin,
  dataTrack,
  children
}: Props) => {
  const { isMobile } = useDeviceSize()

  const containerClassNames = classNames({
    [styles.container]: true,
    [styles['container-wide']]: wide,
    [styles['radius-top']]: radiusTop,
    [styles['radius-right']]: radiusRight,
    [styles['radius-bottom']]: radiusBottom,
    [styles['radius-left']]: radiusLeft,
    [styles[color]]: true
  })

  return (
    <div className={containerClassNames} style={{ margin: marginToCssProp(margin) }} data-track={dataTrack}>
      <div>
        <Typography tag={isMobile ? 'h3' : 'h2'} margin={{}}>{title}</Typography>
        <Typography tag='p' margin={{ v: 16 }}>{bodyText}</Typography>
      </div>
      <div className={styles['button-container']}>
        {children}
      </div>
    </div>
  )
}
