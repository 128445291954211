import React from 'react'
import connect from 'react-redux/es/connect/connect'
import { getDisplayName } from '../../store/selectors'
import PropTypes from 'prop-types'
import { actions as consentActions } from '../../store/reducers/consents'
import { actions as accountActions } from "../../store/reducers/accounts";
import { RegistrationFormView } from './RegistrationFormView'
import { paths } from '../../utils/urlPaths'
import { withRouter } from 'react-router-dom'
import { tryExtractCountryCodeFromPhoneString, formatPhoneLegacy } from '../../utils/functions'
import { isValidMobilePhoneNumber, validateEmail, formatPhoneToE164 } from 'src/new-lib/_utils'


class Page extends React.Component {
  constructor(props) {
    super(props)

    const initialPhone = this.props.myInformation.validatedMobileNumber
      ? this.props.myInformation.countryNumber +
      (this.props.myInformation.validatedMobileNumber.startsWith("0")? this.props.myInformation.validatedMobileNumber.substring(1) : this.props.myInformation.validatedMobileNumber)
      : ''

    this.state = {
      popup: false,
      isLoading: false,
      mobileNumber: {
        value: initialPhone,
        errorMessage: ''
      },
      mobileNumberVerification: {
        value: initialPhone,
        errorMessage: ''
      },
      emailAddress: {
        value: this.props.myInformation.emailAddress,
        errorMessage: ''
      },
      displayFormError: false
    }
  }

  componentDidMount() {
    if (this.props.app.userIsRegistered) {
      this.props.history.push(paths['home'])
      return
    }

    this.setState({ isLoading: true })
    const reducerActions = this.props.useService2 ? accountActions : consentActions

    if (this.props.syncWithUserConsents) {
      this.props
        .dispatch(reducerActions.getAllConsentTypes())
        .then((allConsents) => {
          this.props
            .dispatch(reducerActions.getAllUserConsentTypes())
            .then((userConsents) => {
              this.props.dispatch(
                consentActions.syncAcceptedConsents({
                  all: allConsents,
                  user: userConsents
                })
              )
            })
          this.setState({ isLoading: false })
        })
        .catch(() => {
          this.setState({ isLoading: false })
        })
    } else {
      this.props
        .dispatch(reducerActions.getAllConsentTypes(true))
        .then(() => {
          this.setState({ isLoading: false })
        })
        .catch(() => {
          this.setState({ isLoading: false })
        })
    }
  }

  _togglePopup = (text) => {
    const nextState = {
      popup: !this.state.popup
    }

    if (text) {
      // Leave text if none, so old text is not removed before unmount.
      nextState.content = text
    }

    this.setState(nextState)
  }

  setMobileNumber = (ev) => {
    const value = ev.target ? ev.target.value : ''
    this.setState((state) => {
      const { rule, errorMessage } = isValidMobilePhoneNumber

      const countryEncodedValue =
        value && tryExtractCountryCodeFromPhoneString(value)
          ? value
          : `+46${value}`

      const mobileNumber = rule(countryEncodedValue)
        ? { value, errorMessage: '' }
        : { value, errorMessage }

      const verificationValue = state.mobileNumberVerification.value
      const mobileNumberVerification =
        verificationValue === value || !verificationValue
          ? {
              displayFormError: false,
              value: verificationValue,
              errorMessage: ''
            }
          : {
              displayFormError: false,
              value: verificationValue,
              errorMessage: 'Felaktigt mobilnummer'
            }

      return {
        mobileNumber,
        mobileNumberVerification,
        displayFormError: false
      }
    })
  }

  setMobileNumberVerification = (ev) => {
    const value = ev.target ? ev.target.value : ''
    this.setState((state) => {
      return value === state.mobileNumber.value
        ? {
            displayFormError: false,
            mobileNumberVerification: { value, errorMessage: '' }
          }
        : {
            displayFormError: false,
            mobileNumberVerification: {
              value,
              errorMessage: 'Felaktigt mobilnummer'
            }
          }
    })
  }

  setEmail = (ev) => {
    const value = ev.target ? ev.target.value : ''

    this.setState(() => {
      return validateEmail(value)
        ? { displayFormError: false, emailAddress: { value, errorMessage: '' } }
        : {
            displayFormError: false,
            emailAddress: { value, errorMessage: 'Detta fält är obligatoriskt' }
          }
    })
  }

  onNextPageButtonClick = () => {
    const mobileFieldError = this.state.mobileNumber.errorMessage
    const mobileVerFieldError = this.state.mobileNumberVerification.errorMessage
    const emailFieldError = this.state.emailAddress.errorMessage

    const mobileRequiredError = this.state.mobileNumber.value
      ? mobileFieldError
      : 'Du måste ange ett mobilnummer.'

    const mobileVerRequiredError = this.state.mobileNumberVerification.value
      ? mobileVerFieldError
      : 'Du måste verifiera ditt mobilnummer.'

    const emailRequiredError = this.state.emailAddress.value
      ? emailFieldError
      : 'Du måste ange en e-postaddress.'

    const formIsValid =
      !mobileRequiredError &&
      !mobileFieldError &&
      !mobileVerRequiredError &&
      !mobileVerFieldError &&
      !emailRequiredError &&
      !emailFieldError

    const newContactFormState = {
      mobileNumber: {
        ...this.state.mobileNumber,
        errorMessage: mobileRequiredError
      },
      mobileNumberVerification: {
        ...this.state.mobileNumberVerification,
        errorMessage: mobileVerRequiredError
      },
      emailAddress: {
        ...this.state.emailAddress,
        errorMessage: emailRequiredError
      }
    }
    this.setState({ displayFormError: !formIsValid, ...newContactFormState })

    if (formIsValid) {
      const phoneNumber = this.state.mobileNumber.value
      const { countryCode, subscriberNumber } = this.props.useService2 ? formatPhoneToE164(phoneNumber) : formatPhoneLegacy(phoneNumber)

      this.props.dispatch(
        consentActions.onMyInfoChangeAll({
          validatedMobileNumber: subscriberNumber,
          emailAddress: this.state.emailAddress.value,
          countryNumber: countryCode
        })
      )
      this.props.history.push({
        pathname: paths['registration > ToS'],
        search: this.props.location.search
      })
    }
  }

  render() {
    return (
      <RegistrationFormView
        displayName={this.props.displayName}
        phoneField={{
          value: this.state.mobileNumber.value,
          errorText: this.state.mobileNumber.errorMessage,
          onChange: this.setMobileNumber
        }}
        phoneConfirmField={{
          value: this.state.mobileNumberVerification.value,
          onChange: this.setMobileNumberVerification,
          errorText: this.state.mobileNumberVerification.errorMessage
        }}
        emailField={{
          value: this.state.emailAddress.value,
          onChange: this.setEmail,
          errorText: this.state.emailAddress.errorMessage
        }}
        consents={this.props.consentTypes.map((con) => ({
          key: con.consentTypeId,
          checked: con.isAccepted,
          title: con.name,
          paragraphs: [con.description],
          onChange: (ev) =>
            this.props.dispatch(
              consentActions.toggleConsentTypeCheckbox(con.id, ev.target.checked)
            )
        }))}
        onNextPageButtonClick={() => this.onNextPageButtonClick()}
        formIsValid={!this.state.displayFormError}
        isLoading={this.state.isLoading}
      />
    )
  }
}

Page.propTypes = {
  onBackPath: PropTypes.string,
  consentTypes: PropTypes.array.isRequired,
  displayName: PropTypes.string
}

const mapStateToProps = (state) => ({
  app: state.app,
  displayName: getDisplayName(state),
  consentTypes: state.consent.consentTypes,
  myInformation: state.consent.myInformation,
  useService2: state.featureManager.useService2
})

export const RegistrationFormPage = withRouter(connect(mapStateToProps)(Page))
