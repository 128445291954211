import React from "react";
import { array, bool, string } from "prop-types";
import { ActionTopbarDesktop } from "./ActionTopbarDesktop";
import { ActionTopbarMobile } from "./ActionTopbarMobile";
import { useDeviceSize } from "../_hooks";


const ActionTopbar = ({ displayName, actions, logoIsNotLink }) => {

    const { isBiggerThanTablet } = useDeviceSize();

    return isBiggerThanTablet
        ? <ActionTopbarDesktop
            logoIsNotLink={logoIsNotLink}
            dropdownButtonText={displayName || "Mitt konto"}
            actions={actions}
        />
        : <ActionTopbarMobile
            logoIsNotLink={logoIsNotLink}T
            actions={actions}
        />


}

ActionTopbar.prototypes = {
    displayName: string,
    actions: array,
    logoIsNotLink: bool,
}

export { ActionTopbar }
