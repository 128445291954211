import { actions } from "./ducks";
import { featureConfigOfSession } from "./sessionUtils";

export const thunks = {
    tryFetchFeatureConfig: () => async (dispatch, _getState, api) => {
        
        if (!featureConfigOfSession.hasValue()) {
            const config = await api.services.features.fetchFeatureConfig();
            dispatch(actions.setFeatureConfig(config));  
        }
        else {
            dispatch(actions.setFeatureConfig(featureConfigOfSession.get()));
        }
    },
    tryInitializeReloadInterval: () => async (dispatch, getState, api) => {
        const { reloadIntervalId } = getState();
        if (!reloadIntervalId) {
            const twoMinutes = 120000;
            const newIntervalId = window.setInterval(async () => {
                const config = await api.services.features.fetchFeatureConfig();
                dispatch(actions.setFeatureConfig(config)); 
            }, twoMinutes);

            dispatch(actions.setReloadIntervalId(newIntervalId));
        }
    }
}
