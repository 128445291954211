import React from 'react'
import styles from './Reader.module.scss'
import { FixMeLater } from 'src/new-lib/_types'
import { marginToCssProp } from 'src/new-lib/_utils'
import classNames from 'classnames'

type Props = {
  margin?: FixMeLater
  animation?: boolean
  children?: React.ReactNode
  centered?: boolean
  text?: boolean
}

/**
 * En komponent som renderar text med olika stilar och eventuella animationer.
 *
 * @component
 * @param {Props} props - Egenskaper som styr komponentens beteende och utseende.
 */

const TextReader = ({ children, margin, animation, centered, text }: Props) => {
  const linkClassNames = classNames({
    [styles.animation]: animation,
    [styles.text]: text,
    [styles.regular]: !animation && !text
  })

  return (
    <div
      className={centered ? styles.centered : styles.container}
      style={{ margin: marginToCssProp(margin) }}
    >
      <p className={linkClassNames}>{children}</p>
    </div>
  )
}

export default TextReader
