import { TermsOfService } from "."
import MuiDialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import { Text } from '../Text';
import { Checkbox } from "../Checkbox"
import { Button } from "../Button"
import { AlertBox } from "../AlertBox"
import styles from "./TermsOfService.module.scss"
import { useState } from "react";

export const UpdatedTermsOfServiceModalView = ({ termsOfService, tosAcceptance, isOpen, defaultChecked, acceptTos }) => {
    const [accepted, setAccepted] = useState(defaultChecked || false)

    const acceptanceIsLoading = tosAcceptance === "loading" || tosAcceptance === "accepted";

    return (
        <MuiDialog open={isOpen} scroll="body">
            <MuiDialogTitle disableTypography>
                <Text tag="h1" align="center">{copy.title}</Text>
                <Text tag="h3" align="center" style={{whiteSpace: "pre-wrap" }}>{copy.subTitle}</Text>
            </MuiDialogTitle>
            <DialogContent>
                <TermsOfService termsOfService={termsOfService} />
                <div className={styles["accept-form"]}>
                    <Checkbox 
                        defaultChecked={defaultChecked}
                        onChange={(e) => setAccepted(e.target.checked)}
                        label={copy.checkbox}
                        disabled={acceptanceIsLoading}
                    />

                    <AlertBox hidden={tosAcceptance !== "error"} text={copy.acceptFailed} />

                    {/* primary button does not change color when disabled, tertiary is used for disabled state */}
                    <Button
                        tertiary={!accepted}
                        primary={accepted}
                        disabled={!accepted}
                        loading={acceptanceIsLoading}
                        onClick={() => acceptTos()}
                    >{copy.button}</Button>
                </div>

            </DialogContent>
        </MuiDialog>
    )
}
const copy = {
    title: "Uppdaterade tjänstevillkor",
    subTitle: "Vi har uppdaterat våra tjänstevillkor.\nVänligen granska och godkänn.",
    checkbox: "Ja, jag godkänner tjänstevillkoren",
    button: "Spara",
    acceptFailed: "Tyvärr gick något fel och vi kan inte spara. Var god försök igen lite senare."
}