import React from "react";
import PropTypes from "prop-types";
import MuiMenuList from '@material-ui/core/MenuList'

export const MenuList = ({ children, autoFocus, style, fullwidth, horizontal }) => {
    
    const width = fullwidth ? "100%" : "345px";
    
    const _style = horizontal 
    ? { width, ...horizontalStyle, ...style } 
    : { width, ...style }

    return (
        <MuiMenuList variant="menu" style={_style} autoFocus={autoFocus}>
            {children}
        </MuiMenuList>);
}

const horizontalStyle = {
    display: "flex", 
    flexDirection: "row",
    flexWrap: "wrap",
    padding: "0px",
}

MenuList.propTypes = {
    children: PropTypes.array,
    fullwidth: PropTypes.bool,
    autofocusItem: PropTypes.bool,
    horizontal: PropTypes.bool,
}
