import NetworkError from "../NetworkError";
import config from "../config";

export class FeaturesApi {
  constructor(http) {
    this._http = http;
    this._baseUrl = config.featureConfig;
  }

  async fetchFeatureConfig() {
    if (!this._baseUrl) {
      return {}
    }

    try {
      const res = await this._http.get(this._baseUrl);

      if (res.status !== 200) {
        throw new NetworkError(res.status, res.data);
      }

      return res.data["features"].reduce(
        (acc, curr) => ({ 
          ...acc, 
          [curr["key"]]: curr["value"] 
        }), 
        {}
      )
    } catch (e) {
      console.error(e);

      return {}
    }
  }
}
