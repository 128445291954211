import React from 'react';
import MuiDialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import styles from "./Dialog.module.scss";
import { func, string, element, any } from 'prop-types';
import { Text } from '../Text';
import DialogContent from '@material-ui/core/DialogContent';
import { bool } from 'prop-types';
import { Icon } from "../Icon"
import useMediaQuery from "@material-ui/core/useMediaQuery";
import scss from "../_styles/variables.module.scss";
//Does not have storyshots

const basePropTypes = {
    open: bool,
    heading: string,
    body: element,
    actions: element,
    ariaLabelledby: string,
    e2e: string,
};

const DialogBase = ({ 
    open, 
    renderIcon: renderIcon = (size) => <Icon name="info-circle" color="gray" size={size} />, 
    heading, 
    body, 
    actions, 
    ariaLabelledby,
    e2e
}) => {

    const iconSize = useMediaQuery(`(min-width: ${scss.breakpointTablet})`) ? "xxl" : "xl";

    return (
        <MuiDialog scroll="paper"
            classes={{ root: styles.dialog }}
            aria-labelledby={ariaLabelledby}
            open={open}
            PaperProps={{ "data-e2e": e2e}}
            prop
            >
            
            <div style={{ textAlign: "center", paddingTop: "50px" }}>
                {renderIcon(iconSize)}
            </div>

            <MuiDialogTitle disableTypography classes={{ root: styles.title }}>
                <Text tag="h1" align="center" style={{marginTop: "30px", marginBottom: "14px"}}>{heading}</Text>
            </MuiDialogTitle>

            <DialogContent classes={{ root: styles.content }}>
                {body}
            </DialogContent>
            {actions}

            
        </MuiDialog>
    );
}

const InfoDialog = (props) => {
    const { actions } = props;
    const actionsContainer = (
        <div className={styles.actions}>
            {actions}
        </div>
    );
    return (
        <DialogBase {...props} actions={actionsContainer} />
    );
}

InfoDialog.propTypes = {
    ...basePropTypes,
    icon: any
}

export { InfoDialog };