import PropTypes from 'prop-types'
import { Button } from 'src/new-lib/Button'
import { Typography } from 'src/new-lib/Typography'
import styles from './ListingSigningView.module.scss'
import { TextField } from 'src/new-lib/TextField'
import { Icon } from 'src/new-lib/Icon'
import { InfoCheckbox } from 'src/new-lib/Checkbox/InfoCheckbox'
import { useDeviceSize } from 'src/new-lib/_utils'
import { LinkTo } from 'src/new-lib/LinkTo'

/**
 * @description Listing signing in view
 * FocusEventHandler<HTMLInputElement>
 */

type Props = {
  isSubmitting: boolean
  formError: boolean
  onSubmit: Function
  errorMessage: string
  navigateBack: Function
  street: string
  onTermsAcceptedChange: (ev: React.ChangeEvent) => void
  onSubmitButtonClick: Function
  careCenterName: string
  careCenterRegion: string
  fullName: string
  onStreetChange: (ev: React.ChangeEvent) => void
  streetError: string
  postcode: string
  onPostalCodeChange: (ev: React.ChangeEvent) => void
  postcodeError: string
  cancelListing: Function
  onPostAddressChange: (ev: React.ChangeEvent) => void
  postAddressError: string
  postAddress: string
  onPostcodeChange: (ev: React.ChangeEvent) => void
}

export const ListingSigningView = ({
  careCenterName,
  careCenterRegion,
  fullName,
  street,
  onStreetChange,
  streetError,
  postcode,
  onPostcodeChange,
  postcodeError,
  postAddress,
  onPostAddressChange,
  postAddressError,
  onTermsAcceptedChange,
  formError,
  onSubmitButtonClick,
  isSubmitting,
  cancelListing,
}: Props) => {
  const { isMobile, isDesktop } = useDeviceSize()

  return (
    <section className={styles.container}>
      <div
        className={formError ? styles['error-form'] : styles['form-container']}
      >
        <div className={styles['content-container']}>
          <div className={styles.icon}>
            <LinkTo animation to="listing > select">
              <Icon margin={{ r: '16px' }} name="nav-arrow-left" />
              {isMobile && (
                <span className={styles['icon-text']}>Tillbaka</span>
              )}
              {isDesktop && (
                <span className={styles['icon-text']}>
                  Välj annan vårdcentral
                </span>
              )}
            </LinkTo>
          </div>
          <PreviousValues
            careCenterRegion={careCenterRegion}
            careCenterName={careCenterName}
          />
          <form className={styles['info-checkbox']}>
            <TextField
              margin={isDesktop ? { t: '40px' } : { t: '24px' }}
              title="Namn"
              isDisabled
              value={fullName}
            />
            <TextField
              errorMessage={streetError}
              margin={{ t: '16px' }}
              title="Gatuadress"
              labelText="Din Gatuadress"
              value={street}
              isDisabled={isSubmitting}
              isError={formError}
              onBlur={onStreetChange}
            />
            <TextField
              errorMessage={postcodeError}
              margin={{ t: '16px' }}
              title="Postnummer"
              labelText="Ditt postnummer"
              value={postcode}
              onBlur={onPostcodeChange}
              isDisabled={isSubmitting}
              isError={formError}
            />
            <TextField
              errorMessage={postAddressError}
              margin={{ t: '16px' }}
              title="Postort"
              labelText="Din postort"
              value={postAddress}
              onBlur={onPostAddressChange}
              isDisabled={isSubmitting}
              isError={formError}
            />

            <InfoCheckbox
              margin={{ t: '20px' }}
              error={
                formError && 'Du måste godkänna villkoren för att fortsätta'
              }
              onChange={onTermsAcceptedChange}
              buttonCloseLabel="Stäng"
              label="Jag har läst och godkänner villkoren vid byte av vårdcentral"
              modalIcon="completed-notepad"
              modalSize="md"
              modalHeading={listingTermsContent.title}
              disabled={isSubmitting}
              modalText={
                <ul className={styles['listing-terms-list']}>
                  {listingTermsContent.paragraphs.map((p, i) => (
                    <li key={i}>
                      <Typography tag="p" margin={{ v: 8 }}>
                        {p}
                      </Typography>
                    </li>
                  ))}
                </ul>
              }
            />
          </form>
          <ButtonContainerGroup
            onCancelClick={cancelListing}
            onSubmitClick={onSubmitButtonClick}
            isSubmitting={isSubmitting}
          />
        </div>
      </div>
    </section>
  )
}

const ButtonContainerGroup = ({
  onSubmitClick,
  onCancelClick,
  isSubmitting
}) => {
  const { isDesktop } = useDeviceSize()

  return isDesktop ? (
    <div className={styles['button-container']}>
      <Button variant="secondary-dark" onClick={onCancelClick}>
        Avbryt
      </Button>
      <Button
        loading={isSubmitting}
        onClick={onSubmitClick}
        expand
        iconLeft="bank-id-white"
        iconSize="xl"
        variant="primary"
        margin={{ l: '32px' }}
        childMargin={{ l: '8px' }}
        track={{
          name: 'listing_sign',
          category: 'listing',
          action: 'sign_contract',
        }}
      >
        signera
      </Button>
    </div>
  ) : (
    <div className={styles['button-container']}>
      <Button
        loading={isSubmitting}
        onClick={onSubmitClick}
        iconLeft="bank-id-white"
        iconSize="xl"
        expand
        variant="primary"
        margin={{ b: '16px' }}
        childMargin={{ l: '8px' }}
        track={{
          name: 'listing_sign',
          category: 'listing',
          action: 'sign_contract',
        }}
      >
        signera
      </Button>
      <Button expand variant="secondary-dark" onClick={onCancelClick}>
        Avbryt
      </Button>
    </div>
  )
}

type TextProps = {
  careCenterName: string
  careCenterRegion: string
}

const PreviousValues = ({ careCenterName, careCenterRegion }: TextProps) => {
  const { isDesktop } = useDeviceSize()
  return (
    <div>
      <Typography margin={isDesktop ? { t: '32px' } : { t: '24px' }} tag="h2">
        Bekräfta ditt val
      </Typography>

      <div className={styles['user-region-care-unit']}>
        <Typography margin={{ t: '24px' }} tag="h3">
          Region
        </Typography>
        <Typography margin={{ t: '8px' }} tag="span">
          {careCenterRegion}
        </Typography>

        <Typography margin={{ t: '16px' }} tag="h3">
          Vald vårdcentral
        </Typography>
        <Typography margin={{ t: '8px' }} tag="span">
          {careCenterName}
        </Typography>
      </div>
    </div>
  )
}

const listingTermsContent = {
  title: 'Villkor vid byte av vårdcentral',
  paragraphs: [
    'Du kan bara vara listad på en vårdcentral i taget',
    'Om du väljer att lista om dig kommer din tidigare listning tas bort',
    'Om du ångrar din listning kan du lista om dig igen men inte till mottagningar som har full lista',
    'Om du står i kö till att bli listad på annan vårdcentral kommer den köplatsen att tas bort',
    'Enligt Patientlagen har du rätt till vård utan att behöva vara listad'
  ]
}

ListingSigningView.propTypes = {
  street: PropTypes.string.isRequired,
  postcode: PropTypes.string.isRequired,
  postAddress: PropTypes.string.isRequired,
  isSubmitting: PropTypes.bool.isRequired,
  formError: PropTypes.bool.isRequired,
  onSubmitButtonClick: PropTypes.func.isRequired
}
