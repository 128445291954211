import NetworkError from "../../NetworkError";
import config from "../../config";
import type {
  UpdateUserPayload,
  UpdateUserResponse,
  CreateUserPayload,
  GetUserInfoResponse,
  GetLatestTosResponse
} from "./AccountAPIModel"
import { AxiosInstance } from "axios";
import { isAxiosError } from "src/new-lib/_utils"
import { trackAxiosError } from "src/new-lib/_utils/analytics";

export type AccountApi = InstanceType<typeof AccountAPI>


export class AccountAPI {
  private _http: AxiosInstance
  private _endpointV1: string
  private _endpointV2: string
  private _consentV2: string
  private _authHeaders: (token: string) => {
    headers: { Authorization: `Bearer ${string}` }
  }


  constructor(http: AxiosInstance) {
    this._http = http
    this._endpointV2 = `${config.base}/api/v2/account`
    this._endpointV1 =`${config.base}/api/v1/account`
    this._consentV2 = `${config.base}/api/v2/consents`
    
    this._authHeaders = (token: string) => ({ headers: { Authorization: `Bearer ${token}` }})
  }


  async deleteUserInfo(token: string) {
    const res = await this._http.delete(this._endpointV2, this._authHeaders(token));

    if (res.status < 200 || res.status >= 300) {
      throw new NetworkError(res.status, res.statusText, res.data);
    }

    return res.data;
  }


  async getUserInfo(token: string) {
    const res = await this._http.get<GetUserInfoResponse>(this._endpointV2, this._authHeaders(token));

    if (res.status < 200 || res.status >= 300) {
      throw new NetworkError(res.status, res.statusText, res.data);
    }

    return res.data;
  }


  async createUser(payload: CreateUserPayload, token: string) {
    const url = this._endpointV1;
    const res = await this._http.post(url, payload, this._authHeaders(token));

    if (res.status < 200 || res.status >= 300) {
      throw new NetworkError(res.status, res.statusText, res.data);
    }

    return res.data;
  }


  async updateUser(payload: UpdateUserPayload, token: string) {
    const url = this._endpointV2;
    const res = await this._http.put<UpdateUserResponse>(url, payload, this._authHeaders(token));

    if (res.status !== 200) {
      throw new NetworkError(res.status, res.statusText, res.data);
    }

    return res.data;
  }


  async updateConsent(payload, token: string) {
    const url = `${this._consentV2}/grants`;
    const res = await this._http.post(url, payload, this._authHeaders(token));

    if (res.status < 200 || res.status >= 300) {
      throw new NetworkError(res.status, res.statusText, res.data);
    }

    return res.data;
  }

  
  async getAllConsents(token: string) {
    const res = await this._http.get(this._consentV2, this._authHeaders(token));

    if (res.status < 200 || res.status >= 300) {
      throw new NetworkError(res.status, res.data);
    }

    return res.data;
  }


  async getAllUserConsents(token: string) {
    const res = await this._http.get(`${this._consentV2}/grants`, this._authHeaders(token));

    if (res.status < 200 || res.status >= 300) {
      throw new NetworkError(res.status, res.data);
    }

    return res.data;
  }


  async updateSelectedCareUnit(token: string, payload: { id: string }) {
    const { status, data } = await this._http.patch(`${this._endpointV2}/selectedcareunit`, payload, this._authHeaders(token))

    if (status < 200 || status >= 300) throw new NetworkError(status, data)
  }


  async getLatestTos(token: string) {
    try {
      const res = await this._http.get<GetLatestTosResponse>(`${this._endpointV2}/tos`, this._authHeaders(token))      
      return res.data
      
    } catch (error: unknown) {
      if (isAxiosError(error)) {
        trackAxiosError(error)
        return error
      }
      throw error
    }
  }
}
