import { Route, Redirect } from 'react-router-dom';
import { paths } from "../../utils/urlPaths";

// test feature=branch
export const PrivateRoute = ({ component: Component, isAuthenticated, isRegistered = true, errorOccured = false, ...rest }) => (
    <Route
        {...rest}
        render={props => {
            if (errorOccured) {
                return <Redirect to={{ pathname: paths["error"], search: props.location.search, state: { from: props.location } }} />
            }
            if (isAuthenticated) {
                if (isRegistered) {
                    return <Component {...props} />
                } else {
                    return <Redirect to={{ pathname: paths["registration > contact"], search: props.location.search, state: { from: props.location } }} />
                }
            } else {
                return <Redirect to={{ pathname: paths["logout"], search: props.location.search, state: { from: props.location } }} />
            }
        }}
    />

);

PrivateRoute.defaultProps = {
    unauthorizedPath: '/signout',
};
