import styles from "./HomeView.module.scss";
import { HeroHeader } from "../../new-lib/Hero";
import { Typography } from "../../new-lib/Typography";
import { Button } from "../../new-lib/Button";
import { LinkCard } from "../../new-lib/LinkCard";
import { ActionMenuRow } from "../../new-lib/ActionMenuRow";
import { WebCtaBlock } from "../../new-lib/WebCtaBlock";
import { useDeviceSize } from "../../new-lib/_utils";
import imgMomWithChildAtPlayground from "../../assets/images/mom-with-children-at-playground.jpg";
import { To } from "../../new-lib/LinkTo";
import { paths } from "src/utils/urlPaths";
import { ContentState } from "src/store/reducers/content/contentState";
import { createTryGetContentValueFn, type HomeContent } from "src/api/services/ContentAPI"


type Props = {
  displayName: string;
  savedCareCenterData: {
    careUnit: string;
    phoneNumber: string;
    address: string;
    postCode: string;
    city: string;
    to: To;
  };
  protectedId: "J" | "N";
  content: ContentState["home"]
};

export const HomeView = ({
  displayName,
  savedCareCenterData,
  protectedId,
  content
}: Props) => {  
  const { isDesktop, isMobile } = useDeviceSize();

  const tryGetValue = createTryGetContentValueFn(content)

  const PreselectedCareUnit = () => {
    return (
      <section className={styles["preselected-care-unit"]}>
        <div className={styles["pre-care-unit-heading"]}>
          <Typography tag="h2" align="center" margin={{ b: 24 }}>
            {tryGetValue(c => c.sectionTwo.title)}
          </Typography>
          <Typography tag="p" align="center" margin={{ b: 24 }}>
          {tryGetValue(c => c.sectionTwo.infoText)}
          </Typography>
        </div>
        {savedCareCenterData ? (
          <div className={styles["pre-care-unit-body"]}>
            <LinkCard
              to="chat > settings > selected"
              leftIconName="stethoscope"
              rightIconName="nav-arrow-right"
              rightIconSize="md">
              <Typography tag={isMobile ? "h4" : "h3"} margin={{}}>
                {savedCareCenterData.careUnit}
              </Typography>
              {savedCareCenterData.phoneNumber && (
                <Typography tag="p" margin={{ t: 8 }}>
                  {savedCareCenterData.phoneNumber}
                </Typography>
              )}
              <Typography tag="p" fontSize="sm" margin={{ t: 8 }}>
                {savedCareCenterData.address}
              </Typography>
              <Typography tag="p" fontSize="sm" margin={{}}>
                {savedCareCenterData.postCode} {savedCareCenterData.city}
              </Typography>
            </LinkCard>
            <ActionMenuRow
              iconName="medical-phone-app"
              label="starta ärende här"
              color="light-gray"
              radiusBottom
              radiusTop
              to={paths["switchboard"]}
            />
          </div>
        ) : (
          <Button variant="primary" to="chat > settings > selected">
            {tryGetValue(c => c.sectionTwo.ctaButton.text)}
          </Button>
        )}
      </section>
    );
  };

  const ContactHealthReceptions = () => {
    return (
      <section className={styles["contact-health-receptions"]}>
        <div className={styles["contact-content"]}>
          <div className={styles["contact-heading"]}>
            <Typography align="center" tag="h2" margin={{ b: 24 }}>
              {tryGetValue(c => c.sectionThree.title)}
            </Typography>
            <Typography align="center" tag="p" margin={{}}>
              {tryGetValue(c => c.sectionThree.infoText)}
            </Typography>
          </div>
          <div className={styles["contact-body"]}>
            <LinkCard
              leftIconName="stethoscope"
              to="care units"
              rightIconName="nav-arrow-right"
              rightIconSize="md"
            >
              <Typography tag="h3" margin={{}}>
                {tryGetValue(c => c.sectionThree.ctaButton.text)}
              </Typography>
            </LinkCard>
          </div>
        </div>
      </section>
    );
  };

  const CtaBlocks = () => {
    const formCTABlock = (c: HomeContent["sectionFour"]["cardsCollection"][number], index: number) => {
      const itemPosition = index + 1
      const everySecond = itemPosition % 2 === 0
      const everyThird = itemPosition % 3 === 0

      const renderButton = () => {
        if (c.linkUrl)
          return (<Button
            expand={isMobile}
            variant="primary"
            iconRight="share-link"
            childMargin={{ r: 8 }}
            to={c.linkUrl}
            targetTo="_blank"
          >
            {c.linkText}
          </Button>)
        if (c.internalRoute)
          return (<Button
            expand={isMobile}
            variant={everyThird ? "secondary-dark" : "primary"}
            childMargin={{ r: 8 }}
            to={c.internalRoute}
          >
            {c.linkText}
          </Button>)

        return undefined
      }

      if (everySecond)
        return (<WebCtaBlock
          key={c.id}
          title={c.title}
          bodyText={c.infoText}
          wide
          radiusBottom={isMobile}
          radiusTop={isMobile}
          margin={isMobile ? { v: 16 } : { h: 24 }}
        >
          {renderButton()}
        </WebCtaBlock>)

      if (everyThird)
        return (<WebCtaBlock
          key={c.id}
          title={c.title}
          bodyText={c.infoText}
          wide
          radiusRight={isDesktop}
          radiusBottom={isMobile}
          radiusTop={isMobile}
          color="light-salmon"
        >
          {renderButton()}
        </WebCtaBlock>)

      return (
        <WebCtaBlock
          key={c.id}
          title={c.title}
          bodyText={c.infoText}
          wide
          radiusLeft={isDesktop}
          radiusBottom={isMobile}
          radiusTop={isMobile}
        >
          {renderButton()}
        </WebCtaBlock>
      )
    }

    return (
      <section className={styles["cta-block-container"]}>
        <Typography tag="h3" align="center" margin={{ b: 24 }}>
          {tryGetValue(c => c.sectionFour.title)}
        </Typography>
        <div className={styles["cta-block-content"]}>
          {!content.isLoading && content.data?.sectionFour.cardsCollection.map(formCTABlock)}
          
        </div>
      </section>
    );
  };

  const getFirstName = () =>
    protectedId === "N" && displayName ? ` ${displayName.split(" ")[0]}` : "";

  return (
    <>
      <div className={styles.body}>
        <HeroHeader
          title={`${tryGetValue(c => c.sectionOne.title)}${getFirstName()}!`}
          text={tryGetValue(c => c.sectionOne.infoText)}
          image={imgMomWithChildAtPlayground}
          alt="mom with her children at the playground">
          <Button
            variant="primary"
            to={paths["switchboard"]}
            track={{
              name: "seek_care_cta2",
              category: "digital_care",
              action: "to_digital_care",
            }}>
            {tryGetValue(c => c.sectionOne.ctaButton.text)}
          </Button>
        </HeroHeader>
        <PreselectedCareUnit />
        <ContactHealthReceptions />
        <CtaBlocks />
      </div>
    </>
  );
};
