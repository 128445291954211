import { Checkbox } from '../Checkbox'
import { Icon } from '../Icon'
import { useDeviceSize } from '../_utils'
import SkeletonLoader from './SkeletonLoader'
import styles from './SkeletonLoader.module.scss'

export const CheckboxSkeleton = () => {
  const { isMobile } = useDeviceSize()

  return (
    <div className={styles.checkbox}>
      <Checkbox dummy={true} disabled />
      <div className={styles.loaders}>
        <SkeletonLoader width="100%" height={isMobile ? '24px' : '16px'} />
        <div className={styles['second-loader']}>
          <SkeletonLoader width="50px" height={isMobile ? '24px' : '16px'} />
        </div>
      </div>
    </div>
  )
}
