export const MY_INFO_CHANGE = 'MY_INFO_CHANGE';
export const MY_INFO_CHANGE_SPAR = 'MY_INFO_CHANGE_SPAR';
export const MY_INFO_CHANGE_ALL = 'MY_INFO_CHANGE_ALL';
export const MY_CHILD_INFO_CHANGE = 'MY_CHILD_INFO_CHANGE';
export const SELECT_CENTER = 'SELECT_CENTER';
export const SYNC_SELECTED_CONSENTS = 'SYNC_SELECTED_CONSENTS';
export const TOGGLE_CONSENT_CHECKBOX = 'TOGGLE_CONSENT_CHECKBOX';
export const TOGGLE_CONSENT_TYPE_CHECKBOX = 'TOGGLE_CONSENT_TYPE_CHECKBOX';
export const STORE_ALL_CONSENT_TYPES = 'STORE_ALL_CONSENT_TYPES';
export const SAVE_ALL_CONSENT_TYPES = 'SAVE_ALL_CONSENT_TYPES';
export const STORE_SPAR_INFORMATION = 'STORE_SPAR_INFORMATION';
export const STORE_SPAR_LOADING = 'STORE_SPAR_LOADING';
export const ACCEPT_TOS = 'ACCEPT_TOS';
export const ACCEPT_TOS_RESPONSE = 'ACCEPT_TOS_RESPONSE';
export const ACCEPT_TOS_RESPONSE_ERROR = 'ACCEPT_TOS_RESPONSE_ERROR';
export const UPDATE_CONTACT_INFORMATION = 'UPDATE_CONTACT_INFORMATION';
export const UPDATE_ASSOCIATED_CARE_GIVERS_INFO = 'UPDATE_ASSOCIATED_CARE_GIVERS_INFO';
export const SELECTE_SWITCHBOARD_REGION = 'SELECTE_SWITCHBOARD_REGION';
export const SELECT_SWITCHBOARD_HCU = 'SELECT_SWITCHBOARD_HCU';
export const UPDATE_ASSOCIATED_CAREGIVERS = 'UPDATE_ASSOCIATED_CAREGIVERS';
export const WAS_ROUTED_BY_SETTINGS = 'WAS_ROUTED_BY_SETTINGS';
export const UPDATE_CONTACT_INFORMATION_RESPONSE =
  'UPDATE_CONTACT_INFORMATION_RESPONSE';
export const UPDATE_CONTACT_INFORMATION_RESPONSE_ERROR =
  'UPDATE_CONTACT_INFORMATION_RESPONSE_ERROR';
