import { createContext } from "react";
import { connect } from "react-redux";
import { arrayOf, element, object, oneOf, oneOfType } from "prop-types";
import { defaultFeatureConfig } from "../store/reducers/featureManager";

const FeatureContext = createContext();
export const FeatureProvider = FeatureContext.Provider;

export const featureManager = {
    withConfig: (cfg) => ({
        flags: (flagNames) => ({
            whenEnabled: (enabledHandler) => ({
                exec: () => {
                    if (typeof flagNames === "string" && cfg[flagNames] === true) {
                        return enabledHandler();
                    }
                    if (Array.isArray(flagNames) && flagNames.length && flagNames.every(f => cfg[f] === true)) {
                        return enabledHandler();
                    }
                },
                whenDisabled: (disabledHandler) => ({
                    exec: () => {

                        if (typeof flagNames === "string" && cfg[flagNames] === true) {
                            return enabledHandler();
                        }
                        if (Array.isArray(flagNames) && flagNames.length && flagNames.every(f => cfg[f] === true)) {
                            return enabledHandler();
                        }
                        if (typeof flagNames === "string" && cfg[flagNames] === false) {
                            return disabledHandler();
                        }
                        if (Array.isArray(flagNames) && flagNames.length && flagNames.every(f => cfg[f] === false)) {
                            return disabledHandler();
                        }
                    }
                })
            })
        })
    })
}


const _Feature = ({ enabled = [], disabled = [], config, children }) => {
    
    if ((enabled.length && disabled.length) || (typeof enabled === "string" && typeof disabled === "string")) {
        throw new Error("An instance of the Feature component was supplied both enabled and disabled flags." +
            `\nenabled: ${enabled}\ndisabled: ${disabled}`);
    }
    if (typeof enabled === "string") {
        return config[enabled] ? children : null;
    }
    if (typeof disabled === "string") {
        return !config[disabled] ? children : null;
    }
    if (enabled.length) {
        return enabled.every(componentFlag => config[componentFlag])
            ? children
            : null;
    }
    if (disabled.length) {
        return disabled.every(componentFlag => config[componentFlag] === false)
            ? children
            : null;
    }

    return null;


}

const mapStateToProps = state => ({
    config: state.featureManager,
});



export const Feature = connect(mapStateToProps, null)(_Feature);

const featureKeys = Object.keys(defaultFeatureConfig);

Feature.propTypes = {
    enabled: oneOfType([oneOf(featureKeys), arrayOf(oneOf(featureKeys))]),
    disabled: oneOfType([oneOf(featureKeys), arrayOf(oneOf(featureKeys))]),
    config: object,
    children: element,
}
