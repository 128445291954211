import { RECEIVE_CENTERS, FETCH_CENTERS, RECEIVE_DIGITAL_CENTERS } from "./action-types";
import initialState from "./initialState";

export const actions = {
  fetchDigitalProviderCenters() {
    return (dispatch, _, api) => {
      dispatch({ type: FETCH_CENTERS });

      return api.services.center.getAllDigitalProviders().then(centers => {
        dispatch({
          type: RECEIVE_DIGITAL_CENTERS,
          payload: centers
        });
        return Promise.resolve();
      });
    };
  },
};

export const reducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_CENTERS:
      return { ...state, loading: false };

    case RECEIVE_CENTERS:
      return { ...state, centers: action.payload };

    case RECEIVE_DIGITAL_CENTERS:
      return { ...state, digitalCenters: action.payload };

    default:
      return state;
  }
};
