import React, { useState, useEffect, MouseEventHandler } from 'react'
import styles from './FindCareUnitView.module.scss'
import { HeroHeader } from '../../new-lib/Hero'
import { Accordion } from 'src/new-lib/Accordion'
import { AccordionSkeleton } from 'src/new-lib/Accordion'
import { Dropdown } from '../../new-lib/Dropdown'
import { Typography } from '../../new-lib/Typography'
import { Button } from '../../new-lib/Button'
import { useDeviceSize, getCareUnitIconName } from '../../new-lib/_utils'
import { RenderIfVisible } from 'src/new-lib/RenderIfVisible'
import { To } from 'src/new-lib/LinkTo'
import { FixMeLater } from 'src/new-lib/_types'

const heroHeaderTitle = 'Hitta mottagning'
const heroHeaderText = 'Behöver du komma i kontakt med någon av Capios mottagningar? Här hittar du alla Capios mottagningar, var de ligger och hur du kan nå dem.'

type CareAreasAndRegions = {
  selected: string
  items: string[]
  onSelect: (event: React.ChangeEvent<HTMLSelectElement>) => void
}

export type CareUnit = {
  careAreas: string[] | []
  chat: null | To | (() => void)
  hsaid: string
  id: number
  name: string
  phone: string
  postarea: string
  postcode: string
  region: string
  street: string
  type: "specialist" | "digitalvc" | "vardcentral"
  webAddress: To
}

type Props = {
  careAreas: CareAreasAndRegions
  regions: CareAreasAndRegions
  careUnits : CareUnit[]
}

export const FindCareUnitView = ({
  careAreas,
  regions,
  careUnits
}: Props) => {
  const { isDesktop, isMobile } = useDeviceSize()

  const handleCareAreasChange = careAreas.onSelect
  const handleRegionsChange = regions.onSelect

  const [selectedCareAreas, setSelectedCareAreas] = useState<CareUnit[]>()
  const [selectedRegions, setSelectedRegions] = useState<CareUnit[]>()
  const [filteredCareUnits, setFilteredCareUnits] = useState<CareUnit[]>(careUnits)

  const filterCareUnitsByParams = (
    _careUnits: FixMeLater,
    filterKey: FixMeLater,
    filterValue: FixMeLater
  ) => {
    if (!filterValue || filterValue.includes('Välj')) return _careUnits

    const foundCareUnits = []

    for (let i = 0; i < _careUnits.length; i++) {
      const careUnit = _careUnits[i]
      const careUnitDoesMatchParam = careUnit[filterKey].includes(filterValue)
      if (careUnitDoesMatchParam) foundCareUnits.push(careUnit)
    }

    return foundCareUnits
  }

  useEffect(() => {
    if (careAreas.selected) setSelectedCareAreas(filterCareUnitsByParams(careUnits, 'careAreas', careAreas.selected))
  }, [careAreas.selected])

  useEffect(() => {
    if (selectedCareAreas) setFilteredCareUnits(filterCareUnitsByParams(selectedCareAreas, 'region', regions.selected))
  }, [selectedCareAreas])

  useEffect(() => {
    if (regions.selected) setSelectedRegions(filterCareUnitsByParams(careUnits, 'region', regions.selected))
  }, [regions.selected])

  useEffect(() => {
    if (selectedRegions) setFilteredCareUnits(filterCareUnitsByParams(selectedRegions, 'careAreas', careAreas.selected))
  }, [selectedRegions])

  const [selectedAccordion, setSelectedAccordion] = useState(null)
  const handleClick = (id: string) => setSelectedAccordion(selectedAccordion === id ? null : id)

  const renderAccordion = (unit: CareUnit) => {
    return (
      <Accordion
        id={unit.id.toString()}
        selectedAccordion={selectedAccordion}
        onClick={handleClick}
        iconName={getCareUnitIconName(unit.careAreas)}
        headerContent={
          <>
            <Typography tag={isMobile ? 'h4' : 'h3'} margin={{ b: 8 }}>{unit.name}</Typography>
            { unit.name.toUpperCase() === 'CAPIO GO'
                ? <Typography tag='p' margin={{}}>Vår heldigitala mottagning. Öppet alla dagar 07-22</Typography>
                : <>
                    <Typography tag='p' margin={{}}>{unit.street}</Typography>
                    <Typography tag='p' margin={{}}>{`${unit.postcode} ${unit.postarea}`}</Typography>
                  </> }
          </>
        }
      >
        <div className={styles['accordion-body-content']}>
          {unit.phone &&
            <div className={styles['accordion-phone-container']}>
              <Typography tag='p' margin={{}}>Telefon:</Typography>
              <a href={`tel:${unit.phone}`} className={styles.phone}>
                <Typography tag='p' weight='700' margin={{}}>
                  {unit.phone}
                </Typography>
              </a>
            </div>}
          {unit.webAddress && (
            <Button
              expand
              variant='secondary-dark'
              to={unit.webAddress}
              targetTo='_blank'
              iconRight='share-link'
              childMargin={{ r: 8 }}
              margin={{ b: 16 }}
            >
              Besök hemsida
            </Button>
          )}
          {renderChatButton(unit)}
        </div>
      </Accordion>
    )
  }

  const renderChatButton = (unit: CareUnit) => {
    if (!unit.chat) return null
    if (typeof unit.chat === "function") return <Button expand variant='primary' onClick={unit.chat}>starta chatt</Button>
    
    const chatTo = unit.chat
    return <Button expand variant='primary' to={chatTo}>starta chatt</Button>
  }

  return (
    <>
      <HeroHeader title={heroHeaderTitle} text={heroHeaderText} />
      <div className={styles.body}>
        <section className={styles['filter-container']}>
          <div className={styles['filter-content']}>
            <Typography tag='h2' margin={isDesktop ? { b: 8 } : {}}>Filtrera</Typography>
            <Dropdown
              defaultValue='Välj typ av vård'
              options={careAreas.items}
              onChange={handleCareAreasChange}
              value={careAreas.selected}
            />
            <Dropdown
              defaultValue='Välj region'
              options={regions.items}
              onChange={handleRegionsChange}
              value={regions.selected}
            />
          </div>
        </section>
        <Typography tag='p'>Visar {filteredCareUnits.length || 0} mottagningar</Typography>
        {filteredCareUnits.map((unit, i) => (
          <RenderIfVisible key={i} placeholder={<AccordionSkeleton expandHeight />}>
            {renderAccordion(unit)}
          </RenderIfVisible>
        ))}
      </div>
    </>
  )
}
