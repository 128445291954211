import React from 'react'
import { Footer } from 'src/new-lib/Footer'
import { Header } from 'src/new-lib/Header'
import { paths } from '../../../utils/urlPaths'
import styles from './LoggedInLayoutScreen.module.scss'
import { RootState } from 'src/store/Model'

type Props = {
  menuButtonText: string
  children: React.ReactNode
  content: RootState['content']['topbar']
}

export const LoggedInLayoutScreen = ({
  menuButtonText,
  content,
  children
}: Props) => {
  return (
    <div className={styles.root}>
      <Header menuButtonText={menuButtonText} content={content} isLoggedIn />
      <div>{children}</div>
      <Footer signedIn sections={createFooterContent()} />
    </div>
  )
}

const createFooterContent = () => [
  {
    header: 'Tjänster',
    links: [
      {
        display: 'Sök vård digitalt',
        to: paths['switchboard'],
        track: {
          name: 'seek_care_cta',
          category: 'home',
          action: 'to_home',
          pageContext: 'top_nav'
        }
      },
      {
        display: 'Lista på Capio',
        to: paths['listing > select'],
        track: {
          name: 'listing_dropdown_link',
          category: 'listing',
          action: 'to_listing',
          pageContext: 'top_nav_dropdown'
        }
      },
      {
        display: 'Hitta en mottagning',
        to: paths['care units'],
        track: {
          name: 'topbar_menu_link',
          category: 'digital_care',
          action: 'to_digital_care',
          pageContext: 'top_nav_dropdown'
        }
      },
      {
        display: 'Uppdatera kontaktuppgifter',
        to: paths['my profile'],
        track: {
          category: 'my_profile',
          action: 'to_my_profile',
          pageContext: 'top_nav_dropdown'
        }
      }
    ]
  },
  {
    header: 'Om Capio',
    links: [
      {
        display: 'Så arbetar vi med kvalitet',
        to: paths['how we work with QA']
      },
      {
        display: 'Tillgänglighetsredogörelse',
        to: paths['accessability account']
      },
      {
        display: 'Hantera cookies',
        to: paths['cookies']
      },
      {
        display: 'Nätintegritetspolicy',
        to: paths['webIntegrity']
      }
    ]
  },
  {
    header: 'Kontakt',
    links: [
      {
        display: 'Vanliga frågor om digital vård',
        to: paths['faq'],
        track: {
          name: 'topbar_menu_link',
          category: 'awareness',
          action: 'to_faq',
          pageContext: 'top_nav_dropdown'
        }
      }
    ]
  },
  {
    header: 'Följ oss i sociala medier',
    links: [
      {
        display: 'Instagram',
        to: paths['instagram']
      },
      {
        display: 'Facebook',
        to: paths['facebook']
      },
      {
        display: 'LinkedIn',
        to: paths['linkedin']
      }
    ]
  }
]
