import { useState, useEffect, useRef } from "react"
import scss from "./InputOTP.module.scss"
import classNames from "classnames"

type InputSelection = {
  start: number | null
  end: number | null
  direction: "none" | "forward" | "backward" | null
}

const defaultSelection: InputSelection = {
  start: null,
  end: null,
  direction: null
}


type Props = {
  maxLength: number 
  inputMode?: "text" | "numeric"
  onChange?: (value: string) => void
  onComplete?: (value: string) => void
  autoFocus?: boolean
  regexPattern?: RegExp
}

export const InputOTP = ({
  maxLength,
  inputMode = "numeric",
  onChange,
  onComplete,
  autoFocus = true,
  regexPattern = /^[0-9]+$/
}: Props) => {
  const [value, setValue] = useState("")
  const [inputSelection, setInputSelection] = useState<InputSelection>(defaultSelection)

  const previousInputSelection = useRef<InputSelection>(defaultSelection)
  const inputRef = useRef<HTMLInputElement>(null)


  const handleSelectionChange = () => {
    const input = inputRef.current
    const previousStart = previousInputSelection.current.start
    const previousEnd = previousInputSelection.current.end

    if (document.activeElement !== input || !input || previousStart === null || previousEnd === null) {
      setInputSelection(defaultSelection)
      return
    }

    
    const currentSelection = {
      start: input.selectionStart,
      end: input.selectionEnd,
      direction: input.selectionDirection
    }

    if (!input.value || input.selectionStart === null || input.selectionEnd === null) {      
      setInputSelection(currentSelection)
      previousInputSelection.current = currentSelection
      return
    }

    const isSelectingText = input.selectionStart !== input.selectionEnd
    const isInsertMode = input.selectionStart === input.value.length && input.value.length < maxLength

    if (isSelectingText || isInsertMode) {
      setInputSelection(currentSelection)
      previousInputSelection.current = currentSelection
      return
    }


    const updateSelections = (selection: InputSelection) => {
      input.setSelectionRange(selection.start, selection.end, selection.direction ?? undefined)
      setInputSelection(selection)
      previousInputSelection.current = selection
    }
    
    const caretPosition = input.selectionStart
    if (caretPosition === 0) {
      updateSelections({ start: 0, end: 1, direction: "forward" })
      return
    }
    
    if (caretPosition === maxLength) {
      updateSelections({ start: caretPosition - 1, end: caretPosition, direction: "backward" })
      return
    }

    
    const direction = caretPosition < previousEnd ? 'backward' : 'forward'
    const wasInserting = previousStart === previousEnd && previousStart < maxLength

    if (direction === "forward" || wasInserting) {
      updateSelections({ start: caretPosition, end: caretPosition + 1, direction })
      return
    }

    updateSelections({ start: caretPosition - 1, end: caretPosition, direction })
  }


  useEffect(() => {
    if (!inputRef.current) return

    previousInputSelection.current = {
      start: inputRef.current.selectionStart,
      end: inputRef.current.selectionEnd,
      direction: inputRef.current.selectionDirection ?? "none"
    }

    handleSelectionChange()
    document.addEventListener('selectionchange', handleSelectionChange, { capture: true })

    return () => {
      document.removeEventListener("selectionchange", handleSelectionChange, { capture: true })
    }
  }, [])


  const handleChange = (ev: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = ev.currentTarget.value.slice(0, maxLength)
    if (newValue.length > 0 && !regexPattern.test(newValue)) {
      ev.preventDefault()
      return
    }
    
    // cutting text doesn't trigger the selectionchange event
    const hasCutText = newValue.length < value.length
    if (hasCutText) handleSelectionChange()
    
    if (onChange) onChange(newValue)
    if (onComplete && newValue.length === maxLength) onComplete(newValue)

    setValue(newValue)
  }


  const slotIsActive = (slotIndex: number) => {
    const start = inputSelection.start
    const end = inputSelection.end

    if (start === end && slotIndex === start) return true
    if (slotIndex >= start && slotIndex < end) return true

    return false
  }

  const inputClasses = classNames({ [scss["input-field"]]: true })
  const containerClasses = classNames({ [scss["container"]]: true })
  const slotContainerClasses = classNames({ [scss["slots-container"]]: true })
  const caretClasses = classNames({ [scss["caret"]]: true })


  return (
    <div className={containerClasses}>
      <div className={slotContainerClasses}>
        {Array.from({ length: maxLength }).map((_, index) => {
          const isActive = slotIsActive(index)
          const char = value[index]
          const shouldShowCaret = isActive && !char

          const slotClasses = classNames({
            [scss["slot"]]: true,
            [scss["slot-active"]]: isActive
          })
          
          return (
            <div className={slotClasses} key={index}>
              {char}
              {shouldShowCaret && <div className={caretClasses} />}
            </div>
          )
        })}
      </div>
      <input
        title="one-time-code"
        autoComplete="one-time-code"
        autoFocus={autoFocus}
        pattern={regexPattern.source}
        inputMode={inputMode}
        maxLength={maxLength}
        type="text"
        value={value}
        ref={inputRef}
        onChange={handleChange}
        className={inputClasses}
      />
    </div>
  )
}
