import React, { useState } from 'react'
import styles from './TextField.module.scss'
import classNames from 'classnames'
import { Typography } from '../Typography'
import PropTypes from 'prop-types'
import { marginToCssProp } from '../_utils'
import { FixMeLater } from '../_types'

type Props = {
  labelId?: string
  title?: string
  margin?: FixMeLater
  name?: string
  labelText?: string
  value?: string
  isDisabled?: boolean
  isError?: boolean
  errorMessage?: string
  onChange?: React.ChangeEventHandler<HTMLInputElement>
  forwardedRef?: React.ForwardedRef<FixMeLater>
  onBlur?: React.FocusEventHandler<HTMLInputElement>
}

export const TextField = ({
  labelId,
  title,
  margin,
  name = title,
  labelText,
  value,
  isDisabled,
  isError,
  errorMessage,
  onChange,
  forwardedRef,
  onBlur
}: Props) => {
  const textFieldClass = classNames({
    [styles['text-field']]: true,
    [styles['text-field-disabled']]: isDisabled,
    [styles['text-field-error']]: isError
  })

  const [inputValue, setInputValue] = useState(value)

  const handleChange: React.ChangeEventHandler<HTMLInputElement> = (ev) => {
    setInputValue(ev.target.value)
    if (onChange) onChange(ev)
  }

  return (
    <div style={{ margin: marginToCssProp(margin) }}>
      <Typography tag='label' htmlFor={labelId} fontSize='sm'>{title}</Typography>
      <input
        id={labelId}
        className={textFieldClass}
        type='text'
        name={name}
        placeholder={labelText}
        value={inputValue}
        disabled={isDisabled}
        onChange={handleChange}
        onBlur={onBlur}
        ref={forwardedRef}
      />
      {isError &&
        <Typography tag='p' variant='inputError' fontSize='sm' align='right' margin={{ t: 4 }}>
          {errorMessage}
        </Typography>}
    </div>
  )
}
