

import { RootState } from "src/store/Model"
import { CmsApi } from "src/api/services/ContentAPI"
import { AxiosError } from "axios"
import { ContentDispatcher } from "./reducer"
import { isError } from "src/new-lib/_utils"

type ApiService = { services: { content: CmsApi } }

export const actions = {
    fetchFaq: () =>
        async (dispatch: ContentDispatcher, getState: () => RootState, api: ApiService) => {
            const content = getState().content.faq
            if (content.data || content.isLoading)
                return

            dispatch({ type: "fetch faq content" })
            const payload = await api.services.content.getFaqPageContent()

            if (isError(payload))
                dispatch({ type: "fetch flow onboarding start content failed", payload })
            else
                dispatch({ type: "fetch faq content succeeded", payload })
        },

    fetchHome: () =>
        async (dispatch: ContentDispatcher, getState: () => RootState, api: ApiService) => {
            const homeContent = getState().content.home
            if (homeContent.isLoading || homeContent.data)
                return

            dispatch({ type: "fetch home content" })
            const payload = await api.services.content.getHomePageContent()


            if (isError(payload))
                dispatch({ type: "fetch home content failed", payload })
            else
                dispatch({ type: "fetch home content succeeded", payload })

        },

    fetchFlowOnboardingStart: () =>
        async (dispatch: ContentDispatcher, getState: () => RootState, api: ApiService) => {
            const content = getState().content.flowOnboardingStart

            if (content.isLoading || content.data)
                return

            dispatch({ type: "fetch flow onboarding start content" })

            const payload = await api.services.content.getFlowOnboardingStartPageContent()


            if (isError(payload))
                dispatch({ type: "fetch flow onboarding start content failed", payload })
            else
                dispatch({ type: "fetch flow onboarding start content succeeded", payload })
        },
        
    fetchTopbar: () =>
        async (dispatch: ContentDispatcher, getState: () => RootState, api: ApiService) => {    
            const content = getState().content.topbar

            if (content.isLoading || content.data) return

            dispatch({ type: "fetch topbar content" })

            const payload = await api.services.content.getTopbarContent()
            

            if (isError(payload))
                dispatch({ type: "fetch topbar content failed", payload })
            else
                dispatch({ type: "fetch topbar content succeeded", payload })
        },

    fetchProfile: () =>
        async (dispatch: ContentDispatcher, getState: () => RootState, api: ApiService) => {
            const profileContent = getState().content.profile
            if (profileContent.isLoading || profileContent.data) return

            dispatch({ type: "fetch profile content" })
            const payload = await api.services.content.getProfilePageContent()

            if (isError(payload))
                dispatch({ type: "fetch profile content failed", payload })
            else
                dispatch({ type: "fetch profile content succeeded", payload })
        },
    
    fetchGlobal: () =>
        async (dispatch: ContentDispatcher, getState: () => RootState, api: ApiService) => {
            const globalContent = getState().content.global
            if (globalContent.isLoading || globalContent.data) return

            dispatch({ type: "fetch global content" })
            const payload = await api.services.content.getGlobalContent()

            if (isError(payload))
                dispatch({ type: "fetch global content failed", payload })
            else
                dispatch({ type: "fetch global content succeeded", payload })
        }
}
