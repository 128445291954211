import queryString from 'query-string'

const getValueOrDefault = (key) => (window._env_ && window._env_[key]) || ''

export default {
  base: getValueOrDefault('SERVICE_URL'),
  baseAuth: getValueOrDefault('AUTH_URL'),
  redirectBase: getValueOrDefault('AUTH_REDIRECT_URL'),
  loginOverride: `${getValueOrDefault('LOGIN_OVERRIDE_URL')}${queryString.parse(
    window.location.search
  ).service || ''}`,
  postLogoutURI: getValueOrDefault('POST_LOGOUT_URL'),
  capioGoOrigin: getValueOrDefault('DOCTRIN_BASE_URL'),
  capioGo: getValueOrDefault('DOCTRIN_FLOW_URL'),
  capioGoLogout: getValueOrDefault('DOCTRIN_LOGOUT_URL') || '',
  flow: getValueOrDefault('FLOW_SSO_URL'),
  featureConfig: getValueOrDefault('FEATURE_CONFIG_URL'),
  piwikId: getValueOrDefault('PIWIK_ID'),
  piwikDimension: {
    event: {
      viewport: getValueOrDefault('PIWIK_VIEWPORT_DIMENSION_ID'),
      option: getValueOrDefault('PIWIK_OPTION_DIMENSION_ID'),
      content: getValueOrDefault('PIWIK_CONTENT_DIMENSION_ID'),
      pageContext: getValueOrDefault('PIWIK_PAGE_CONTEXT_DIMENSION_ID'),
      message: getValueOrDefault('PIWIK_ERROR_MESSAGE_DIMENSION'),
      errorName: getValueOrDefault('PIWIK_ERROR_NAME_DIMENSION'),
      stackTrace: getValueOrDefault('PIWIK_STACK_TRACE_DIMENSION')
    },
    session: {
      gender: getValueOrDefault('PIWIK_GENDER_DIMENSION_ID')
    }
  },
  idleTimer: getValueOrDefault('IDLE_TIMER'),
  environment: getValueOrDefault('ENVIRONMENT'),
  cmsBaseUri: getValueOrDefault("CMS_BASE_URI")
}
