import React from 'react'
import { Typography } from '../../new-lib/Typography'
import { TextField } from '../../new-lib/TextField/TextField'
import { InfoCheckbox } from '../../new-lib/Checkbox'
import { Button } from '../../new-lib/Button'
import { useDeviceSize } from '../../new-lib/_utils'
import styles from './RegistrationFormView.module.scss'
import { FixMeLater } from 'src/new-lib/_types'
import { CheckboxSkeleton } from 'src/new-lib/Skeleton/CheckboxSkeleton'
import SkeletonLoader from 'src/new-lib/Skeleton/SkeletonLoader'

const formBodyText = `Eftersom det är första gången du loggar in behöver vi veta dina
  kontaktuppgifter och samtycken för hur vi ska kontakta dig:`

type FieldType = {
  value: string
  errorText: string | null | undefined
  onChange: (ev: React.ChangeEvent) => void
}

type ConsentType = {
  key: FixMeLater
  checked: boolean
  title: string
  paragraphs: string[]
  onChange?: (ev: React.ChangeEvent) => void
}

type Props = {
  phoneField: FieldType
  phoneConfirmField: FieldType
  emailField: FieldType
  consents: ConsentType[]
  onNextPageButtonClick: () => void
  isLoading: boolean
}

export const RegistrationFormView = ({
  phoneField,
  phoneConfirmField,
  emailField,
  consents,
  onNextPageButtonClick,
  isLoading
}: Props) => {
  const { isMobile } = useDeviceSize()

  const phoneFieldTitle = 'Mobilnummer'
  const phoneFieldLabel = `Ditt ${phoneFieldTitle}`
  const phoneConfirmNumberTitle = `Bekräfta ${phoneFieldTitle.toLowerCase()}`
  const phoneConfirmNumberLabel = `Bekräfta ${phoneFieldLabel.toLowerCase()}`
  const cancelButtonVariant = isMobile ? 'secondary-light' : 'text'

  const handlePhoneChange = phoneField.onChange
  const handlePhoneConfirmChange = phoneConfirmField.onChange
  const handleEmailChange = emailField.onChange

  const handleOnNextPageClick = (event: React.MouseEvent) => {
    event.preventDefault()
    onNextPageButtonClick()
  }

  return (
    <>
      <div className={styles.background}>
        <div className={styles['form-container']}>
          <form className={styles['reg-form']}>
            <Typography tag="h1" margin={{}}>
              Välkommen!
            </Typography>
            <Typography tag="p" margin={{ t: 16, b: 32 }}>
              {formBodyText}
            </Typography>
            {isLoading ? (
              <>
                <div>
                  <SkeletonLoader />
                </div>
                <div style={{ marginTop: '16px' }}>
                  <SkeletonLoader />
                </div>
                <div style={{ marginTop: '16px', marginBottom: '32px' }}>
                  <SkeletonLoader />
                </div>
              </>
            ) : (
              <fieldset>
                <TextField
                  title={phoneFieldTitle}
                  labelText={phoneFieldLabel}
                  value={phoneField.value}
                  isError={!!phoneField.errorText}
                  errorMessage={phoneField.errorText}
                  onBlur={handlePhoneChange}
                />
                <TextField
                  margin={{ v: 16 }}
                  title={phoneConfirmNumberTitle}
                  labelText={phoneConfirmNumberLabel}
                  value={phoneConfirmField.value}
                  isError={!!phoneConfirmField.errorText}
                  errorMessage={phoneConfirmField.errorText}
                  onBlur={handlePhoneConfirmChange}
                />
                <TextField
                  title="E-post"
                  labelText="Din e-post"
                  value={emailField.value}
                  isError={!!emailField.errorText}
                  errorMessage={emailField.errorText}
                  onBlur={handleEmailChange}
                />
              </fieldset>
            )}
            <div className={styles['checkboxes-container']}>
              <Consents isLoading={isLoading} data={consents} />
            </div>
            <Button
              onMouseUp={handleOnNextPageClick}
              variant="primary"
              expand
              margin={{ b: 16 }}
            >
              Gå vidare
            </Button>
            <Button variant={cancelButtonVariant} to="/" expand>
              Avbryt
            </Button>
          </form>
        </div>
      </div>
    </>
  )
}

const Consents = ({ data, isLoading }) =>
  isLoading ? (
    <>
      <div style={{ padding: '16px' }}>
        <CheckboxSkeleton />
        <CheckboxSkeleton />
        <CheckboxSkeleton />
      </div>
    </>
  ) : (
    data.map(({ key, title, paragraphs, onChange: handleChange }) => (
      <InfoCheckbox
        key={key}
        label={title}
        modalHeading={title}
        modalText={{ markdownContent: paragraphs.join() }}
        modalSize="md"
        modalTextAlign="left"
        buttonCloseLabel="stäng"
        onChange={handleChange ? handleChange : () => {}}
      />
    ))
  )
