import React from "react";
import { withRouter } from "react-router-dom";
import connect from "react-redux/es/connect/connect";
import { getDisplayName } from "../../../store/selectors";
import { Header } from "src/new-lib/Header";
import { Footer } from "src/new-lib/Footer";
import { FixMeLater } from "src/new-lib/_types";

type Props = {
    displayName: string
    children: React.ReactNode
}

const _RegistrationLayout = ({
    displayName,
    children
}: Props) => {

    return (
        <main style={{width: '100%', padding: '0', backgroundColor: '#ffffff'}}>
            <Header isLoggedIn menuButtonText={displayName} variant='fast-listing' />
            <div>{children}</div>
            <Footer signedIn />
        </main>
    )
}

const mapStateToProps = (state: FixMeLater) => ({
    displayName: getDisplayName(state),
});

export const RegistrationLayout = withRouter(
    connect(mapStateToProps)(_RegistrationLayout)
);