import React, { PureComponent } from 'react'
import { actions } from '../../store/reducers/app'
import connect from 'react-redux/es/connect/connect'
import queryString from 'query-string'
import config from '../../api/config'
import { LoginView } from './LoginView'
import { LoggedOutLayout } from '../_layouts'

class Page extends PureComponent {
  constructor(props) {
    super(props)
    this.state = {
      nextRoute: '',
      fastListing: '',
      hsaId: '',
      loginOverrideScreenLoader: false
    }
  }

  notSupportedDialog = () => {
    alert(
      'Capio stöder inte din webbläsare för tillfället.\nVi rekommenderar att du använder Google Chrome, Firefox, Microsoft Edge, Opera, eller Safari.'
    )
  }

  handleLoginBtnClick = (event) => {
    event && event.preventDefault()

    if (this.browserIsSupported()) {
      this.props.dispatch(actions.signIn(this.queryParametersToPersist()))
    } else {
      this.notSupportedDialog()
    }
  }

  browserIsSupported = () => {
    const isIE = false || !!document.documentMode
    return !isIE
  }

  onLoginOverride = () => {
    this.props.dispatch(actions.signIn(this.queryParametersToPersist()))
  }

  queryParametersToPersist = () => {
    const urlQueryParams = queryString.parse(this.props.history.location.search)
    return {
      next_route: this.state.next_route,
      hcu_id: this.state.hcu_id,
      direct_entry: this.state.direct_entry,
      fast_listing: this.state.fast_listing,
      hsa_id: this.state.hsa_id,
      ...(config.environment === 'local' || config.environment === 'dev'
        ? { enable_freja: urlQueryParams.enable_freja }
        : null)
    }
  }

  componentDidMount() {
    if (!this.props.history) {
      return
    }
    const urlQueryParams = queryString.parse(this.props.history.location.search)
    const nextRoute = urlQueryParams.service || urlQueryParams.s
    const hcuId = urlQueryParams.hid
    const loginOverride = urlQueryParams.loginOverride || urlQueryParams.lo
    const directEntry = urlQueryParams.directEntry || urlQueryParams.de
    const fastListing = urlQueryParams.fastListing
    const hsaId = urlQueryParams.hsaid
    this.setState({ next_route: nextRoute })

    if (loginOverride) {
      if (!this.browserIsSupported()) {
        this.notSupportedDialog()
        return
      }
      this.setState(
        {
          loginOverrideScreenLoader: true,
          next_route: nextRoute,
          hcu_id: hcuId,
          direct_entry: directEntry,
          fast_listing: fastListing,
          hsa_id: hsaId
        },
        () => {
          this.onLoginOverride()
        }
      )
    }
  }

  render() {
    return (
      <LoggedOutLayout>
        <LoginView
          onLoginButtonClick={this.handleLoginBtnClick}
          loading={this.state.loginOverrideScreenLoader}
        />
      </LoggedOutLayout>
    )
  }
}

export const LoginPage = connect((state) => {
  return {
    app: state.app
  }
})(Page)
