import NetworkError from "../NetworkError";
import config from "../config";

export class NewsletterAPI {
  constructor(http) {
    this._http = http;
    this._endpointUnsubscribe = `${config.base}/api/v1/consent/b2c/customers/unsubscribe`;
  }

  async unsubscribe(type, unsubscribeToken, messageSourceId) {
    const res = await this._http.post(this._endpointUnsubscribe, {
      type,
      unsubscribeToken, 
      messageSourceId
    });

    if (!(res.status >= 200 && res.status <= 299)) {
      throw new NetworkError(res.status, res.data);
    }
  }
}
