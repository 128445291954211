

const key = "featureConfig";

export const featureConfigOfSession = {
    set: value => window.sessionStorage.setItem(key, JSON.stringify(value)),
    get: () => JSON.parse(window.sessionStorage.getItem(key)),
    hasValue: () => {
        const x = window.sessionStorage.getItem(key) ==! "{}" 
        return x
    }
}