import { SELECT_REGION, STORE_ALL_REGIONS } from './action-types';
import { initialState } from './initialState';

export const actions = {
  selectRegion(location) {
    return {
      type: SELECT_REGION,
      payload: location
    };
  },

  getAllRegions() {
    return (dispatch, getState, api) => {
      return api.services.location
        .getAllRegions()
        .then(payload => {
          dispatch({ type: STORE_ALL_REGIONS, payload });
          return Promise.resolve();
        })
        .catch(err => {
          console.log(err);
        });
    };
  }
};

export const reducer = (state = initialState, action) => {
  switch (action.type) {
    case SELECT_REGION:
      return {
        ...state,
        selectedRegion: action.payload
      };

    case STORE_ALL_REGIONS:
      return {
        ...state,
        regions: action.payload
      };

    default:
      return state;
  }
};
