import React, { Component } from "react";
import config from '../../api/config';
import {LogoutScreen} from "./LogoutScreen";

class Logout extends Component {

    componentDidMount() {
        let postLogoutURI = `${config.postLogoutURI}`;
        let postLogoutURIOverride = localStorage.getItem('postLogoutURIOverride');
        if (postLogoutURIOverride) {
            postLogoutURI = postLogoutURIOverride;
            localStorage.removeItem('postLogoutURIOverride');
        }
        let  link = document.createElement("a");
        link.id = "XXX";
        link.href=`${config.capioGoLogout}`;
        link.target = "output_frame";
        link.onclick= () => {
            setTimeout(() => {
                window.location.assign(postLogoutURI);
            }, 2000);
        };
        link.click();
    }


    render() {
        return (
            <LogoutScreen/>
        );
    }
}

export const LogoutPage = Logout;
