import React from "react";

import styles from "./UnitNotIntegratedScreen.module.scss";
import config from '../../../api/config';
import { Text } from "../../../lib";
import { Button } from "../../../lib/Button";


export const UnitNotIntegratedScreen = ({
    healthCareUnitName,
    externalHealthCareUnitURL,
    onClickSettings,
    otherButtonOnClick,
    otherButtonLoading,
    isSavable
}) => {

return (
    <div className={styles.content}>
        <div className={styles.centerChoice}>
            <div className={styles.centerInputs}>
                <div className={styles.contentContainer}>
                    <Text tag="h1" align={"center"} style={{marginBottom: "30px", marginLeft: "40px", marginRight: "40px"}}>Chatta med din mottagning</Text>

                    <div className={styles.checkBoxWrapper}>
                        <Button
                            e2e="select_home_unit_button"
                            to={externalHealthCareUnitURL}
                            style={{minWidth: "310px"}}>
                            {healthCareUnitName}
                        </Button>

                    </div>

                    <div className={styles.fancySpacerContainer}>
                        <p className={styles.fancy}><span>eller</span></p>
                    </div>

                    <Text tag={"p"} align={"center"} style={{marginTop: "15px", marginBottom: "0px"}}>
                        {'Sök vård online via vår nationella tjänst.\n Bemannad 7-22, alla dagar - året runt.'}
                    </Text>

                    <div className={styles.checkBoxWrapper}>

                        <Button
                            style={{width: "310px"}}
                            secondary
                            loading={otherButtonLoading}
                            onClick={otherButtonOnClick}>
                            Capio Go - Vår nationella tjänst
                        </Button>
                    </div>
                    {isSavable() && (
                        <div className={styles.settingsDescriptionWrapper}>
                            <Text tag={"p"} style={{fontSize: "14px", whiteSpace: "pre-wrap"}}>
                                {'Du kan ändra ditt val av mottagning via '}
                                <a  className={styles.blueLinkColor}
                                    href={''}
                                    onClick={onClickSettings}
                                >
                                    inställningar
                                </a>
                            </Text>
                        </div>
                    )}

                </div>
            </div>
        </div>
    </div>
)};
