import { createSelector } from "reselect";
import { capitalizeString } from "../utils/functions";

const getGivenName = state => {
        const name = state.app.identityInformation.givenName;
        return capitalizeString(name);
};
const getHasProtectedIdentity = state => state.consent.myInformation.protectedIdentity === "J";

export const getHasProtectedIdentitySelector = createSelector(
        getHasProtectedIdentity,
        isTrue => isTrue,
);

export const getDisplayName = createSelector(
        getHasProtectedIdentity,
        getGivenName,
        (hasProtectedId, name, isRegistered) => hasProtectedId ? "" : name
);


const getCareCenters = state => state.healthCenter.digitalCenters.filter(c => c.type === "vardcentral");

export const getCareCentersSelector = createSelector(getCareCenters, center => center);


const getFullName = state =>
        capitalizeString(state.app.identityInformation.givenName) + " " +
        capitalizeString(state.app.identityInformation.lastName);

export const getFullNameSelector = createSelector(getFullName, fullname => fullname);

