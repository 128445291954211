import { useEffect } from "react"
import { connect } from "react-redux"
import { withRouter } from "react-router-dom"
import { Typography } from "src/new-lib/Typography"
import { FaqView } from "./FaqView"
import { actions as contentActions } from "src/store/reducers/content"
import { Loader } from "src/new-lib/Loader"
import styles from "./Faq.module.scss"
import type { ContentState } from "src/store/reducers/content/contentState"

type Props = {
    content: ContentState["faq"]
    dispatchFetchContent: () => void
}

const _FaqPage = ({ content, dispatchFetchContent }: Props) => {
    useEffect(() => dispatchFetchContent(), [])

    if (content.data) return <FaqView content={content} />

    if (content.isLoading) return <main className={styles["loader-and-error-container"]}><Loader show /></main>

    return <ErrorView />
}

const ErrorView = () => (
    <main className={styles["loader-and-error-container"]}>
        <Typography tag="h2" align="center">Tyvärr gick något fel. </Typography>
        <Typography tag="p" align="center" weight="600">
            Var god försök igen lite senare eller kontakta oss om felet kvarstår.
        </Typography>
    </main>
)


const mapStateToProps = (state: any) => ({
    content: state.content.faq,
})

const mapDispatchToProps = (dispatch: any) => ({
    dispatchFetchContent: () => dispatch(contentActions.fetchFaq())
})

export const FaqPage = withRouter(connect(mapStateToProps, mapDispatchToProps)(_FaqPage))
