import { FixMeLater } from "src/new-lib/_types";

type FeatureManager = {
  [key: string]: boolean
}

export const handleDeleteUserInfo = (api: FixMeLater, token: string, featureManager: FeatureManager) => {
  const apiService = featureManager.useService2 ? api.services.account : api.services.consent

  return apiService.deleteUserInfo(token)
    .catch((err: Error) => console.info("Failed to remove user information:", err.message))
}