import React from 'react'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import { getCareCentersSelector } from '../../store/selectors'
import { paths } from '../../utils/urlPaths'
import { bool } from 'prop-types'

export const FastListingReceipt = () => {
  return <>{window.location.replace(paths["fast listing > completed"])}</>
}

const mapStateToProps = (state) => ({
  selectedCenter: state.consent.selectedCenter,
  centers: getCareCentersSelector(state)
})

export const FastListingReceiptPage = withRouter(
  connect(mapStateToProps)(FastListingReceipt)
)

FastListingReceiptPage.propTypes = {
  userIsRegistered: bool
}
