import { Button } from "src/new-lib/Button";
import { Typography } from "src/new-lib/Typography";
import styles from "./ProfileView.module.scss";

type Props = {
    title: string
    infoText: string
    buttonText: string
    openPhoneValidationModalHandler: () => void
    isMobile: boolean
}

export const PhoneValidationSection =(props: Props)=>{
  return (
    <section className={styles["validate-phone-number-card"]}>
      <div className={styles["validate-phone-number-texts-container"]}>
          <Typography tag="h2" margin={{}}>
            {props.title}
          </Typography>
          <Typography tag="p" margin={{}}>
          {props.infoText}
        </Typography>
      </div>
      <div className={styles["validate-phone-number-button-cointainer"]}>
        <Button
          expand={props.isMobile}
          variant="primary"
          onClick={props.openPhoneValidationModalHandler}>
          {props.buttonText}
        </Button>
      </div>
    </section>
  );
}
