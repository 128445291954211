import React, { ReactEventHandler } from 'react'
import styles from './LinkRow.module.scss'
import { Typography } from '../../new-lib/Typography'
import { Icon, IconNames, IconSizes } from '../../new-lib/Icon'
import { LinkTo, To } from '../../new-lib/LinkTo'
import { useDeviceSize } from '../../new-lib/_utils'
import { FixMeLater } from '../_types'
import { analytics } from '../_utils/analytics'

/**
 * @description LinkRow component that takes most of its visual appearance based on the props.
 * Used as both "webCareUnitLinkRow" and "webLinkRow"
 */

type Props = {
  title?: string
  leftIconName?: IconNames
  leftIconSize?: IconSizes
  rightIconName?: IconNames
  rightIconSize?: IconSizes
  to?: To
  onClick?: () => void
  children?: React.ReactNode
  track?: FixMeLater
}

export const LinkRow = (props: Props) => {
  const { isMobile } = useDeviceSize()

  const defaultIconSize = isMobile ? 'md' : 'lg'

  const {
    title,
    leftIconName,
    leftIconSize = defaultIconSize,
    rightIconName,
    rightIconSize = defaultIconSize,
    to,
    onClick,
    children,
    track
  } = props

  const LinkRowContent = () => {
    return (
      <>
        {leftIconName && <span className={styles['left-icon']}><Icon size={leftIconSize} name={leftIconName} /></span>}
        <div className={styles['text-container']}>
          <Typography tag='h4' margin={children ? { b: 8 } : {}}>{title}</Typography>
          <div className={styles.labels}>
            {children}
          </div>
        </div>
        {rightIconName && <span className={styles['right-icon']}><Icon size={rightIconSize} name={rightIconName} /></span>}
      </>
    )
  }

  const handleClick = (ev: React.MouseEvent<HTMLDivElement>) => {
    ev.preventDefault()
    onClick()
    analytics.track({ ...track })
  }

  return to
    ? <LinkTo className={styles['link-row']} to={to} dataTrack={track}><LinkRowContent /></LinkTo>
    : <div className={styles['link-row']} onClick={handleClick}><LinkRowContent /></div>
}
