import { useState } from 'react'
import { Button } from '../../new-lib/Button'
import { Typography } from '../../new-lib/Typography'
import { Loader } from 'src/new-lib/Loader'
import scss from './UnsubscribeView.module.scss'
import { useDeviceSize } from 'src/new-lib/_utils'
import { paths } from '../../utils/urlPaths'
import { LoadingScreen } from '../../new-lib/LoadingScreen/LoadingScreen'

type Props = {
  loading: boolean
  error: boolean
}

export const UnsubscribeView = ({ loading, error }: Props) => {
  const { isMobile } = useDeviceSize()

  const [mHeader, mParagraph, mButton] = [
    { b: '24px' },
    { b: '32px' },
    { b: '0px' }
  ]

  const [clicked, setClicked] = useState<boolean>(false)

  const handleBtnClick = () => {
    setClicked(true)
  }

  const renderSuccess = () => (
    <>
      <Loader show={true} margin={{ b: '20px' }} />
      <Typography margin={mHeader} tag='h1' align='left'>{copy.done.heading}</Typography>
      <Typography margin={mParagraph} tag='p' align='left'>{copy.done.body}</Typography>
      <div className={scss['button-container']}>
        <Button
          onClick={handleBtnClick}
          loading={loading || clicked}
          expand={isMobile}
          margin={mButton}
          to="my profile"
          track={{
            name: "my_profile_link",
            category: "my_profile",
            action: "to_my_profile",
          }}
        >
          {copy.done.btn}
        </Button>
      </div>
    </>
  )

  const renderError = () => (
    <>
      <Loader show={true} margin={{ b: '20px' }} />
      <Typography margin={mHeader} tag='h1' weight='400' align='left'>{copy.error.heading}</Typography>
      <Typography margin={mParagraph} tag='p' align='left'>{copy.error.body}</Typography>
      <div className={scss['button-container']}>
        <Button
          onClick={handleBtnClick}
          loading={loading || clicked}
          expand={isMobile}
          margin={mButton}
          to="my profile"
          track={{
            name: "my_profile_link",
            category: "my_profile",
            action: "to_my_profile",
          }}
        >
          {copy.done.btn}
        </Button>
      </div>
    </>
  )

  const renderContent = () => {
    return ((
      <section className={scss.root}>
        <article className={scss.container}>
          {error ? renderError() : renderSuccess()}
        </article>
      </section>
    ))
  }

  return (
    loading ? <LoadingScreen text={copy.loading.heading} /> : renderContent()

  )
}

const copy = {
  done: {
    heading: 'Din prenumeration är nu avslutad',
    body: 'Du har avslutat din prenumeration till Capios nyhetsbrev. För att starta den igen, gå till din profil.',
    btn: 'Till din profil'
  },
  loading: {
    heading: 'Avslutar prenumeration'
  },
  error: {
    heading: 'Vi kunde inte avsluta prenumerationen',
    body: 'Länken för att avsluta prenumerationen är inte giltig. För att avsluta den, gå till din profil.'
  },
  goHome: 'Gå till Capio Online'
}
