// These types can be found in Strapi's Consent collection entities
type ConsentUnionTypes = "email_newsletter_subscription" | "sms_newsletter_subscription" | "newsletter_consent"

type ConsentTypeV2 = {
  id: string
  consentTypeId: string
  description: string
  isAccepted: boolean
  name: string
  type: ConsentUnionTypes
  version: number
}

type ConsentTypeV1 = {
  id: string
  CosmosEntityName: "consenttypes"
  allowMultipleActiveConsentsOfThisType: boolean
  allowUserConsentsUnderAge: boolean
  categories: any[]
  consentTypeId: string
  consentTypeValidFrom: string // ISO 8601
  consentTypeValidTo: string // ISO 8601
  description: string
  isAccepted: boolean
  name: string
  numberOfDaysLifeTime: number
  recallUserConsentsOnUnRegister: boolean
  timestamp: {
    utcTimeOfCreation: string // ISO 8601
    utcTimeOfLastUpdate: string // ISO 8601
  }
  version: number
  visibleToUserIfActiveConsent: boolean
  visibleToUserIfNoActiveConsent: boolean
}

export type MyInformation = {
  name: string
  mobileNumber: string
  validatedMobileNumber: string
  mobileNumberVerification: string
  countryNumber: string
  emailAddress: string
  streetAddress: string
  postAddress: string
  zipCode: string
  protectedIdentity: "J" | "N" | ""
  hasValidatedPhone: boolean
}

export type ConsentsState = {
  myInformation: MyInformation
  sparLoading: boolean
  sparInformation: {
    streetAddress: string
    postAddress: string
    zipCode: string
    privacyMark: string
  }
  child: {
    name: string
    personalNumber: string
    streetAddress: string
    postAddress: string
    zipCode: string
  }
  consents: {
    serviceConditions: boolean
    consentToSMSAndEmailReminder: boolean
    consentToSMSAndEmailNewsLetter: boolean
    listChildConfirmation: boolean
  }
  consentTypes: ConsentTypeV1 | ConsentTypeV2[]
  didUpdateContactInformation: boolean
  didAcceptServiceConditions: boolean
  loading: boolean
  selectedCenter: {
    label: string
    value: string
    region: string
  }
  switchBoard: {
    selectedRegion: string
    selectedHealthCareUnitId: string
  }
  wasRoutedBySettings: boolean
}

export const initialState: ConsentsState = {
  myInformation: {
    name: '',
    mobileNumber: '',
    validatedMobileNumber: '',
    mobileNumberVerification: '',
    countryNumber: '+46',
    emailAddress: '',
    streetAddress: '',
    postAddress: '',
    zipCode: '',
    protectedIdentity: '',
    hasValidatedPhone: false
  },
  sparLoading: false,
  sparInformation: {
    streetAddress: '',
    postAddress: '',
    zipCode: '',
    privacyMark: ''
  },
  child: {
    name: '',
    personalNumber: '',
    streetAddress: '',
    postAddress: '',
    zipCode: ''
  },
  consents: {
    serviceConditions: false,
    consentToSMSAndEmailReminder: false,
    consentToSMSAndEmailNewsLetter: false,
    listChildConfirmation: false
  },
  consentTypes: [],
  didUpdateContactInformation: false,
  didAcceptServiceConditions: false,
  loading: false,
  selectedCenter: {
    label: '',
    value: '',
    region: ''
  },
  switchBoard: {
    selectedRegion: '',
    selectedHealthCareUnitId: ''
  },
  wasRoutedBySettings: false
};


