import config from '../../api/config'
import styles from './LogoutScreen.module.scss'
import { LoadingScreen } from '../../new-lib/LoadingScreen/LoadingScreen'

export const LogoutScreen = () => {
  return (
    <>
      <LoadingScreen />
      <iframe
        title="output_frame"
        className={styles.hiddenFrame}
        name='output_frame'
        src={`${config.capioGo}`}
        id='output_frame'
       />
    </>
  )
}
