import React from "react";
import PropTypes from "prop-types";
import styles from "./Text.module.scss";
import scss from "../_styles/variables.module.scss";

const renderTag = {
    h1: (textAlign, fontWeight, textTransform, className, color, style, children) => <h1 className={className} style={{ textAlign, fontWeight, textTransform, color, ...style }}>{children}</h1>,
    h2: (textAlign, fontWeight, textTransform, className, color, style, children) => <h2 className={className} style={{ textAlign, fontWeight, textTransform, color, ...style }}>{children}</h2>,
    h3: (textAlign, fontWeight, textTransform, className, color, style, children) => <h3 className={className} style={{ textAlign, fontWeight, textTransform, color, ...style }}>{children}</h3>,
    h4: (textAlign, fontWeight, textTransform, className, color, style, children) => <h4 className={className} style={{ textAlign, fontWeight, textTransform, color, ...style }}>{children}</h4>,
    h5: (textAlign, fontWeight, textTransform, className, color, style, children) => <h5 className={className} style={{ textAlign, fontWeight, textTransform, color, ...style }}>{children}</h5>,
    h6: (textAlign, fontWeight, textTransform, className, color, style, children) => <h6 className={className}style={{ textAlign, fontWeight, textTransform, color, ...style }} >{children}</h6>,
    p: (textAlign, fontWeight, textTransform, className, color, style, children) => <p className={className} style={{ textAlign, fontWeight, textTransform, color, ...style }}>{children}</p>,
}

const classes = {
    h1: styles.h1,
    h2: styles.h2,
    h3: styles.h3,
    p: styles.p,
    psm: styles.pSM,
    pxs: styles.pXS,
}


export const Text = ({ 
    tag, 
    variant = tag, 
    align = "left", 
    weight, 
    transform = "none", 
    color = scss.black,
    style,
    children }) => {
    return renderTag[tag](align, weight, transform, classes[variant], color, style, children)
}

Text.propTypes = {
    tag: PropTypes.oneOf(["h1", "h2", "h3", "h4", "h5", "h6", "p"]).isRequired,
    variant: PropTypes.oneOf(["h1", "h2", "h3", "p", "psm", "pxs"]),
    align: PropTypes.oneOf(["left", "right", "center", "start", "end", "justify", "inherit", "initial", "unset"]),
    transform: PropTypes.oneOf(["none", "capitalize", "lowercase", "uppercase", "inherit", "initial", "unset"]),
    style: PropTypes.object,
    color: PropTypes.string,
    children: PropTypes.node.isRequired
}