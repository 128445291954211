import type {
    FAQContent,
    FlowOnboardingStartContent,
    HomeContent,
    TopbarContent,
    ProfilePageContent,
    WebGlobalContent
} from "src/api/services/ContentAPI"
import type { AxiosError } from "axios"

export type ContentState = {
    faq: { isLoading: boolean, data?: FAQContent, error?: AxiosError }
    home: { isLoading: boolean, data?: HomeContent, error?: AxiosError },
    flowOnboardingStart: { isLoading: boolean, data?: FlowOnboardingStartContent, error?: AxiosError }
    topbar: { isLoading: boolean, data?: TopbarContent, error?: AxiosError }
    profile: { isLoading: boolean, data?: ProfilePageContent, error?: AxiosError }
    global: { isLoading: boolean, data?: WebGlobalContent, error?: AxiosError }
}

export const initialState: ContentState = {
    faq: { isLoading: false },
    home: { isLoading: false },
    flowOnboardingStart: { isLoading: false },
    topbar: { isLoading: false },
    profile: { isLoading: false },
    global: { isLoading: false }
}