import React from "react";
import { Button } from "./index";
import PropTypes from "prop-types";


const IconButton = ({ 
    primary, 
    secondary, 
    tertiary, 
    flat,
    small, 
    alert, 
    onClick, 
    onFocus, 
    onBlur, 
    disabled, 
    icon,
    style,
 }) => (
    <Button 
        primary={primary} 
        secondary={secondary}
        tertiary={tertiary}
        flat={flat}
        small={small}
        alert={alert}
        onClick={onClick}
        onFocus={onFocus}
        onBlur={onBlur}
        disabled={disabled}
        style={{ minWidth: "0px", ...style }}>
        {icon}
    </Button>
)

IconButton.propTypes = {
    primary: PropTypes.bool,
    secondary: PropTypes.bool,
    tertiary: PropTypes.bool,
    flat: PropTypes.bool,
    small: PropTypes.bool,
    alert: PropTypes.bool,
    disabled: PropTypes.bool,
    onClick: PropTypes.func,
    onBlur: PropTypes.func,
    icon: PropTypes.element,
    style: PropTypes.object,
}

export { IconButton }