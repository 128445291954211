import React, { useEffect } from 'react'
import { withRouter } from 'react-router-dom'
import connect from 'react-redux/es/connect/connect'
import { getDisplayName } from '../../../store/selectors'
import { LoggedInLayoutScreen } from './LoggedInLayoutScreen'
import { RootState } from 'src/store/Model'
import { Dispatch, bindActionCreators } from 'redux'
import { actions } from 'src/store/reducers/content/actions'
import { TopbarContent } from 'src/api/services/ContentAPI'

type Props = {
  displayName: string
  children: React.ReactNode,
  content: RootState["content"]["topbar"],
  fetchContent: () => void
}

const LayoutContainer = ({
  displayName,
  content,
  fetchContent,
  children
}: Props) => {
  useEffect(() => { fetchContent() }, [])
  return (
    <LoggedInLayoutScreen menuButtonText={displayName} content={content}>
      {children}
    </LoggedInLayoutScreen>
  )
}

const mapStateToProps = (state: RootState) => ({
  displayName: getDisplayName(state),
  content: state.content.topbar
})

const mapDispatchToProps = (dispatch: Dispatch) => bindActionCreators({
  fetchContent: actions.fetchTopbar
}, dispatch)

export const LoggedInLayout = withRouter(
  connect(mapStateToProps, mapDispatchToProps)(LayoutContainer)
)
