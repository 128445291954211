import { initialState } from "./initialState";
import config from "../../../api/config";
import {
  APPLICATION_GLOBAL_LOADING,
  USER_LOGGED_IN,
  SAVE_SESSION_TOKEN,
  SAVE_ID_TOKEN,
  SAVE_IDENTITY_INFORMATION,
  TOGGLE_SIDE_DRAWER,
  USER_IS_REGISTERED,
  USER_IS_NOT_REGISTERED,
  SIGN_OUT,
  END_SESSION,
  CREATE_SESSION_TOKEN,
  SAVE_CODE,
  SAVE_SIGN_CODE,
  SAVE_REDIRECT_URI,
  SAVE_LISTING_PDF_URI,
  ERROR_OCCURED,
} from "./action-types";
import { getPersonId } from "../../../utils/personId";
import { signIn } from "../../../utils/userManager";
import jwtDecode from "jwt-decode";
import { actions as consentActions } from "../../reducers/consents";
import { actions as accountActions } from "../../reducers/accounts";
import { actions as appActions } from "../../reducers/app";
import { actions as centerActions } from "../../reducers/centers";
import { actions as locationActions } from "../../reducers/locations";
import { analytics } from "src/new-lib/_utils/analytics";
import { handleDeleteUserInfo } from "./utils.ts";

export const actions = {
  applicationGlobalLoading(value) {
    return {
      type: APPLICATION_GLOBAL_LOADING,
      payload: value,
    };
  },
  saveSessionToken(value) {
    return {
      type: SAVE_SESSION_TOKEN,
      payload: value,
    };
  },
  saveIdToken(value) {
    return {
      type: SAVE_ID_TOKEN,
      payload: value,
    };
  },
  saveCode(value) {
    return {
      type: SAVE_CODE,
      payload: value,
    };
  },
  saveSignCode(value) {
    return {
      type: SAVE_SIGN_CODE,
      payload: value,
    };
  },

  saveRedirectURI(value) {
    return {
      type: SAVE_REDIRECT_URI,
      payload: value,
    };
  },

  saveListingPDFURI(value) {
    return {
      type: SAVE_LISTING_PDF_URI,
      payload: value,
    };
  },

  saveIdentityInformation(value) {
    return {
      type: SAVE_IDENTITY_INFORMATION,
      payload: value,
    };
  },

  userLoggedIn(value) {
    return {
      type: USER_LOGGED_IN,
      payload: value,
    };
  },
  userIsRegistered() {
    return {
      type: USER_IS_REGISTERED,
      payload: true,
    };
  },
  errorOccured(value) {
    return {
      type: ERROR_OCCURED,
      payload: value,
    };
  },
  toggleSideDrawer(toggled) {
    return {
      type: TOGGLE_SIDE_DRAWER,
      payload: toggled,
    };
  },

  isUserRegistered() {
    return (dispatch, getState, api) => {
      const { sessionToken } = getState().app;

      let sessionTokenStorage = localStorage.getItem("sessionToken");
      if (!sessionTokenStorage) {
        return Promise.reject("No session");
      }

      return api.services.consent
        .isUserRegistered(sessionToken)
        .then((isRegistered) => {
          if (isRegistered) {
            dispatch({ type: USER_IS_REGISTERED });
            return Promise.resolve(isRegistered);
          } else {
            dispatch({ type: USER_IS_NOT_REGISTERED });
            return Promise.resolve(false);
          }
        })
        .catch((err) => {
          if (err.response) {
            if (err.response.status >= 500 && err.response.status < 599) {
              localStorage.removeItem("sessionToken");
              window.location = `${config.redirectBase}/error`;
            } else {
              dispatch({ type: USER_IS_NOT_REGISTERED });
              return Promise.reject(err);
            }
          } else {
            dispatch({ type: USER_IS_NOT_REGISTERED });
            return Promise.reject(err);
          }
        });
    };
  },

  fetchContactInfo() {
    return (dispatch, getState, api) => {
      const { identityInformation, sessionToken } = getState().app;
      let personalNumber = identityInformation.personalNumber;
      const personId = getPersonId(personalNumber);
      return api.services.consent
        .contactInformation(personId, sessionToken)
        .then((data) => {
          dispatch(consentActions.onMyInfoChangeAll(data));
          return Promise.resolve(data);
        })
        .catch((err) => {
          return Promise.reject(err);
        });
    };
  },

  /* Måste refaktoreras och tänkas om! */
  fetUserInfo() {
    return (dispatch, getState, api) => {
      dispatch(actions.errorOccured(false));
      const { identityInformation, sessionToken } = getState().app;
      let personalNumber = identityInformation.personalNumber;

      let sessionTokenStorage = localStorage.getItem("sessionToken");
      if (!sessionTokenStorage) {
        return Promise.reject("No session");
      }

      if (!personalNumber) {
        return Promise.reject("No personal number is store");
      }

      return dispatch(this.isUserRegistered())
        .then((isUserRegistered) => {
          if (isUserRegistered) {
            const personId = getPersonId(personalNumber);
            return api.services.consent
              .contactInformation(personId, sessionToken)
              .then((data) => {
                dispatch({ type: USER_IS_REGISTERED });
                dispatch(consentActions.onMyInfoChangeAll(data));
                return Promise.resolve(data);
              })
              .catch((err) => {
                dispatch({ type: USER_IS_NOT_REGISTERED });
                return Promise.reject(err);
              });
          } else {
            dispatch({ type: USER_IS_NOT_REGISTERED });
            return Promise.resolve(false);
          }
        })
        .catch((err) => {
          dispatch(actions.errorOccured(true));
        });
    };
  },

  signIn(queryParams) {
    return (dispatch, getState, api) => {

      signIn({ ...queryParams, ...getState().featureManager });
    };
  },
  endSession() {
    return (dispatch, getState, api) => {
      const { app } = getState();

      api.services.app
        .endSession(app.sessionToken)
        .then((payload) => {
          console.log("Successful single sign-out");
        })
        .catch((err) => {
          console.log("Failed single sign-out");
        });
    };
  },

  createSessionToken() {
    return (dispatch, getState, api) => {
      const { app } = getState();
      let code = app.code;
      let redirect_uri = app.redirectURI;

      return api.services.app
        .createrAccessToken(code, redirect_uri)
        .then((response) => {
          localStorage.setItem("sessionToken", response.access_token);
          localStorage.setItem("idToken", response.id_token);
          sessionStorage.setItem("refresh_token", response.refresh_token)

          const identityInformation = jwtDecode(response.access_token);

          const gender =
            identityInformation.personalNumber[10] % 2 === 0
              ? "Female"
              : "Male";
          analytics.setSessionDimension("gender", gender);

          dispatch(actions.saveSessionToken(response.access_token));
          dispatch(actions.saveIdToken(response.id_token));
          dispatch(actions.saveIdentityInformation(identityInformation));
          dispatch(actions.userLoggedIn(true));
          return Promise.resolve();
        })
        .catch((err) => {
          return Promise.reject();
        });
    };
  },

  refreshAccessToken() {
    return async (dispatch, getState, api) => {
      
      
    }
  },

  signListingContract() {
    return (dispatch, getState, api) => {
      const { app } = getState();
      let code = app.signCode;

      return api.services.listing
        .signListingContract(app.sessionToken, {
          code: code,
        })
        .then((response) => {
          return Promise.resolve(response);
        })
        .catch((err) => {
          console.log("Action Failed Sign Listing Contract:", err);
          return Promise.reject(err);
        });
    };
  },

  signOut() {
    return { type: SIGN_OUT };
  },

  deleteUserInfo() {
    return (_, getState, api) => {
      const { sessionToken } = getState().app;

      return handleDeleteUserInfo(api, sessionToken, getState().featureManager)
    };
  },

  sendPhoneValidationOtp() {
    return (dispatch, getState, api) => {
      const { sessionToken } = getState().app;

      return api.services.phoneValidation
        .sendOtpSms(sessionToken)
        .then((data) => {
          return Promise.resolve(data);
        })
        .catch(() => {
          console.info("Unable to send phone number validation code");
          return Promise.reject();
        });
    };
  },

  verifyOtp(otp) {
    return (dispatch, getState, api) => {
      const { sessionToken } = getState().app;

      return api.services.phoneValidation
        .verifyOtp(sessionToken, otp)
        .then((data) => {
          return Promise.resolve(data);
        })
        .catch((e) => {
          console.info("Unable to verify sms code");
          return Promise.reject();
        });
    };
  },

  fetchMinimumUserState() {
    return (dispatch, getState, api) => {
      return dispatch(getState().featureManager.useService2 ? accountActions.fetUserInfo() : appActions.fetUserInfo())
        .then((data) => {
          return dispatch(centerActions.fetchDigitalProviderCenters()).then(
            (datac) => {
              return dispatch(locationActions.getAllRegions()).then((datar) => {
                return Promise.resolve();
              });
            }
          );
        })
        .catch((err) => {
          return dispatch(centerActions.fetchDigitalProviderCenters()).then(
            () => {
              return dispatch(locationActions.getAllRegions()).then(() => {
                return Promise.resolve();
              });
            }
          );
        });
    };
  },
};

export const reducer = (state = initialState, action) => {
  switch (action.type) {
    case APPLICATION_GLOBAL_LOADING:
      return { ...state, applicationGlobalLoading: action.payload };

    case USER_LOGGED_IN:
      return { ...state, userLoggedIn: action.payload };
    case SAVE_SESSION_TOKEN:
      return { ...state, sessionToken: action.payload };

    case SAVE_ID_TOKEN:
      return { ...state, idToken: action.payload };

    case SAVE_CODE:
      return { ...state, code: action.payload };

    case SAVE_SIGN_CODE:
      return { ...state, signCode: action.payload };

    case SAVE_REDIRECT_URI:
      return { ...state, redirectURI: action.payload };

    case SAVE_LISTING_PDF_URI:
      return { ...state, listingPdfURI: action.payload };

    case SAVE_IDENTITY_INFORMATION:
      return { ...state, identityInformation: action.payload };

    case TOGGLE_SIDE_DRAWER:
      return { ...state, sideDrawerToggled: action.payload };

    case USER_IS_REGISTERED:
      return { ...state, userIsRegistered: true };

    case USER_IS_NOT_REGISTERED:
      return { ...state, userIsRegistered: false };

    case ERROR_OCCURED:
      return { ...state, errorOccured: action.payload };

    case SIGN_OUT:
      return initialState;

    case CREATE_SESSION_TOKEN:
      return state;

    case END_SESSION:
      return state;

    default:
      return state;
  }
};
