import scss from './LoadingScreen.module.scss'
import classNames from 'classnames'
import { Loader } from 'src/new-lib/Loader'
import { Typography } from 'src/new-lib/Typography'

type Props = {
  text?: string
  standalone?: boolean
}

export const LoadingScreen = ({ text, standalone: embedded }: Props) => {
  const loaderContainer = classNames({
    [scss['embedded-container']]: embedded,
    [scss.container]: !embedded
  })
  return (
    <div className={scss.box}>
      <div className={loaderContainer}>
        <Loader show loop />
        <Typography tag="h1" variant="h2">
          {text}
        </Typography>
      </div>
    </div>
  )
}
