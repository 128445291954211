import { AxiosError } from "axios";
import { isAxiosError } from "src/new-lib/_utils/functions";
import { ERROR_OCCURED, USER_IS_NOT_REGISTERED, USER_IS_REGISTERED } from "../app/action-types"
import {
  MY_INFO_CHANGE,
  MY_INFO_CHANGE_ALL,
  STORE_ALL_CONSENT_TYPES,
  UPDATE_CONTACT_INFORMATION,
  UPDATE_CONTACT_INFORMATION_RESPONSE,
  UPDATE_CONTACT_INFORMATION_RESPONSE_ERROR
} from "../consents/action-types"
import { actions as appActions } from "../app/index"
import type { MyInformation } from "src/store/reducers/consents/initialState";
import type { GetUserInfoResponse, UpdateUserResponse } from "src/api/services/AccountAPI"
import type { GetPhoneValidationStatusResponse } from "src/api/services/PhoneValidationAPI";
import { actions as apiActions } from "../app/index";

export const actions = {
  onMyInfoChange<K extends keyof MyInformation>(key: K, value: MyInformation[K]) {
    return {
      type: MY_INFO_CHANGE,
      payload: {
        name: key,
        value
      }
    }
  },

  onMyInfoChangeAll(value) {
    return {
      type: MY_INFO_CHANGE_ALL,
      payload: { ...value, mobileNumberVerification: '' }
    }
  },

  errorOccured(value) {
    return {
      type: ERROR_OCCURED,
      payload: value,
    };
  },


  createUser() {
    return (dispatch, getState, api) => {
      const contact = getState().consent.myInformation
      const { app, consent } = getState()

      const acceptedConsents = consent.consentTypes.filter(item => item.isAccepted)
      const acceptedConsentsIds = acceptedConsents.map(item => item.consentTypeId)

      const user = {
        email: contact.emailAddress,
        phone: {
          countryCode: contact.countryNumber,
          subscriberNumber: contact.validatedMobileNumber.toString()
        }
      }

      return api.services.account
        .createUser(user, app.sessionToken)
        .then(api.services.app.refreshTokens)
        .then((tokenData) => {

          localStorage.setItem("sessionToken", tokenData.access_token);
          localStorage.setItem("idToken", tokenData.id_token);
          sessionStorage.setItem("refresh_token", tokenData.refresh_token);
          dispatch(appActions.saveSessionToken(tokenData.access_token));
          dispatch(appActions.saveIdToken(tokenData.id_token));

          dispatch({ type: USER_IS_REGISTERED });
          const consentPayload = { ids: acceptedConsentsIds };
          return api.services.account.updateConsent(consentPayload, tokenData.access_token);

        }).catch((err) => {
          //cb(); // FIXME: Redirect to error page or display error.
          dispatch({ type: USER_IS_NOT_REGISTERED })
        })
    }
  },



  updateContactInformation() {
    return (dispatch, getState, api) => {
      dispatch({ type: UPDATE_CONTACT_INFORMATION })
      const { app, consent } = getState()
      const userInfo = consent.myInformation

      const postalCode = userInfo.zipCode.length === 5
        ? `${userInfo.zipCode.substring(0, 3)} ${userInfo.zipCode.substring(3)}`
        : userInfo.zipCode

      const payloadV2 = {
        phone: {
          countryCode: userInfo.countryNumber,
          subscriberNumber: userInfo.validatedMobileNumber
        },
        email: userInfo.emailAddress,
        street: userInfo.streetAddress,
        postalCode,
        city: userInfo.postAddress
      };

      return api.services.account
        .updateUser(payloadV2, app.sessionToken)
        .then((data: UpdateUserResponse) => {
          dispatch({ type: UPDATE_CONTACT_INFORMATION_RESPONSE })
          dispatch(actions.onMyInfoChange("hasValidatedPhone", data.phone.isValidated))

          return data
        })
        .catch(err => dispatch({ type: UPDATE_CONTACT_INFORMATION_RESPONSE_ERROR, payload: err }))
    }
  },

  fetchContactInfo() {
    return async (dispatch, getState, api) => {
      const { sessionToken } = getState().app;

      return api.services.account
        .getUserInfo(sessionToken)
        .then((data: GetUserInfoResponse) => {
          const contact = actions.transformToV2(data)
          dispatch(actions.onMyInfoChangeAll(contact));
          return Promise.resolve(contact);
        })
        .catch((err) => {
          return Promise.reject(err);
        });
    };
  },

  transformToV2(data: GetUserInfoResponse) {
    return {
      firstName: data.name.first,
      lastName: data.name.last,
      countryNumber: data.phone.countryCode,
      emailAddress: data.email,
      fullMobileNumber: `${data.phone.countryCode}${data.phone.subscriberNumber}`,
      mobileNumber: `${data.phone.countryCode}${data.phone.subscriberNumber}`,
      postAddress: data.city,
      givenName: data.name.first,
      streetAddress: data.street,
      zipCode: data.postalCode,
      shouldSyncHealthCareManual: false,
      parseIntrotectedIdentity: data.status.includes("has_protected_identity_restrictions") ? "J" : "N",
      primaryHealthCareEntityId: data.selectedCareUnitId,
      hasValidatedPhone: data.phone.isValidated
    }
  },

  fetUserInfo() {
    return async (dispatch, getState, api) => {
      const sessionTokenStorage = localStorage.getItem("sessionToken");
      if (!sessionTokenStorage) return Promise.reject("No session");


      dispatch(actions.errorOccured(false));
      const { sessionToken } = getState().app;

      return api.services.account
        .getUserInfo(sessionToken)
        .then((data: GetUserInfoResponse) => {
          const contact = actions.transformToV2(data)

          dispatch({ type: USER_IS_REGISTERED });
          dispatch(actions.onMyInfoChangeAll(contact));
          return Promise.resolve(true);
        })
        .catch((err) => {
          dispatch({ type: USER_IS_NOT_REGISTERED });
          return Promise.resolve(false);
        });
    };
  },

  getAllConsentTypes(initial) {
    return (dispatch, getState, api) => {
      const { app } = getState()

      return api.services.account
        .getAllConsents(app.sessionToken)
        .then((data) => {

          const payload = data.consents.map(consent => ({
            "id": consent.id,
            "consentTypeId": consent.id,
            "type": consent.type,
            "version": consent.version,
            "name": consent.title,
            "description": consent.content
          }));

          if (initial) dispatch({ type: STORE_ALL_CONSENT_TYPES, payload })

          return Promise.resolve(payload)
        })
        .catch(() => { })
    }
  },

  getAllUserConsentTypes() {
    return (dispatch, getState, api) => {
      const { app } = getState()

      return api.services.account
        .getAllUserConsents(app.sessionToken)
        .then((data) => {
          const payload = data.consentIds.map(id => ({
            id,
            consentType: {
              consentTypeId: id
            }
          }))

          return Promise.resolve(payload)
        })
        .catch(() => {
          //dispatch({ type: ACCEPT_TOS_RESPONSE_ERROR, payload: err });
          return Promise.reject()
        })
    }
  },

  saveAllUserConsentTypes() {
    return (dispatch, getState, api) => {
      const { app, consent } = getState()

      const filteredConsentTypes = consent.consentTypes.filter((item) => {
        if (item.isAccepted) {
          return item.consentTypeId
        } else {
          return false
        }
      })

      const acceptedConsentsIds = filteredConsentTypes.map((item) => {
        if (item.isAccepted) {
          return item.consentTypeId
        } else {
          return false
        }
      })

      const consentPayload = { ids: acceptedConsentsIds };
      return api.services.account
        .updateConsent(consentPayload, app.sessionToken)
        .then((payload) => {
          return Promise.resolve(payload)
        })
        .catch(() => {
          //dispatch({ type: ACCEPT_TOS_RESPONSE_ERROR, payload: err });
          return Promise.reject()
        })
    }
  }
}