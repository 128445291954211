import { useEffect, useState, FC } from 'react'
import styles from './SkeletonLoader.module.scss'

interface SkeletonLoaderProps {
  height?: string
  width?: string
}

const SkeletonLoader: FC<SkeletonLoaderProps> = ({
  height = '40px',
  width = '100%'
}) => {
  const [loaded, setLoaded] = useState(false)

  useEffect(() => {
    const timer = setTimeout(() => {
      setLoaded(true)
    }, 3000)

    return () => clearTimeout(timer)
  }, [])

  return (
    <div className={styles.skeletonWrapper}>
      <div
        style={{ height, width }}
        className={`${styles.skeleton} ${loaded ? styles.loaded : ''}`}
      ></div>
    </div>
  )
}

export default SkeletonLoader
