import React from "react";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";
import styles from "./MenuItem.module.scss";
import MuiMenuItem from '@material-ui/core/MenuItem';
import { Text } from "../Text";

const MenuItem = ({ iconLeft, iconRight, text, color, to, onClick, style, divider, e2e }) => {
    const _style = { height: "84px", ...style };

    const renderLabel = () => typeof text === "string"
        ? (
            <Text tag="p" style={{
                margin: "0px",
                marginLeft: iconLeft ? "18px" : "0px",
                color
            }} capitalize>{text}</Text>
        )
        : text;

    const render = () => (
      <div className={styles.menuItem}>
        <div className={styles.leftContainer}>
          {iconLeft}
          {renderLabel()}
        </div>
        {iconRight}
      </div>
    );

    return to
        ?
        <li style={_style} onDragStart={e => e.preventDefault()} data-e2e={e2e}>
            <Link to={to} className={styles.link}>
                <MuiMenuItem component="div" divider={divider} style={{ width: "100%", height: "100%"}}>
                    {render()}
                </MuiMenuItem>
            </Link>
        </li>


        :
        (<MuiMenuItem key={text} divider={divider} onClick={onClick} style={_style} >
            {render()}
        </MuiMenuItem>)

}


MenuItem.propTypes = {
    iconLeft: PropTypes.element,
    iconRight: PropTypes.element,
    text: PropTypes.oneOfType([PropTypes.object, PropTypes.string, PropTypes.func]),
    color: PropTypes.string,
    to: PropTypes.string,
    height: PropTypes.string,
    width: PropTypes.string,
    onClick: PropTypes.func,
    divider: PropTypes.bool,
    e2e: PropTypes.string,
}

export { MenuItem }