import classNames from 'classnames'
import React from 'react'
import { Link } from 'react-router-dom'
import { Icon, IconSizes } from '../Icon'
import { FixMeLater } from '../_types'
import {
  marginToCssProp,
  paddingToCssProp,
  isWebUrl,
  isPhoneNumber,
  cleanPhoneNumberString
} from '../_utils'
import { createPath, LocalPath } from 'src/utils/urlPaths'
import styles from './LinkTo.module.scss'
import { analytics } from '../_utils/analytics'

/**
 * @description Can have plain text as children but using the Typography component is recommended
 * (preferably by using the "span" tag with Typography).
 * Can be wrapped around icons, images or any other tag / React component.
 */

type Protocols = "http://" | "https://" | "www." | "tel:" | "mailto:" | "/" | "#"
type Url = `${Protocols}${string}`

export type To = Url | LocalPath | string

export type Targets = "_self" | "_blank" | "_parent" | "_top"

interface Props {
  className?: string
  animation?: boolean
  margin?: FixMeLater
  padding?: FixMeLater
  to: To
  iconSize?: IconSizes
  dataTrack?: FixMeLater
  children?: React.ReactNode
  displayLinkIcon?: boolean
  expand?: boolean
  onMouseEnter?: React.MouseEventHandler
  onMouseLeave?: React.MouseEventHandler
  onTouchStart?: React.TouchEventHandler
  onTouchEnd?: React.TouchEventHandler
  target?: Targets
  onClick?: React.MouseEventHandler
}

export const LinkTo = ({
  className,
  animation,
  margin,
  padding,
  to,
  iconSize = 'sm',
  dataTrack,
  children,
  displayLinkIcon = false,
  onMouseEnter,
  onMouseLeave,
  onTouchStart,
  onTouchEnd,
  expand,
  target,
  onClick
}: Props) => {
  const linkClassNames = classNames({
    [styles.default]: !className,
    [styles.animation]: animation,
    [styles.expand]: expand,
    [className]: className
  })
  const path = createPath(to)

  
  const renderExternalLink = (target: string) => (
    <a
        target={target}
        className={linkClassNames}
        style={{
          margin: marginToCssProp(margin),
          padding: paddingToCssProp(padding)
        }}
        href={path}
        onMouseEnter={onMouseEnter}
        onMouseLeave={onMouseLeave}
        onTouchStart={onTouchStart}
        onTouchEnd={onTouchEnd}
        onClick={(ev: React.MouseEvent) => {
          analytics.track({ ...dataTrack, to: path, content: children })
          onClick && onClick(ev)
        }}
      >
        {children}
        {displayLinkIcon && (
          <Icon name='share-link' margin={{ l: 4 }} size={iconSize} />
        )}
      </a>
  )

  if (isPhoneNumber(path)) {
    const tel = `tel:${cleanPhoneNumberString(path)}`
    return renderExternalLink(tel)
  }
  if (isWebUrl(path)) return renderExternalLink(path)
  
  return (
    <Link
        className={linkClassNames}
        style={{
          margin: marginToCssProp(margin),
          padding: paddingToCssProp(padding)
        }}
        to={path}
        target={target}
        onMouseEnter={onMouseEnter}
        onMouseLeave={onMouseLeave}
        onTouchStart={onTouchStart}
        onTouchEnd={onTouchEnd}
        onClick={(ev: React.MouseEvent) => {
          analytics.track({ ...dataTrack, to: path, content: children })
          onClick && onClick(ev)
        }}
      >
        {children}
        {displayLinkIcon && (
          <Icon name='share-link' margin={{ l: 4 }} size={iconSize} />
        )}
      </Link>
  )
}
