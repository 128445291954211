import { Typography } from "src/new-lib/Typography"
import { LinkTo } from "../LinkTo"
import styles from "./markdown.module.scss"

const CustomLink = ({ children, href }) => (
    <LinkTo className={styles["link-to"]} to={href} target="_blank" displayLinkIcon>
      <Typography tag="p" margin={{}}>
        {children}
      </Typography>
    </LinkTo>
)

export const options = { overrides: {
    h1: { component: Typography, props: { tag: 'h1'} },
    h2: { component: Typography, props: { tag: 'h2'} },
    h3: { component: Typography, props: { tag: 'h3'} },
    h4: { component: Typography, props: { tag: 'h4'} },
    h5: { component: Typography, props: { tag: 'h5'} },
    h6: { component: Typography, props: { tag: 'h6'} },
    p: { component: Typography, props: { tag: 'p' } },
    ul: { props: { className: styles.ul + ' ' + Typography } },
    li: { props: { className: styles.li } },
    a: { component: CustomLink }
}}