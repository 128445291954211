import React from 'react';
import styles from './HeroHeader.module.scss';
import classNames from 'classnames';
import { Typography } from '../Typography';
import { useDeviceSize } from '../_utils';
import { Colors, Image } from 'src/new-lib/_types'

type Props = {
  alignment?: 'left' | 'center' | 'right';
  title: string;
  text: string;
  children?: React.ReactNode;
  image?: Image;
  alt?: string;
  color?: Colors;
}

const HeroHeader = ({
  alignment = 'center',
  title,
  text,
  children,
  image,
  alt,
  color,
}: Props) => {
  const heroContainerClassNames = classNames({
    [styles['hero-header']]: true,
    [styles['hero-header-with-image']]: image,
    [styles[`color-${color}`]]: color,
  });

  const buttonContainerClassNames = classNames({
    [styles['button-container']]: children,
    [styles['button-container-left']]: alignment === 'left',
    [styles['button-container-center']]: alignment === 'center',
    [styles['button-container-right']]: alignment === 'right',
  });

  const { isDesktop } = useDeviceSize();

  const titleMargin = isDesktop ?  { b: 24 } : { b: 16 }

  return (
    <section className={heroContainerClassNames}>
      <div className={styles['hero-content-container']}>
        <div className={styles['hero-content']}>
          <Typography align={alignment} margin={titleMargin} tag="h1" children={title} htmlFor={undefined} weight={undefined} lineHeight={undefined} display={undefined} spacing={undefined} />
          <Typography tag='p' children={text} align={alignment} margin={titleMargin} htmlFor={undefined} weight={undefined} lineHeight={undefined} display={undefined} spacing={undefined} />
          <div className={buttonContainerClassNames}>{children}</div>
        </div>
      </div>
      {isDesktop && image && (
        <img className={styles.image} src={image} alt={alt} />
      )}
    </section>
  )
}

export default HeroHeader;
