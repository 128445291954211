import React, { useEffect } from "react";
import { withRouter } from "react-router-dom";

const _ScrollToTop = ({ history, children }) => {
  useEffect(() => {
    const unlisten = history.listen(() => {
      window.scrollTo(0, 0);
    });
    return () => unlisten();
  }, []);

  return <>{children}</>;
}

export const ScrollToTop = withRouter(_ScrollToTop);