import React from 'react'
import { connect } from 'react-redux'
import { Header, HeaderVariants } from 'src/new-lib/Header'
import { Footer } from 'src/new-lib/Footer'
import { paths } from 'src/utils/urlPaths'
import { Button } from 'src/new-lib/Button'
import styles from './SwitchboardLayout.module.scss'
import { RootState } from 'src/store/Model'
import { Dispatch, bindActionCreators } from 'redux'
import { actions } from 'src/store/reducers/content'

const footerSections = [
  {
    header: 'Tjänster',
    links: [
      {
        display: 'Sök vård digitalt',
        to: paths['switchboard'],
        track: {
          name: 'seek_care_cta',
          category: 'home',
          action: 'to_home',
          pageContext: 'top_nav'
        }
      },
      {
        display: 'Lista dig hos Capio',
        to: paths['listing > select'],
        track: {
          name: 'listing_dropdown_link',
          category: 'listing',
          action: 'to_listing',
          pageContext: 'top_nav_dropdown'
        }
      },
      {
        display: 'Hitta en mottagning',
        to: paths['care units'],
        track: {
          name: 'topbar_menu_link',
          category: 'digital_care',
          action: 'to_digital_care',
          pageContext: 'top_nav_dropdown'
        }
      },

      {
        display: 'Registrera dig för Capio nyhetsbrev',
        to: paths['my profile']
      },
      {
        display: 'Uppdatera kontaktuppgifter',
        to: paths['my profile'],
        track: {
          category: 'my_profile',
          action: 'to_my_profile',
          pageContext: 'top_nav_dropdown'
        }
      }
    ]
  },
  {
    header: 'Om Capio',
    links: [
      {
        display: 'Så arbetar vi med kvalitet',
        to: paths['how we work with QA']
      },
      {
        display: 'Tillgänglighetsredogörelse',
        to: paths['accessability account']
      },
      {
        display: 'Hantera cookies',
        to: paths['cookies']
      },
      {
        display: 'Nätintegritetspolicy',
        to: paths['webIntegrity']
      }
    ]
  },
  {
    header: 'Kontakt',
    links: [
      {
        display: 'Vanliga frågor om digital vård',
        to: paths['faq'],
        track: {
          name: 'topbar_menu_link',
          category: 'awareness',
          action: 'to_faq',
          pageContext: 'top_nav_dropdown'
        }
      }
    ]
  },
  {
    header: 'Följ oss i social medier',
    links: [
      {
        display: 'Instagram',
        to: paths['instagram']
      },
      {
        display: 'Facebook',
        to: paths['facebook']
      },
      {
        display: 'LinkedIn',
        to: paths['linkedin']
      }
    ]
  }
]

const mapStateToProps = (state: RootState) => ({
  content: state.content.topbar
})

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators(
    {
      fetchContent: actions.fetchTopbar()
    },
    dispatch
  )

type SwitchboardLayoutProps = {
  displayName: string
  children: React.ReactNode
  variant: HeaderVariants
  content: RootState['content']['topbar']
}

const SwitchboardLayoutContainer = (props: SwitchboardLayoutProps) => (
  <SwitchboardLayoutView {...props} />
)

export const SwitchboardLayoutView = ({
  displayName,
  children,
  variant,
  content
}: SwitchboardLayoutProps) => (
  <div className={styles['sboard-layout']}>
    <Header
      content={content}
      isLoggedIn
      menuButtonText={displayName}
      variant={variant}
    />
    {children}
    <Footer signedIn sections={footerSections} />
  </div>
)

export const SwitchboardLayout = connect(
  mapStateToProps,
  mapDispatchToProps
)(SwitchboardLayoutContainer)

type ChatLayoutProps = {
  children: React.ReactNode
  content: RootState['content']['topbar']
}

const ChatLayoutContainer = (props: ChatLayoutProps) => (
  <ChatLayoutView {...props} />
)

export const ChatLayoutView = ({ children, content }) => (
  <div className={styles['sboard-layout']}>
    <Header content={content} isLoggedIn={true} variant="chat" />
    <div className={styles['child-container']}>{children}</div>
    <Footer signedIn sections={footerSections} />
  </div>
)

export const ChatLayout = connect(
  mapStateToProps,
  mapDispatchToProps
)(ChatLayoutContainer)
