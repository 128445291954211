import React from 'react'
import styles from './TermsOfService.module.scss'
import { InfoCheckbox } from '../../new-lib/Checkbox/InfoCheckbox'
import PropTypes from 'prop-types'
import { Typography } from 'src/new-lib/Typography'
import { Button } from '../../new-lib/Button'
import { useDeviceSize } from 'src/new-lib/_utils'
import { TermsOfServices } from 'src/new-lib/TermsOfServices'
import { Card } from 'src/new-lib/Card'
import { CheckboxSkeleton } from 'src/new-lib/Skeleton/CheckboxSkeleton'
import type { TosState } from 'src/store/reducers/terms-of-services/initialState'

type Props = {
  formError: boolean
  onClick: () => void
  loading: boolean
  termsOfService: TosState
  onTosAcceptedChange: () => void
  errorMessage: string
  onClickBack: () => void
}

/**
 * @description Terms of service view
 */

export const TermsOfServiceView = ({
  onClick,
  loading,
  termsOfService,
  onTosAcceptedChange,
  errorMessage,
  onClickBack
}: Props) => {
  const { isMobile } = useDeviceSize()

  return (
    <section className={styles.container}>
      <Card>
        <div className={styles['content-container']}>
          <Typography align="center" margin={{}} tag="h1">
            Tjänstevillkor
          </Typography>
          <Typography tag="p" margin={{ t: 16 }} align="center">
            För att kunna använda Capios digitala tjänster behöver du godkänna
            tjänstevillkoren.
          </Typography>

          {loading ? (
            <div
              style={
                isMobile
                  ? { marginTop: 24, marginBottom: 24, width: '100%' }
                  : { marginTop: 32, marginBottom: 32, width: '100%' }
              }
            >
              <CheckboxSkeleton />
            </div>
          ) : (
            <InfoCheckbox
              margin={isMobile ? { t: 24, b: 24 } : { t: 32, b: 32 }}
              text="Läs tjänstevillkoren"
              error={errorMessage}
              onChange={onTosAcceptedChange}
              buttonCloseLabel="Stäng"
              label="Jag godkänner tjänstevillkoren"
              modalHeading="Tjänstevillkor"
              modalSize="md"
              disabled={loading}
              modalText={
                <TermsOfServices
                  termsOfService={termsOfService}
                  overrides={undefined}
                />
              }
            />
          )}

          <Button
            disabled={loading}
            loading={loading}
            onClick={onClick}
            margin={{ b: 16 }}
            expand
            variant="primary"
          >
            Slutför Registrering
          </Button>
          <Button
            onClick={onClickBack}
            variant={isMobile ? 'secondary-light' : 'text'}
            expand
          >
            Tillbaka
          </Button>
        </div>
      </Card>
    </section>
  )
}

TermsOfServiceView.propTypes = {
  onTosAcceptedChange: PropTypes.func.isRequired,
  onClick: PropTypes.func.isRequired,
  formError: PropTypes.bool.isRequired,
  loading: PropTypes.bool.isRequired,
  errorMessage: PropTypes.string.isRequired,
  onClickBack: PropTypes.func.isRequired
}
