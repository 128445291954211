import { combineReducers } from 'redux';
import { routerReducer } from 'react-router-redux';

import { reducer as app } from './app';
import { reducer as consent } from './consents';
import { reducer as healthCenter } from './centers';
import { reducer as location } from './locations';
import { reducer as featureManager } from "./featureManager";
import { reducer as newsletter } from "./newsletter"
import { reducer as termsOfServices } from "./terms-of-services"
import { reducer as content } from "./content"

const rootReducer = combineReducers({
  app,
  routing: routerReducer,
  consent,
  location,
  healthCenter,
  featureManager,
  newsletter,
  termsOfServices,
  content
});

export default rootReducer;
