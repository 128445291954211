import NetworkError from "../NetworkError";
import config from "../config";

export class LocationAPI {
  constructor(http) {
    this._http = http;
    this._endpointRegions = `${config.base}/cms/api/v1/health-care-units/regions`;
  }

  async getAllRegions() {
    const res = await this._http.get(this._endpointRegions);

    if (res.status !== 200) {
      throw new NetworkError(res.status, res.data);
    }
    return res.data;
  }
}
